import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TicketTableArchive from './TicketTableArchive';
import { useTranslation } from 'react-i18next';
import { FaArchive, FaUser, FaTicketAlt, FaHistory, FaFileInvoice, FaCreditCard } from 'react-icons/fa';
import './Archive.css';

const Archive = () => {
  const { t } = useTranslation();
  const [archivedTickets, setArchivedTickets] = useState([]);
  const [user, setUser] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      navigate('/');
      return;
    }
    fetchUserData(token);
  }, [navigate]);

  useEffect(() => {
    if (user === null) {
      navigate('/');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user) {
      const token = sessionStorage.getItem('access_token');
      fetchArchivedTickets(token, currentPage);
    }
  }, [user, currentPage]);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get('/api/users/user', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUser(response.data);
    } catch (error) {
      console.error(t('archiveP.fetchUserDataError'), error);
      setUser(null);
    }
  };

  const fetchArchivedTickets = async (token, page) => {
    setIsLoading(true);
    try {
      const response = await axios.get('/api/tickets/archived', {
        headers: { Authorization: `Bearer ${token}` },
        params: { page, limit: 25 }
      });
      setArchivedTickets(response.data.tickets);
      setPagination(response.data.pagination);
    } catch (error) {
      console.error(t('archiveP.fetchArchivedTicketsError'), error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTicketClick = (ticketId) => {
    navigate(`/tickets/${ticketId}/messages`);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (user === undefined || isLoading) {
    return (
      <div className="una-dashboard-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
          <h2>{t('archiveP.loadingTitle')}</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaArchive />
              <span>{t('archiveP.fetchingArchives')}</span>
            </div>
            <div className="una-metric-item">
              <FaUser />
              <span>{t('archiveP.loadingUserData')}</span>
            </div>
            <div className="una-metric-item">
              <FaTicketAlt />
              <span>{t('archiveP.processingTickets')}</span>
            </div>
            <div className="una-metric-item">
              <FaHistory />
              <span>{t('archiveP.verifyingHistory')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="archive-container" id="unanimity-archive-dashboard">
      <TicketTableArchive 
        tickets={archivedTickets} 
        user={user} 
        onTicketClick={handleTicketClick} 
        isLoading={isLoading}
      />
      {pagination && (
        <div className="unanimity-archive-pagination">
          <button 
            className="unanimity-archive-pagination-button"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={!pagination.has_previous || isLoading}
          >
            {t('common.previous')}
          </button>
          <span className="unanimity-archive-pagination-text">
            {t('common.page')} {pagination.current_page} / {pagination.total_pages}
          </span>
          <button 
            className="unanimity-archive-pagination-button"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={!pagination.has_next || isLoading}
          >
            {t('common.next')}
          </button>
        </div>
      )}
    </div>
  );
};

export default Archive;