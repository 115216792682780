import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Sidebar.css';
import { useTranslation } from 'react-i18next';
import { 
  FaHome, FaUsers, FaShippingFast, FaRobot, FaStore, FaChartBar, 
  FaArchive, FaUserTie, FaCog, FaPowerOff, FaGlobe, FaInfoCircle, 
  FaExchangeAlt, FaAngleDoubleLeft, FaAngleDoubleRight, FaShoppingCart, 
  FaChartLine, FaBoxOpen, FaClipboardList, FaListAlt, FaTag, FaHandshake,
  FaStoreAlt, FaBoxes
} from 'react-icons/fa';
import { FaTiktok } from 'react-icons/fa6';

const Sidebar = ({ userRole }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const storedUserData = sessionStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const handleLogout = () => {
    sessionStorage.removeItem('access_token');
    navigate('/');
  };

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`sidebar ${isExpanded ? 'expanded' : ''}`}>
      <nav className="sidebar-nav">
        <ul>
          {(userRole === 'admin' || userRole === 'employee' || userRole === 'e-commerce' || userRole === 'logistics' || userRole === 'eshop' || userRole === 'seller') && (
            <li>
              <NavLink to="/dashboard">
                <div className="icon"><FaHome /></div>
                <span className="text">{t('dashboard')}</span>
              </NavLink>
            </li>
          )}
          {userRole === 'admin' && (
            <>
              <li>
                <NavLink to="/my-ai-assistants">
                  <div className="icon"><FaRobot /></div>
                  <span className="text">{t('aiAgentsLabs')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/create-agent">
                  <div className="icon"><FaUserTie /></div>
                  <span className="text">{t('employee_creation')}</span>
                </NavLink>
              </li>
              {userData?.edn_api_account && (
                <li>
                  <NavLink to="/statistics">
                    <div className="icon"><FaChartBar /></div>
                    <span className="text">{t('statistics')}</span>
                  </NavLink>
                </li>
              )}
              {!userData?.edn_api_account && (
                <>
                <li>
                    <NavLink to="/my-marketplace">
                      <div className="icon"><FaStoreAlt /></div>
                      <span className="text">{t('marketplaceListing')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/marketplace">
                      <div className="icon"><FaHandshake /></div>
                      <span className="text">{t('marketplace')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/shop-statistics">
                      <div className="icon"><FaChartBar /></div>
                      <span className="text">{t('shopStatistics')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/products">
                      <div className="icon"><FaStore /></div>
                      <span className="text">{t('products')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/live-list">
                      <div className="icon"><FaTiktok /></div>
                      <span className="text">{t('LiveShop')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/orders">
                      <div className="icon"><FaShoppingCart /></div>
                      <span className="text">{t('orders')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/returns">
                      <div className="icon"><FaExchangeAlt /></div>
                      <span className="text">{t('returns')}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/customers">
                      <div className="icon"><FaUsers /></div>
                      <span className="text">{t('customers')}</span>
                    </NavLink>
                  </li>
                </>
              )}
              <li>
                <NavLink to="/tickets/archive">
                  <div className="icon"><FaArchive /></div>
                  <span className="text">{t('archive')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/parameters">
                  <div className="icon"><FaCog /></div>
                  <span className="text">{t('parameters')}</span>
                </NavLink>
              </li>
            </>
          )}
          {userRole === 'e-commerce' && (
            <>
              <li>
                <NavLink to="/my-ai-assistants">
                  <div className="icon"><FaRobot /></div>
                  <span className="text">{t('aiAgentsLabs')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/create-agent">
                  <div className="icon"><FaUserTie /></div>
                  <span className="text">{t('employee_creation')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/customers">
                  <div className="icon"><FaUsers /></div>
                  <span className="text">{t('customers')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/logistician">
                  <div className="icon"><FaShippingFast /></div>
                  <span className="text">{t('logistician_bar')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/statistics">
                  <div className="icon"><FaChartBar /></div>
                  <span className="text">{t('statistics')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/tickets/archive">
                  <div className="icon"><FaArchive /></div>
                  <span className="text">{t('archive')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/parameters">
                  <div className="icon"><FaCog /></div>
                  <span className="text">{t('parameters')}</span>
                </NavLink>
              </li>
            </>
          )}
          {userRole === 'logistics' && (
            <>
              <li>
                <NavLink to="/dashboard-logs">
                  <div className="icon"><FaHome /></div>
                  <span className="text">{t('dashboard')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/returns">
                  <div className="icon"><FaExchangeAlt /></div>
                  <span className="text">{t('Adminlogistics')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/logistics-ai-assistants">
                  <div className="icon"><FaRobot /></div>
                  <span className="text">{t('LogisticsAiAssistants')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/logistics-statistics">
                  <div className="icon"><FaChartBar /></div>
                  <span className="text">{t('LogisticsStatistics')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/tickets/archive">
                  <div className="icon"><FaArchive /></div>
                  <span className="text">{t('archive')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/parameters">
                  <div className="icon"><FaCog /></div>
                  <span className="text">{t('parameters')}</span>
                </NavLink>
              </li>
            </>
          )}
          {userRole === 'eshop' && (
            <>
              <li>
                <NavLink to="/my-ai-assistants">
                  <div className="icon"><FaRobot /></div>
                  <span className="text">{t('aiAgentsLabs')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/create-agent">
                  <div className="icon"><FaUserTie /></div>
                  <span className="text">{t('employee_creation')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/my-marketplace">
                  <div className="icon"><FaStoreAlt /></div>
                  <span className="text">{t('marketplaceListing')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/marketplace">
                  <div className="icon"><FaHandshake /></div>
                  <span className="text">{t('marketplace')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/shop-statistics">
                  <div className="icon"><FaChartBar /></div>
                  <span className="text">{t('shopStatistics')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/products">
                  <div className="icon"><FaStore /></div>
                  <span className="text">{t('products')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/live-list">
                  <div className="icon"><FaTiktok /></div>
                  <span className="text">{t('LiveShop')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/orders">
                  <div className="icon"><FaShoppingCart /></div>
                  <span className="text">{t('orders')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/returns">
                  <div className="icon"><FaExchangeAlt /></div>
                  <span className="text">{t('returns')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/customers">
                  <div className="icon"><FaUsers /></div>
                  <span className="text">{t('customers')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/tickets/archive">
                  <div className="icon"><FaArchive /></div>
                  <span className="text">{t('archive')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/parameters">
                  <div className="icon"><FaCog /></div>
                  <span className="text">{t('parameters')}</span>
                </NavLink>
              </li>
            </>
          )}
          {userRole === 'seller' && (
            <>
              <li>
                <NavLink to="/marketplace">
                  <div className="icon"><FaChartLine /></div>
                  <span className="text">{t('marketplace')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/products">
                  <div className="icon"><FaStore /></div>
                  <span className="text">{t('products')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/orders">
                  <div className="icon"><FaShoppingCart /></div>
                  <span className="text">{t('orders')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/returns">
                  <div className="icon"><FaExchangeAlt /></div>
                  <span className="text">{t('returns')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/shop-statistics">
                  <div className="icon"><FaChartLine /></div>
                  <span className="text">{t('shopStatistics')}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/parameters">
                  <div className="icon"><FaCog /></div>
                  <span className="text">{t('parameters')}</span>
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </nav>
      <div className="sidebar-footer">
        <button onClick={toggleSidebar} className="toggle-button">
          <div className="icon">
            {isExpanded ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}
          </div>
          <span className="text">{isExpanded ? t('collapse') : t('expand')}</span>
        </button>
        <button onClick={handleLogout} className="logout-button">
          <div className="icon"><FaPowerOff /></div>
          <span className="text">{t('logout')}</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;