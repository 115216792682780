import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { FaFilePdf, FaFileImage, FaFileArchive, FaFileCode, FaFileAlt } from 'react-icons/fa';
import './ManualConversation.css';

// Carrier requirements used to display the extra fields in the manual form
const carrierRequirements = {
  "Mondial Relay": ["tracking_number", "option_text", "shop_name", "message"],
  "Colis Privé": ["tracking_number", "package_value", "motif", "comment"],
  "Chronopost": {
    always: ["tracking_number"],
    conditional: {
      objet: {
        "Instruction de livraison": ["motif", "comment"],
        "Contestation de livraison": ["motif", "comment"],
        "Demande de preuve de livraison": ["motif", "comment"],
        "SWAP": ["motif", "comment"],
        "Signaler un dysfonctionnement": ["motif", "comment"],
        "Fraude": ["package_content", "product_family", "comment"],
        "Livraison partielle ou endommagée": ["package_content", "product_family", "comment"],
        "Position Colis": ["package_content", "product_family", "comment"],
        "Retour Expéditeur": ["package_content", "product_family", "comment"],
        "Récupérer colis en Pack City": ["package_content", "product_family", "comment"],
        "Retard / Hors délais": ["comment"],
        "Retour express de paiement": ["package_content", "comment"]
      }
    }
  },
  "Colissimo": ["tracking_number", "motif_text", "comments", "colis_content", "colis_value"],
  "Amazon": ["tracking_id", "price"],
  "DHL": ["tracking_id", "comment"]
};

const chronopostRequirements = {
  "Instruction de livraison": {
    motif_required: true,
    motif_options: [
      "Reprogrammer une date de livraison",
      "Mise à disposition dans un point de retrait",
      "Changement d'adresse"
    ],
    additional_fields: ["comment"]
  },
  "Contestation de livraison": {
    motif_required: true,
    motif_options: [
      "En main propre",
      "En boite aux lettres",
      "En Pack City"
    ],
    additional_fields: ["comment"]
  },
  "Demande de preuve de livraison": {
    motif_required: true,
    motif_options: [
      "Demander une confirmation de livraison",
      "Obtenir la preuve de livraison"
    ],
    additional_fields: ["comment"]
  },
  "SWAP": {
    motif_required: true,
    motif_options: [
      "Non récupéré",
      "Annulation",
      "Récupéré partiellement"
    ],
    additional_fields: ["comment"]
  },
  "Signaler un dysfonctionnement": {
    motif_required: true,
    motif_options: [
      "Heure de livraison erronée / Pas d'avis de passage",
      "Comportement Chauffeur",
      "Problème point relais",
      "Non respect des consignes",
      "Problème température",
      "Demande courbe de température"
    ],
    additional_fields: ["comment"]
  },
  "Fraude": {
    motif_required: false,
    motif_options: [],
    additional_fields: ["package_content", "product_family", "comment"]
  },
  "Livraison partielle ou endommagée": {
    motif_required: false,
    motif_options: [],
    additional_fields: ["package_content", "product_family", "comment"]
  },
  "Position Colis": {
    motif_required: false,
    motif_options: [],
    additional_fields: ["package_content", "product_family", "comment"]
  },
  "Retour Expéditeur": {
    motif_required: false,
    motif_options: [],
    additional_fields: ["package_content", "product_family", "comment"]
  },
  "Récupérer colis en Pack City": {
    motif_required: false,
    motif_options: [],
    additional_fields: ["package_content", "product_family", "comment"]
  },
  "Retard / Hors délais": {
    motif_required: false,
    motif_options: [],
    additional_fields: ["comment"]
  },
  "Retour express de paiement": {
    motif_required: false,
    motif_options: [],
    additional_fields: ["package_content", "comment"]
  }
};

const productFamilyOptions = [
  "Autre",
  "Informatique / Electronique",
  "Jouet",
  "Textile",
  "Téléphone",
  "Aliment",
  "Document",
  "Médicaments / Matériel Médical",
  "Pièce Auto",
  "Bagagerie"
];

const colisPriveMotifOptions = [
  "Colis non livré / colis retardé",
  "Colis endommagé",
  "Comportement livreur",
  "Colis annoncé livré et non réceptionné",
  "Problématique relais",
  "Colis retourné a l'expéditeur"
];

const colissimoMotifOptions = [
  "Contestation motif de retour",
  "Colis endommagé",
  "Colis non livré",
  "Contestation de livraison",
  "Consignes de livraison",
  "Preuve de livraison"
];

const mondialRelayShops = [
  "MON_CLUB_BEAUTE",
  "EASYPARAPHARMACIE",
  "PARASELECTION",
  "DECL_CONCEPT",
  "MANELLI",
  "BRELOQUE_PENDENTIF",
  "SMOKING",
  "MMDB",
  "MASTERCOIFF",
  "COUTEAUX_DU_CHEF",
  "BELLE_AU_NATUREL",
  "SMART_DELICE",
  "KASY_IMPORT",
  "ESPACE_BRICOLAGE",
  "PEYROUSE_HAIR_SHOP",
  "ASD",
  "CONFORT_ELECTRIQUE",
  "MON_POP_ART",
  "TABLEAU_DECORATION",
  "MON_LABO_PHOTO",
  "NOOTICA",
  "PHOTO_WAY",
  "FOOT_CENTER",
  "SPORT_FOOD_CENTER",
  "SHOPPING_NATURE",
  "FISHADDICT",
  "CAT_APART",
  "MON_PETIT_BIKINI",
  "BIOVIE",
  "TENDRESSE_DE_BEBE",
  "RUGBYSHOP",
  "AZ_BOUTIQUE",
  "SCOOTER_PIECES",
  "MADEINSPORT",
  "BOUTIQUE_FFF",
  "PROJET_13",
  "LED_S_GO",
  "BAZAR_BIO",
  "MADEMOISELLE_DANSE",
  "COMPTOIR_DE_VIE",
  "VAGDISCOUNT",
  "MBTECH",
  "VALEBIO",
  "REXALINE",
  "ESKIS_COMPANY",
  "OZO",
  "JOYAH",
  "ACADEMIE_DES_METIERS",
  "NUTRAVALIA",
  "LES_ZITOUNES",
  "JOUET_STORE",
  "ENVOI_DU_NET",
  "INCEZA",
  "CYCLO",
  "WORDANS",
  "ICASQUE",
  "REVES_ET_DRAGEES",
  "AIME",
  "RESPIRE",
  "MAD_IN_JAPAN",
  "BRAZILIAN_BIKINI_SHOP",
  "MANI_PANI",
  "B2LOG_EDN",
  "MEDICAL_BEAUTE",
  "CUSTOM_ARENA",
  "MANUCURIST",
  "PHARMACIE_LEMAITRE",
  "MARIO_BERTULLI",
  "MORPHOBURO",
  "OSEE",
  "UNITEX",
  "MA_SPATULE",
  "HYDRO_SILEX",
  "KOOKIT",
  "PEDIAKID",
  "CHILL_SILK",
  "YOURSTORE",
  "TILYO",
  "ANTINOMIE",
  "UMAI_NATURAL",
  "BX_LOGISTIQUE",
  "STC",
  "JUNGLEVET",
  "CORAIL",
  "FREDMECENE",
  "BIENAIME_1935",
  "THE_MAGIC_BOX",
  "LITTLE_FRIMOUSSE",
  "SEECLY",
  "NUTRIWEB",
  "PLOUF",
  "DUC_FACTORY",
  "OUTSIDE_POWERKITER",
  "RACING_LUBES",
  "SAS_ELLIE",
  "EASY_INDUSTRY",
  "KALU_NISSA",
  "SAS_LES_PETITS",
  "FORCE_ULTRA_NATURE",
  "ALME",
  "PARABIO_SANTE",
  "BEST_OF_NATURAL",
  "CRLG",
  "MALONGO",
  "RIF_LUMINAIRE",
  "CASANERA",
  "CHEPAKEE",
  "MAX_AND_THE_CITY",
  "EKINSPORT",
  "KIPLI",
  "BIOTHELYA",
  "DELICITY",
  "ZESTED_COSMETIC",
  "MINIWEIGHT",
  "COLLECTION_GRANDS_CRUS",
  "LA_BOUTIQUE_DES_MARQUES",
  "TAMO",
  "HERCULE_STUDIO",
  "ASTRIN",
  "DRESS_CODE_PRODUCTION",
  "AMOI",
  "JK_COMPANY",
  "ALPPOS",
  "LA_CANOPEE",
  "BANDEJASHOP",
  "HUNTERS_QUEST",
  "CB_SALES",
  "BELYS_FRANCE",
  "HACHETTE_WEB",
  "CLIC_AND_FIT",
  "VITALITY",
  "WATTS",
  "AIGLE",
  "HAPPY_COLIS",
  "PIXMANIA_THE_KASE",
  "THE_OTHER_STORE",
  "FEED_SMART_FOOD",
  "CYRILLUS",
  "BEASTYBIKE",
  "DSL",
  "NASSAY",
  "PONGO",
  "DALLET",
  "GREENLOG",
  "MANOEL_LOG_38",
  "VYB_REIKO",
  "UN_JOUR_UN_DESERT",
  "HATTILA",
  "MATIM_LOGISTIC",
  "ANKYA"
];

const mondialRelayOptions = [
  "Litiges / Livraison non conforme",
  "Instructions expéditeur",
  "Déclencher une enquête / Où est mon colis ?",
  "Demande de preuve de livraison"
];

const ManualConversation = ({ onRefresh, ticketFiles, ticket }) => {
  const { t } = useTranslation();
  const [manualCarrier, setManualCarrier] = useState("");
  const [manualParams, setManualParams] = useState({});
  const [selectedObjet, setSelectedObjet] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { ticketId } = useParams();

  const handleParamChange = (field, value) => {
    setManualParams(prevParams => {
      const newParams = {
        ...prevParams,
        [field]: value
      };
      // Sync tracking_number and tracking_id
      if (field === 'tracking_number') {
        newParams.tracking_id = value;
      } else if (field === 'tracking_id') {
        newParams.tracking_number = value;
      }
      return newParams;
    });
  };

  // Pre-fill params when carrier changes using ticket data
  const handleCarrierChange = (e) => {
    const carrier = e.target.value;
    setManualCarrier(carrier);
    
    // Initialize params with ticket data when available
    const initialParams = {};
    if (ticket) {
      if (carrier === "Colis Privé" || carrier === "Colissimo") {
        if (ticket.package_value) initialParams.package_value = ticket.package_value;
        if (ticket.package_content) initialParams.package_content = ticket.package_content;
      }
      if (ticket.tracking_number) {
        initialParams.tracking_number = ticket.tracking_number;
        initialParams.tracking_id = ticket.tracking_number; // Sync tracking_id with tracking_number
      }
    }
    
    setManualParams(initialParams);
    setSelectedObjet("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setSuccessMessage("");

    const token = sessionStorage.getItem('access_token');
    const formData = new FormData();
    formData.append("carrier_name", manualCarrier);
    formData.append("ticket_id", ticketId);
    formData.append("params", JSON.stringify(manualParams));
    
    if (ticketFiles?.length > 0 && manualCarrier !== "Mondial Relay") {
      formData.append("file_urls", JSON.stringify(ticketFiles.map(file => ({
        url: file.url,
        file_type: file.mime_type,
        file_name: file.name
      }))));
    }

    try {
      await axios.post(
        '/api/webhook/manual-carrier-conversation',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setSuccessMessage(t('manualConversation.success'));
      setManualCarrier("");
      setManualParams({});
      setSelectedObjet("");
      if (onRefresh) onRefresh();
    } catch (err) {
      console.error('Error starting manual carrier conversation:', err);
      setError(t('manualConversation.error'));
    }
    setIsSubmitting(false);
  };

  const renderAdditionalFields = () => {
    if (!manualCarrier) return null;
    
    const requirements = carrierRequirements[manualCarrier];
    
    if (Array.isArray(requirements)) {
      return requirements.map(field => (
        <div key={field} className="param-item">
          <label className="param-label">
            {t(`manualConversation.${field}`) || field}:
          </label>
          {(manualCarrier === "Colis Privé" && field === "motif") || 
           (manualCarrier === "Colissimo" && field === "motif_text") ? (
            <select
              value={manualParams[field] || ""}
              onChange={(e) => handleParamChange(field, e.target.value)}
              className="param-input"
            >
              <option value="">
                {t('manualConversation.selectOption') || "Select an option"}
              </option>
              {(manualCarrier === "Colis Privé" ? colisPriveMotifOptions : colissimoMotifOptions).map(option => (
                <option key={option} value={option}>
                  {t(`manualConversation.${option}`) || option}
                </option>
              ))}
            </select>
          ) : manualCarrier === "Mondial Relay" && field === "option_text" ? (
            <select
              value={manualParams[field] || ""}
              onChange={(e) => handleParamChange(field, e.target.value)}
              className="param-input"
            >
              <option value="">
                {t('manualConversation.selectOption') || "Select an option"}
              </option>
              {mondialRelayOptions.map(option => (
                <option key={option} value={option}>
                  {t(`manualConversation.${option}`) || option}
                </option>
              ))}
            </select>
          ) : manualCarrier === "Mondial Relay" && field === "shop_name" ? (
            <select
              value={manualParams[field] || ""}
              onChange={(e) => handleParamChange(field, e.target.value)}
              className="param-input"
            >
              <option value="">
                {t('manualConversation.selectOption') || "Select an option"}
              </option>
              {mondialRelayShops.map(shop => (
                <option key={shop} value={shop}>
                  {shop}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              value={manualParams[field] || ""}
              onChange={(e) => handleParamChange(field, e.target.value)}
              className="param-input"
            />
          )}
        </div>
      ));
    } else if (typeof requirements === 'object') {
      const alwaysFields = requirements.always;
      const objetOptions = Object.keys(requirements.conditional.objet);

      return (
        <>
          {alwaysFields.map(field => (
            <div key={field} className="param-item">
              <label className="param-label">
                {t(`manualConversation.${field}`) || field}:
              </label>
              <input
                type="text"
                value={manualParams[field] || ""}
                onChange={(e) => handleParamChange(field, e.target.value)}
                className="param-input"
              />
            </div>
          ))}
          <div className="param-item">
            <label className="param-label">
              {t('manualConversation.objet') || "Select Object Type"}:
            </label>
            <select
              value={selectedObjet}
              onChange={(e) => {
                setSelectedObjet(e.target.value);
                handleParamChange('objet', e.target.value);
              }}
              className="param-input"
            >
              <option value="">
                {t('manualConversation.selectOption') || "Select an option"}
              </option>
              {objetOptions.map(option => (
                <option key={option} value={option}>
                  {t(`manualConversation.${option}`) || option}
                </option>
              ))}
            </select>
          </div>
          {selectedObjet && requirements.conditional.objet[selectedObjet].map(field => {
            if (field === "motif" && chronopostRequirements[selectedObjet]?.motif_required) {
              return (
                <div key={field} className="param-item">
                  <label className="param-label">
                    {t(`manualConversation.${field}`) || field}:
                  </label>
                  <select
                    value={manualParams[field] || ""}
                    onChange={(e) => handleParamChange(field, e.target.value)}
                    className="param-input"
                  >
                    <option value="">
                      {t('manualConversation.selectOption') || "Select an option"}
                    </option>
                    {chronopostRequirements[selectedObjet].motif_options.map(option => (
                      <option key={option} value={option}>
                        {t(`manualConversation.${option}`) || option}
                      </option>
                    ))}
                  </select>
                </div>
              );
            } else if (field === "product_family") {
              return (
                <div key={field} className="param-item">
                  <label className="param-label">
                    {t(`manualConversation.${field}`) || field}:
                  </label>
                  <select
                    value={manualParams[field] || ""}
                    onChange={(e) => handleParamChange(field, e.target.value)}
                    className="param-input"
                  >
                    <option value="">
                      {t('manualConversation.selectOption') || "Select an option"}
                    </option>
                    {productFamilyOptions.map(option => (
                      <option key={option} value={option}>
                        {t(`manualConversation.${option}`) || option}
                      </option>
                    ))}
                  </select>
                </div>
              );
            } else {
              return (
                <div key={field} className="param-item">
                  <label className="param-label">
                    {t(`manualConversation.${field}`) || field}:
                  </label>
                  <input
                    type="text"
                    value={manualParams[field] || ""}
                    onChange={(e) => handleParamChange(field, e.target.value)}
                    className="param-input"
                  />
                </div>
              );
            }
          })}
        </>
      );
    }
    return null;
  };

  const getFileIcon = (mimeType) => {
    if (mimeType.includes('pdf')) {
      return <FaFilePdf />;
    } else if (mimeType.includes('image')) {
      return <FaFileImage />;
    } else if (mimeType.includes('zip') || mimeType.includes('rar')) {
      return <FaFileArchive />;
    } else if (mimeType.includes('code') || mimeType.includes('json')) {
      return <FaFileCode />;
    }
    return <FaFileAlt />;
  };

  const getFileClass = (mimeType) => {
    if (mimeType.includes('pdf')) {
      return 'pdf';
    } else if (mimeType.includes('image')) {
      return 'image';
    }
    return 'document';
  };

  return (
    <div className="manual-carrier-conversation-form">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            {t('manualConversation.carrierName') || "Carrier Name"}:
          </label>
          <select 
            value={manualCarrier} 
            onChange={handleCarrierChange}
            className="param-input"
          >
            <option value="">{t('manualConversation.selectCarrier') || "Select Carrier"}</option>
            {Object.keys(carrierRequirements).map(carrier => (
              <option key={carrier} value={carrier}>
                {carrier}
              </option>
            ))}
          </select>
        </div>

        {renderAdditionalFields()}

        {error && <div className="error-message">{error}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}

        <button 
          type="submit" 
          disabled={isSubmitting}
          className="submit-button"
        >
          {isSubmitting
            ? t('manualConversation.submitting') || "Submitting..."
            : t('manualConversation.submit') || "Submit"}
        </button>
      </form>
    </div>
  );
};

export default ManualConversation;
