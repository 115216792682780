import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './dashboard.css';
import TicketTableDashboard from './TicketTableDashboard';
import { useTranslation } from 'react-i18next';
import { 
    FaTicketAlt, 
    FaRobot, 
    FaBoxOpen, 
    FaShoppingCart, 
    FaChartLine,
    FaExternalLinkAlt,
    FaExclamationCircle,
    FaUsers,
    FaTrophy,
    FaSmile,
    FaMoneyBillWave,
    FaExclamationTriangle,
    FaBox
} from 'react-icons/fa';
import { Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

const Dashboard = () => {
    const [user, setUser] = useState(undefined);
    const [tickets, setTickets] = useState([]);
    const [monthlyUsage, setMonthlyUsage] = useState({ tickets_used: 0, ticket_limit: 0, extra_tickets_purchased: 0, remaining_extra_tickets: 0 });
    const [ticketStats, setTicketStats] = useState({
        total_tickets: 0,
        ai_resolved_tickets: 0,
        assigned_tickets: 0,
        open_tickets: 0,
        pending_tickets: 0,
        closed_tickets: 0,
        resolved_tickets: 0,
        automation_ratio: 0,
        automated_tickets: 0,
        remaining_tickets: 0,
        waiting_carrier_response: 0,
        new_tickets: 0,
        affected_tickets: 0,
        waiting_client_response: 0,
        user_type: ''
    });
    const [orders, setOrders] = useState([]);
    const [returns, setReturns] = useState([]);
    const [attentionProducts, setAttentionProducts] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const wsRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);
    const [expandedCards, setExpandedCards] = useState({});
    const [tagsStats, setTagsStats] = useState(null);
    const [clientStats, setClientStats] = useState({
        total_clients: 0,
        total_tickets: 0,
        most_active_client: null,
        clients: []
    });
    const [isStatsCardCollapsed, setIsStatsCardCollapsed] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [shopStats, setShopStats] = useState(null);
    const [isShopConfigured, setIsShopConfigured] = useState(true);

    const connectWebSocket = () => {
        const token = sessionStorage.getItem('access_token');
        if (!token) return;

        // Check token expiration before connecting
        try {
            const tokenPayload = JSON.parse(atob(token.split('.')[1]));
            if (tokenPayload.exp && tokenPayload.exp * 1000 < Date.now()) {
                console.log('Token has expired, not connecting WebSocket');
                // Clear session and redirect to login if needed
                sessionStorage.removeItem('access_token');
                navigate('/');
                return;
            }
        } catch (error) {
            console.error('Error parsing token:', error);
            return;
        }

        wsRef.current = new WebSocket(`wss://${window.location.host}/api/ws?token=${token}`);
        
        wsRef.current.onopen = (event) => {
            console.log('WebSocket connection established:', event);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }
        };

        wsRef.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        wsRef.current.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            console.log('Close code:', event.code);
            console.log('Close reason:', event.reason);
            
            // Check if closure was due to authentication error (typical codes for auth issues)
            if (event.code === 1008 || event.code === 4000 || event.code === 4001 || 
                event.reason?.toLowerCase().includes('token') || 
                event.reason?.toLowerCase().includes('auth')) {
                console.log('WebSocket closed due to authentication issue, not reconnecting');
                // Optionally redirect to login
                sessionStorage.removeItem('access_token');
                navigate('/');
                return;
            }

            reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
        };

        wsRef.current.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
            if (event.data === 'ping') {
                wsRef.current.send('pong');
            } else {
                fetchTickets(token);
            }
        };
    };

    useEffect(() => {
        const token = sessionStorage.getItem('access_token');
        if (!token) {
            navigate('/');
            return;
        }

        const fetchAllData = async () => {
            try {
                setIsLoading(true);
                
                const userData = await fetchUserData(token);
                
                const promises = [
                    fetchTickets(token),
                    fetchMonthlyUsage(token),
                    fetchTicketStats(token)
                ];
                
                if (!userData?.edn_api_account) {
                    promises.push(
                        fetchOrders(token),
                        fetchAttentionProducts(),
                        fetchReturns(token),
                        fetchShopStatistics(token)
                    );
                } else {
                    promises.push(fetchTagsStatistics(token));
                }
                
                await Promise.all(promises);
                await fetchClientStats(token);

            } catch (error) {
                console.error('Error loading dashboard data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAllData();
        connectWebSocket();

        const pingInterval = setInterval(() => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send('ping');
            }
        }, 30000);

        return () => {
            clearInterval(pingInterval);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [navigate]);

    const fetchUserData = async (token) => {
        try {
            const response = await axios.get('/api/users/user', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUser(response.data);
            if (response.data?._id) {
                sessionStorage.setItem('user_id', response.data._id);
            }
            return response.data;
        } catch (error) {
            console.error('Failed to fetch user data:', error);
            setUser(null);
            return null;
        }
    };

    const fetchMonthlyUsage = async (token) => {
        try {
            const response = await axios.get('/api/users/monthly-usage', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMonthlyUsage(response.data || { tickets_used: 0, ticket_limit: 0, extra_tickets_purchased: 0, remaining_extra_tickets: 0 });
        } catch (error) {
            console.error('Failed to fetch monthly usage:', error);
        }
    };

    const fetchTickets = async (token) => {
        try {
            const response = await axios.get('/api/tickets/tickets', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setTickets(response.data.tickets || []);
        } catch (error) {
            console.error('Failed to fetch tickets:', error);
        }
    };

    const fetchTicketStats = async (token) => {
        try {
            const response = await axios.get('/api/tickets/stats', {
                headers: { Authorization: `Bearer ${token}` }
            });
            console.log('API response:', response.data);
            setTicketStats(response.data || {
                total_tickets: 0,
                ai_resolved_tickets: 0,
                assigned_tickets: 0,
                open_tickets: 0,
                pending_tickets: 0,
                closed_tickets: 0,
                resolved_tickets: 0,
                automation_ratio: 0,
                automated_tickets: 0,
                remaining_tickets: 0,
                waiting_carrier_response: 0,
                new_tickets: 0,
                affected_tickets: 0,
                waiting_client_response: 0,
                user_type: ''
            });
        } catch (error) {
            console.error('Failed to fetch ticket stats:', error);
        }
    };

    const fetchOrders = async (token) => {
        try {
            const response = await axios.get('/api/shopify/get_orders', {
                headers: { Authorization: `Bearer ${token}` },
                params: { limit: 5 }
            });
            if (response.data.status === "unconfigured") {
                setOrders([]);
            } else {
                setOrders(response.data.orders || []);
            }
        } catch (error) {
            console.error('Failed to fetch orders:', error);
            setOrders([]);
        } 
    };

    const fetchReturns = async (token) => {
        try {
            const response = await axios.get('/api/webhook/returns', {
                headers: { Authorization: `Bearer ${token}` }
            });
            const returnsData = response.data.returns || [];
            setReturns(returnsData.slice(0, 5));
        } catch (error) {
            console.error('Failed to fetch returns:', error);
            setReturns([]);
        }
    };

    const fetchAttentionProducts = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get('/api/shopify/get_products_needing_attention', {
                headers: { Authorization: `Bearer ${token}` }
            });
            if (response.data.status === "unconfigured" || !response.data.products) {
                setAttentionProducts([]);
            } else {
                setAttentionProducts(response.data.products.slice(0, 5)); // Only get top 5
            }
        } catch (error) {
            console.error('Failed to fetch attention products:', error);
            setAttentionProducts([]);
        }
    };

    const fetchTagsStatistics = async (token) => {
        try {
            const response = await axios.get('/api/tickets/tickets-tags-stats', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setTagsStats(response.data);
        } catch (error) {
            console.error('Error fetching tags statistics:', error);
        }
    };

    const fetchClientStats = async (token) => {
        try {
            const response = await axios.get('/api/tickets/client-stats', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setClientStats(response.data);
        } catch (error) {
            console.error('Failed to fetch client stats:', error);
        }
    };

    const fetchShopStatistics = async (token) => {
        try {
            const response = await axios.get('/api/shopify/get_shop_statistics', {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data.status === "unconfigured") {
                setIsShopConfigured(false);
                setShopStats(null);
            } else {
                setIsShopConfigured(true);
                setShopStats(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch shop statistics:', error);
            setShopStats(null);
            setIsShopConfigured(false);
        }
    };

    const handleTicketClick = async (ticketId) => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(`/api/tickets/${ticketId}/status`, null, {
                headers: { Authorization: `Bearer ${token}` }
            });
            navigate(`/tickets/${ticketId}/messages`);
        } catch (error) {
            console.error('Failed to update ticket status:', error);
        }
    };

    const toggleCard = (cardId) => {
        setExpandedCards(prev => ({
            ...prev,
            [cardId]: !prev[cardId]
        }));
    };

    const formatReason = (reason) => {
        const reasons = {
            out_of_stock: 'Out of Stock',
            low_stock: 'Low Stock',
            overstock: 'Overstock',
            low_sales_velocity: 'Low Sales',
            high_discount: 'High Discount',
            missing_handle: 'SEO Issue',
            thin_content: 'Thin Content',
            no_images: 'No Images',
            few_images: 'Few Images',
            no_tags: 'No Tags',
            single_variant: 'Single Variant'
        };
        return reasons[reason] || reason;
    };

    const tagsChartData = tagsStats?.tags_statistics ? {
        labels: tagsStats.tags_statistics.map(item => item._id),
        datasets: [{
            data: tagsStats.tags_statistics.map(item => item.percentage),
            backgroundColor: [
                '#00ff9d', '#00ffff', '#ff00ff', 
                '#8000ff', '#ffff00', '#ff0000', '#0099ff'
            ],
        }],
    } : null;

    const toggleStatsCard = () => {
        setIsStatsCardCollapsed(!isStatsCardCollapsed);
    };

    if (isLoading) {
        return (
            <div className="una-dashboard-loading-screen">
                <div className="una-loading-content">
                    <div className="una-loading-logo">
                        <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
                    </div>
                    <h2>{t('loadingDashboard')}</h2>
                    <div className="una-loading-progress">
                        <div className="una-progress-bar">
                            <div className="una-progress-glow"></div>
                        </div>
                    </div>
                    <div className="una-loading-metrics">
                        <div className="una-metric-item">
                            <FaTicketAlt />
                            <span>{t('loadingTickets')}</span>
                        </div>
                        <div className="una-metric-item">
                            <FaShoppingCart />
                            <span>{t('loadingOrders')}</span>
                        </div>
                        <div className="una-metric-item">
                            <FaChartLine />
                            <span>{t('loadingStats')}</span>
                        </div>
                        <div className="una-metric-item">
                            <FaBoxOpen />
                            <span>{t('loadingReturns')}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="una-dashboard-container">
            {/* Only show stats header for edn_api_account users */}
            {user?.edn_api_account && (
                <div className="una-dashboard-header">
                    <div className="una-dashboard-stats">
                        <div className="una-stats-card">
                            {!isStatsCardCollapsed && (
                                <div className="una-stats-card-content">
                                    <div className="una-stats-section">
                                        <div className="una-stats-grid">
                                            <div className="una-metric-item primary">
                                                <div className="una-metric-icon primary">
                                                    <FaTicketAlt />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value primary">{ticketStats.new_tickets}</span>
                                                    <span className="una-metric-label">{t('newTickets')}</span>
                                                </div>
                                            </div>
                                            
                                            <div className="una-metric-item secondary">
                                                <div className="una-metric-icon secondary">
                                                    <FaRobot />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value secondary">{ticketStats.automated_tickets}</span>
                                                    <span className="una-metric-label">{t('automatedTickets')}</span>
                                                </div>
                                            </div>
                                            
                                            <div className="una-metric-item secondary">
                                                <div className="una-metric-icon secondary">
                                                    <FaTicketAlt />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value secondary">{ticketStats.waiting_client_response}</span>
                                                    <span className="una-metric-label">{t('waitingClientResponse')}</span>
                                                </div>
                                            </div>
                                            
                                            <div className="una-metric-item tertiary">
                                                <div className="una-metric-icon tertiary">
                                                    <FaTicketAlt />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value tertiary">{ticketStats.waiting_carrier_response}</span>
                                                    <span className="una-metric-label">{t('waitingCarrierResponse')}</span>
                                                </div>
                                            </div>
                                            
                                            <div className="una-metric-item tertiary">
                                                <div className="una-metric-icon tertiary">
                                                    <FaTicketAlt />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value tertiary">{ticketStats.resolved_tickets}</span>
                                                    <span className="una-metric-label">{t('resolvedTickets')}</span>
                                                </div>
                                            </div>
                                            
                                            <div className="una-metric-item tertiary">
                                                <div className="una-metric-icon tertiary">
                                                    <FaTicketAlt />
                                                </div>
                                                <div className="una-metric-details">
                                                    <span className="una-metric-value tertiary">{ticketStats.closed_tickets}</span>
                                                    <span className="una-metric-label">{t('closedTickets')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="una-dashboard-main">
                {user?.edn_api_account ? (
                    <TicketTableDashboard 
                        tickets={tickets} 
                        user={user}
                        onTicketClick={handleTicketClick}
                    />
                ) : !isShopConfigured ? (
                    <div className="una-unconfigured-shop">
                        <div className="una-welcome-message">
                            <FaShoppingCart className="una-welcome-icon" />
                            <h2>{t('welcomeToUnanimity')}</h2>
                            <p>{t('configureShopMessage')}</p>
                            <button 
                                className="una-primary-button"
                                onClick={() => navigate('/parameters')}
                            >
                                {t('configureShop')}
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="una-business-dashboard">
                        {/* Shop Statistics Overview */}
                        {shopStats && (
                            <div className="una-store-overview">
                                <div className="una-stats-grid">
                                    <div className="una-stat-card revenue">
                                        <FaMoneyBillWave />
                                        <div className="una-stat-content">
                                            <h3>{t('shopStats.totalRevenue')}</h3>
                                            <p>${shopStats.orders?.total_revenue?.toFixed(2) || '0.00'}</p>
                                            <span>{t('shopStats.avgOrder')}: ${shopStats.orders?.average_order_value?.toFixed(2) || '0.00'}</span>
                                        </div>
                                    </div>

                                    <div className="una-stat-card orders">
                                        <FaShoppingCart />
                                        <div className="una-stat-content">
                                            <h3>{t('shopStats.totalOrders')}</h3>
                                            <p>{shopStats.orders?.total_orders || 0}</p>
                                            <span>{t('shopStats.avgItems')}: {shopStats.sales_analysis?.average_items_per_order?.toFixed(1) || '0.0'}</span>
                                        </div>
                                    </div>

                                    <div className="una-stat-card customers">
                                        <FaUsers />
                                        <div className="una-stat-content">
                                            <h3>{t('shopStats.customers')}</h3>
                                            <p>{shopStats.customers?.total_customers || 0}</p>
                                            <span>{t('shopStats.newCustomers')}: {shopStats.customers?.new_customers_30d || 0}</span>
                                        </div>
                                    </div>

                                    <div className="una-stat-card products">
                                        <FaBox />
                                        <div className="una-stat-content">
                                            <h3>{t('shopStats.products')}</h3>
                                            <p>{shopStats.products?.total_products || 0}</p>
                                            <span>{t('shopStats.variants')}: {shopStats.products?.total_variants || 0}</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Quick Alerts */}
                                <div className="una-alerts-section">
                                    {shopStats.products?.out_of_stock_count > 0 && (
                                        <div className="una-alert out-of-stock">
                                            <FaExclamationTriangle />
                                            <span>{shopStats.products.out_of_stock_count} {t('shopStats.outOfStock')}</span>
                                        </div>
                                    )}
                                    {shopStats.products?.low_stock_count > 0 && (
                                        <div className="una-alert low-stock">
                                            <FaExclamationTriangle />
                                            <span>{shopStats.products.low_stock_count} {t('shopStats.lowStock')}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        
                        {/* Main dashboard grid with key information - now with 3 cards instead of 4 */}
                        <div className="una-dashboard-grid">
                            <div className="una-dashboard-card una-full-content">
                                <div className="una-card-header">
                                    <FaShoppingCart className="orders-icon" />
                                    <h3>{t('recentOrders')}</h3>
                                    <button onClick={() => navigate('/orders')} className="una-view-all">
                                        <FaExternalLinkAlt />
                                    </button>
                                </div>
                                <div className="una-card-content">
                                    {orders.slice(0, 5).map(order => (
                                        <div key={order.id} className="una-compact-item" onClick={() => navigate(`/order/${order.id}`)}>
                                            <div className="una-compact-info">
                                                <span className="una-primary-text">#{order.order_number}</span>
                                                <span className="una-secondary-text">{order.customer?.name}</span>
                                            </div>
                                            <div className="una-compact-details">
                                                <span className="una-price-tag">${order.total_price}</span>
                                                <span className={`una-status-badge-dashboard ${order.financial_status?.toLowerCase()}`}>
                                                    {order.financial_status}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="una-dashboard-card una-full-content">
                                <div className="una-card-header">
                                    <FaBoxOpen className="returns-icon" />
                                    <h3>{t('recentReturns')}</h3>
                                    <button onClick={() => navigate('/returns')} className="una-view-all">
                                        <FaExternalLinkAlt />
                                    </button>
                                </div>
                                <div className="una-card-content">
                                    {returns.slice(0, 5).map(returnItem => (
                                        <div 
                                            key={returnItem._id} 
                                            className="una-compact-item"
                                            onClick={() => navigate(`/return/${returnItem._id}`)}
                                        >
                                            <div className="una-compact-info">
                                                <span className="una-primary-text">{returnItem.items?.[0]?.title || 'No title'}</span>
                                                <span className="una-secondary-text">#{returnItem.order_id}</span>
                                            </div>
                                            <div className="una-compact-details">
                                                <span className="una-price-tag">
                                                    €{(returnItem.items?.[0]?.price !== undefined ? 
                                                        Number(returnItem.items[0].price).toFixed(2) : 
                                                        '0.00')}
                                                </span>
                                                <span className={`una-status-badge-dashboard ${returnItem.status.replace('_', ' ').replace(' ', '-').toLowerCase()}`}>
                                                    {returnItem.status.replace('_', ' ')}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        
                            <div className="una-dashboard-card una-full-content">
                                <div className="una-card-header">
                                    <FaExclamationCircle className="attention-icon" />
                                    <h3>{t('productsNeedingAttention')}</h3>
                                    <button onClick={() => navigate('/shop-statistics')} className="una-view-all">
                                        <FaExternalLinkAlt />
                                    </button>
                                </div>
                                <div className="una-card-content">
                                    {attentionProducts.slice(0, 5).map(product => (
                                        <div 
                                            key={product.id} 
                                            className="una-compact-item"
                                            onClick={() => navigate(`/product/${product.id}`)}
                                        >
                                            <div className="una-compact-info">
                                                <span className="una-primary-text">{product.title}</span>
                                                <div className="una-compact-tags">
                                                    {product.health_status?.reasons?.slice(0, 2).map((reason, index) => (
                                                        <span key={index} className={`una-reason-tag ${reason}`}>
                                                            {formatReason(reason)}
                                                        </span>
                                                    ))}
                                                    {product.health_status?.reasons?.length > 2 && (
                                                        <span className="una-more-tag">+{product.health_status.reasons.length - 2}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="una-compact-details">
                                                {product.health_status?.metrics?.sales_30_days !== undefined && (
                                                    <span className="una-metric-tag">
                                                        <FaShoppingCart />
                                                        {product.health_status.metrics.sales_30_days}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        
                        {/* Ticket table shown after the main dashboard */}
                        <div className="una-tickets-section">
                            <div className="una-section-header">
                                <FaTicketAlt className="tickets-icon" />
                                <h2>{t('supportTickets')}</h2>
                            </div>
                            <TicketTableDashboard 
                                tickets={tickets} 
                                user={user}
                                onTicketClick={handleTicketClick}
                                compact={true}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dashboard;