import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FaCalendar, FaList, FaTruck, FaShareAlt, FaShoppingCart, FaFileInvoice, FaCreditCard, FaUser, FaShoppingBag } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './LiveList.css';

const LiveList = () => {
  const { t } = useTranslation();
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLists = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/shopify/live_products_lists', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setLists(response.data.lists);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLists();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return t('liveList.notScheduled');
    return new Date(dateString).toLocaleString();
  };

  const handleShare = async (listId, existingFormUrl) => {
    try {
      if (existingFormUrl) {
        const shareUrl = `${window.location.origin}${existingFormUrl}`;
        navigator.clipboard.writeText(shareUrl);
        alert(t('liveList.shareLinkCopied'));
        return;
      }

      const token = sessionStorage.getItem('access_token');
      const response = await axios.post(
        `/api/shopify/create_public_form_link`,
        {
          list_id: listId,
          expires_at: null,
          max_uses: null
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      const shareUrl = `${window.location.origin}${response.data.form_url}`;
      navigator.clipboard.writeText(shareUrl);
      alert(t('liveList.shareLinkCopied'));
      
      // Update the lists state to include the new form_url
      setLists(lists.map(list => 
        list.id === listId 
          ? { ...list, form_url: response.data.form_url }
          : list
      ));
    } catch (err) {
      setError(t('liveList.shareError'));
    }
  };

  const handleToggleStatus = async (listId, currentStatus) => {
    try {
      const token = sessionStorage.getItem('access_token');
      
      await axios.patch(
        `/api/shopify/public_form/${listId}/status`,
        {
          is_active: !currentStatus
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      // Update the lists state to reflect the new status
      setLists(lists.map(list => 
        list.id === listId 
          ? { ...list, is_active: !currentStatus }
          : list
      ));
    } catch (err) {
      setError('Failed to update list status');
    }
  };

  if (error) return <div className="una-error">{t('common.error')}: {error}</div>;
  if (isLoading) {
    return (
      <div className="una-dashboard-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
          <h2>{t('liveList.loadingTitle')}</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaShoppingCart />
              <span>{t('liveList.fetchingLists')}</span>
            </div>
            <div className="una-metric-item">
              <FaUser />
              <span>{t('liveList.loadingProducts')}</span>
            </div>
            <div className="una-metric-item">
              <FaShoppingBag />
              <span>{t('liveList.processingData')}</span>
            </div>
            <div className="una-metric-item">
              <FaCreditCard />
              <span>{t('liveList.preparingDisplay')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-live-list-container">
      <div className="una-list-header">
        <h2><FaList /> {t('liveList.title')}</h2>
        <Link to="/fast-live" className="una-create-button">
          {t('liveList.createNew')}
        </Link>
      </div>

      <div className="una-lists-grid">
        {lists.map((list) => (
          <div 
            key={list.id} 
            className="una-list-card"
            onClick={() => navigate(`/live-list/${list.id}`)}
            style={{ cursor: 'pointer' }}
          >
            <div className="una-list-card-header">
              <h3>{list.name}</h3>
              <div className="una-list-card-actions">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShare(list.id, list.form_url);
                  }}
                  className="una-share-button"
                >
                  <FaShareAlt /> {t('common.share')}
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleToggleStatus(list.id, list.is_active);
                  }}
                  className={`una-toggle-button ${list.is_active ? 'active' : 'inactive'}`}
                >
                  {list.is_active ? t('liveList.deactivate') : t('liveList.activate')}
                </button>
              </div>
            </div>

            <div className="una-list-details">
              <p>
                <FaCalendar /> {formatDate(list.scheduled_date)}
              </p>
              <p>
                <FaTruck /> {t('liveList.shipping')}: {list.shipping_fee} €
              </p>
              {list.platform && (
                <p className="una-platform-tag">
                  {list.platform.charAt(0).toUpperCase() + list.platform.slice(1)}
                </p>
              )}
            </div>

            <div className="una-products-preview">
              {list.preview_products.map((product) => (
                <div key={product.id} className="una-product-preview">
                  {product.image_url && (
                    <img
                      src={product.image_url}
                      alt={product.title}
                      className="una-product-thumbnail"
                    />
                  )}
                  <span>{product.title}</span>
                </div>
              ))}
              {list.product_count > 3 && (
                <div className="una-more-products">
                  +{list.product_count - 3} {t('liveList.moreItems')}
                </div>
              )}
            </div>

            {list.description && (
              <p className="una-list-description">{list.description}</p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiveList;
