import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Pie, Line, Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from 'chart.js';
import {
  FaBoxOpen,
  FaWarehouse,
  FaChartBar,
  FaDollarSign,
  FaClock,
  FaChartLine,
  FaSmile,
  FaTicketAlt,
  FaRobot,
  FaChartPie,
  FaTags,
} from 'react-icons/fa';
import './SentimentStatistics.css';

// Register ChartJS components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
);

// Color definitions
const CHART_COLORS = {
  sentiment: {
    line: '#00ff9d', // neon green
    background: 'rgba(0, 255, 157, 0.1)',
  },
  resolved: {
    line: '#00ffff', // neon cyan
    background: 'rgba(0, 255, 255, 0.1)',
  },
  aiResolved: {
    line: '#ff00ff', // neon magenta
    background: 'rgba(255, 0, 255, 0.1)',
  },
};

const PIE_COLORS = [
  '#00ff9d', // neon green
  '#00ffff', // neon cyan
  '#ff00ff', // neon magenta
  '#8000ff', // neon purple
  '#ffff00', // neon yellow
  '#ff0000', // neon red
  '#0099ff', // neon blue
];

const UserStatisticsPanel = ({ userRole }) => {
  const { t } = useTranslation();

  // State variables
  const [sentimentStats, setSentimentStats] = useState(null);
  const [isSentimentLoading, setIsSentimentLoading] = useState(true);
  const [sentimentError, setSentimentError] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('weekly');

  // Fetch data on mount
  useEffect(() => {
    fetchSentimentStatistics();
  }, []);

  const fetchSentimentStatistics = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/tickets/tickets/sentiment-statistics', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSentimentStats(response.data);
      setIsSentimentLoading(false);
    } catch (error) {
      console.error('Error fetching sentiment statistics:', error);
      setSentimentError(t('userStatistics.fetchError'));
      setIsSentimentLoading(false);
    }
  };

  // Error handling
  if (sentimentError) return <div className="una-stats-error">{sentimentError}</div>;

  // Loading screen
  if (isSentimentLoading) {
    return (
      <div className="una-sentiment-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
          <h2>{t('sentimentStatistics.loadingTitle', 'Analyzing Support Data')}</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaSmile />
              <span>{t('sentimentStatistics.loadingMetrics.sentiment', 'Processing Sentiment Analysis')}</span>
            </div>
            <div className="una-metric-item">
              <FaTicketAlt />
              <span>{t('sentimentStatistics.loadingMetrics.tickets', 'Calculating Ticket Statistics')}</span>
            </div>
            <div className="una-metric-item">
              <FaRobot />
              <span>{t('sentimentStatistics.loadingMetrics.ai', 'Processing AI Resolutions')}</span>
            </div>
            <div className="una-metric-item">
              <FaTags />
              <span>{t('sentimentStatistics.loadingMetrics.tags', 'Analyzing Tags Distribution')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Sentiment Chart Data
  const sentimentData = sentimentStats?.sentiment_statistics?.[selectedPeriod];
  let sentimentChartData = null;
  if (sentimentData) {
    const labels = sentimentData.map((item) => item._id);
    const negativePercentages = sentimentData.map((item) => {
      const total = item.positive + item.negative + item.neutral;
      return total > 0 ? (item.negative / total) * 100 : 0;
    });

    sentimentChartData = {
      labels,
      datasets: [{
        label: t('sentimentStatistics.negativeSentimentPercentage'),
        data: negativePercentages,
        borderColor: CHART_COLORS.sentiment.line,
        backgroundColor: CHART_COLORS.sentiment.background,
        borderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6,
        tension: 0.4,
        fill: true,
      }],
    };
  }

  // Resolved Chart Data
  const resolvedData = sentimentStats?.resolved_statistics?.[selectedPeriod];
  let resolvedChartData = null;
  if (resolvedData) {
    const labels = resolvedData.map((item) => item._id);
    const resolvedCounts = resolvedData.map((item) => item.resolved);
    const aiResolvedCounts = resolvedData.map((item) => item.ai_resolved);

    resolvedChartData = {
      labels,
      datasets: [
        {
          label: t('sentimentStatistics.resolvedTickets'),
          data: resolvedCounts,
          borderColor: CHART_COLORS.resolved.line,
          backgroundColor: CHART_COLORS.resolved.background,
          borderWidth: 2,
          pointRadius: 4,
          pointHoverRadius: 6,
          tension: 0.4,
          fill: true,
        },
        {
          label: t('sentimentStatistics.aiResolvedTickets'),
          data: aiResolvedCounts,
          borderColor: CHART_COLORS.aiResolved.line,
          backgroundColor: CHART_COLORS.aiResolved.background,
          borderWidth: 2,
          pointRadius: 4,
          pointHoverRadius: 6,
          tension: 0.4,
          fill: true,
        },
      ],
    };
  }


  // Carrier Performance Charts
  const problematicCarriersData = sentimentStats?.carrier_performance?.problematic_carriers;
  let problematicCarriersChartData = null;
  if (problematicCarriersData) {
    problematicCarriersChartData = {
      labels: problematicCarriersData.map((item) => item._id),
      datasets: [{
        label: t('sentimentStatistics.negativeSentimentCount'),
        data: problematicCarriersData.map((item) => item.negative_sentiment),
        backgroundColor: PIE_COLORS[0],
      }],
    };
  }

  const carrierSatisfactionData = sentimentStats?.carrier_performance?.carrier_satisfaction;
  let carrierSatisfactionChartData = null;
  if (carrierSatisfactionData) {
    carrierSatisfactionChartData = {
      labels: carrierSatisfactionData.map((item) => item._id),
      datasets: [{
        label: t('sentimentStatistics.satisfactionScore'),
        data: carrierSatisfactionData.map((item) => item.satisfaction_score * 100),
        backgroundColor: PIE_COLORS[1],
      }],
    };
  }

  // Operational Insights Charts
  const issueTypesData = sentimentStats?.operational_insights?.issue_types;
  let issueTypesChartData = null;
  if (issueTypesData) {
    issueTypesChartData = {
      labels: issueTypesData.map((item) => item._id),
      datasets: [{
        data: issueTypesData.map((item) => item.count),
        backgroundColor: PIE_COLORS,
      }],
    };
  }

  // Chart Options
  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        labels: {
          color: '#fff',
          padding: 20,
          font: {
            size: 13,
            family: "'Roboto', sans-serif",
          },
          usePointStyle: true,
          filter: (item) => !item.text.includes('Background'),
        },
      },
      tooltip: {
        backgroundColor: 'rgba(13, 14, 26, 0.95)',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 12,
        boxPadding: 8,
        cornerRadius: 8,
        displayColors: true,
        borderColor: 'rgba(0, 255, 157, 0.1)',
        borderWidth: 1,
      },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: { color: 'rgba(255, 255, 255, 0.7)', maxRotation: 45, minRotation: 45 },
        border: { display: false },
      },
      y: {
        grid: { color: 'rgba(255, 255, 255, 0.03)', drawBorder: false, lineWidth: 1 },
        ticks: { color: 'rgba(255, 255, 255, 0.7)', padding: 10, maxTicksLimit: 5 },
        border: { display: false },
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="user-statistics-panel">
      {sentimentStats && (
        <>
          {/* Period Selector */}
          <div className="period-selector">
            {['weekly', 'monthly'].map((period) => (
              <button
                key={period}
                className={selectedPeriod === period ? 'active' : ''}
                onClick={() => setSelectedPeriod(period)}
              >
                {t(`sentimentStatistics.period.${period}`)}
              </button>
            ))}
          </div>

          {/* Dashboard Grid Layout */}
          <div className="dashboard-grid">
            {/* Sentiment and Resolved Charts */}
            {sentimentChartData && (
              <div className="dashboard-card sentiment-card">
                <h3>{t('sentimentStatistics.sentimentTitle')}</h3>
                <div className="chart-container small-chart">
                  <Line data={sentimentChartData} options={{...chartOptions, maintainAspectRatio: true}} />
                </div>
              </div>
            )}
            
            {resolvedChartData && (
              <div className="dashboard-card resolved-card">
                <h3>{t('sentimentStatistics.resolvedTitle')}</h3>
                <div className="chart-container small-chart">
                  <Line data={resolvedChartData} options={{...chartOptions, maintainAspectRatio: true}} />
                </div>
              </div>
            )}

            {/* Carrier Performance Charts */}
            {problematicCarriersChartData && (
              <div className="dashboard-card carriers-card">
                <h3>{t('sentimentStatistics.problematicCarriers')}</h3>
                <div className="chart-container small-chart">
                  <Bar
                    data={problematicCarriersChartData}
                    options={{
                      ...chartOptions,
                      maintainAspectRatio: true,
                      indexAxis: 'y',
                      scales: {
                        x: {
                          beginAtZero: true,
                          grid: { display: false },
                          ticks: { color: 'rgba(255, 255, 255, 0.7)' },
                        },
                        y: {
                          grid: { color: 'rgba(255, 255, 255, 0.03)' },
                          ticks: { color: 'rgba(255, 255, 255, 0.7)' },
                        }
                      },
                    }}
                  />
                </div>
              </div>
            )}
            
            {carrierSatisfactionChartData && (
              <div className="dashboard-card satisfaction-card">
                <h3>{t('sentimentStatistics.carrierSatisfaction')}</h3>
                <div className="chart-container small-chart">
                  <Bar
                    data={carrierSatisfactionChartData}
                    options={{
                      ...chartOptions,
                      maintainAspectRatio: true,
                      indexAxis: 'y',
                      scales: {
                        x: {
                          beginAtZero: true,
                          max: 100,
                          grid: { display: false },
                          ticks: { color: 'rgba(255, 255, 255, 0.7)' },
                        },
                        y: {
                          grid: { color: 'rgba(255, 255, 255, 0.03)' },
                          ticks: { color: 'rgba(255, 255, 255, 0.7)' },
                        }
                      },
                    }}
                  />
                </div>
              </div>
            )}

            {/* Issue Types Pie Chart */}
            {issueTypesChartData && (
              <div className="dashboard-card issues-card">
                <h3>{t('sentimentStatistics.issueTypesDistribution')}</h3>
                <div className="chart-container small-chart">
                  <Pie
                    data={issueTypesChartData}
                    options={{
                      ...chartOptions,
                      maintainAspectRatio: true,
                      plugins: {
                        legend: { 
                          position: 'right',
                          labels: {
                            boxWidth: 10,
                            font: { size: 10 }
                          }
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}

            {/* Geographical Analysis */}
            {sentimentStats?.geographical_analysis?.problematic_destinations && (
              <div className="dashboard-card geo-card">
                <h3>{t('sentimentStatistics.problematicDestinations')}</h3>
                <div className="chart-container small-chart">
                  <Bar
                    data={{
                      labels: sentimentStats.geographical_analysis.problematic_destinations.map((item) => item.destination),
                      datasets: [{
                        label: t('sentimentStatistics.issueRate'),
                        data: sentimentStats.geographical_analysis.problematic_destinations.map((item) => item.issue_rate * 100),
                        backgroundColor: PIE_COLORS[4],
                      }],
                    }}
                    options={{
                      ...chartOptions,
                      maintainAspectRatio: true,
                      indexAxis: 'y',
                      scales: {
                        x: {
                          beginAtZero: true,
                          max: 100,
                          grid: { display: false },
                          ticks: { color: 'rgba(255, 255, 255, 0.7)' },
                        },
                        y: {
                          grid: { color: 'rgba(255, 255, 255, 0.03)' },
                          ticks: { color: 'rgba(255, 255, 255, 0.7)' },
                        }
                      },
                    }}
                  />
                </div>
              </div>
            )}

            {/* Client Insights Tables - Compact Version */}
            {sentimentStats?.client_insights?.top_clients_by_volume && (
              <div className="dashboard-card clients-volume-card">
                <h3>{t('sentimentStatistics.topClientsByVolume')}</h3>
                <div className="table-container compact-table">
                  <table>
                    <thead>
                      <tr>
                        <th>{t('sentimentStatistics.clientName')}</th>
                        <th>{t('sentimentStatistics.totalTickets')}</th>
                        <th>{t('sentimentStatistics.negativeTickets')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sentimentStats.client_insights.top_clients_by_volume.slice(0, 3).map((client, index) => (
                        <tr key={index}>
                          <td>{client._id}</td>
                          <td>{client.total_tickets}</td>
                          <td>{client.negative_tickets}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            
            {sentimentStats?.client_insights?.common_client_issues && (
              <div className="dashboard-card client-issues-card">
                <h3>{t('sentimentStatistics.commonClientIssues')}</h3>
                <div className="table-container compact-table">
                  <table>
                    <thead>
                      <tr>
                        <th>{t('sentimentStatistics.clientName')}</th>
                        <th>{t('sentimentStatistics.topIssues')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sentimentStats.client_insights.common_client_issues.slice(0, 3).map((client, index) => (
                        <tr key={index}>
                          <td>{client._id}</td>
                          <td>
                            {client.top_issues.slice(0, 2).map((issue, idx) => (
                              <div key={idx}>{issue.issue_type}: {issue.count}</div>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserStatisticsPanel;