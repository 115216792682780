import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import LogisticDashboard from './components/LogisticDashboard';
import CreateAgent from './components/CreateAgent';
import MessageWindow from './components/MessageWindow';
import PrivateRoute from './components/PrivateRoute';
import AIAssistantManagement from './components/AIAssistantManagement';
import AIAssistant from './components/AIAssistant';
import LogisticsAIAssistantManagement from './components/LogisticsAIAssistantManagement.js';
import UserParameter from './components/UserParameter';
import Archive from './components/Archive';
import ResetPassword from './components/ResetPassword';
import ForgotPassword from './components/ForgotPassword';
import TagList from './components/TagList';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import Payment from './components/Payment';
import InstagramAccountSelection from './components/InstagramAccountSelection';
import CreateForm from './components/CreateForm';
import FormResult from './components/FormResult';
import KnowledgeBoard from './components/KnowledgeBoard';
import Welcome from './components/Welcome';
import Chatbot from './components/ChatbotSettings';
import SentimentStatistics from './components/SentimentStatistics';
import Layout from './components/Layout';
import Billing from './components/ShopifyBillingTest';
import ShopifyCallback from './components/ShopifyCallback';
import Logistician from './components/Logistician.js';
import ChatbotSettingsPage from './components/ChatbotSettingsPage.js'; // La nouvelle page
import ChatbotTest from './components/ChatbotTest';

//import AppInitializer from './components/AppInitializer';
import LanguageSwitcher from './components/LanguageSwitcher';
import OrderDetails from './components/OrderDetails';
import LogisticsOrderDetails from './components/LogisticsOrderDetails';
import ConversationDetails from './components/ConversationDetails';
import LogisticsStatisticsPanel from './components/LogisticsStatisticsPanel';
import LogisticsDashboardTickets from './components/LogisticsDashboardTickets.js';
import CustomerList from './components/CustomerList.js';
import CustomerDetails from './components/CustomerDetails.js';
import ProductsList from './components/ProductsList.js';
import ProductDetails from './components/ProductDetails.js';
import OrdersList from './components/OrdersList.js';
import ShopStatistics from './components/ShopStatistics.js';
import ManageReturns from './components/ManageReturns.js';
import ReturnDetails from './components/ReturnDetails.js';
import Tickets from './components/Tickets.js';
import FastLive from './components/FastLive.js';
import LiveBuyForm from './components/LiveBuyForm.js';
import LiveList from './components/LiveList.js';
import PaymentSuccess from './components/paymentSuccess.js';
import PaymentFailure from './components/PaymentFailure.js';
import LiveListDetails from './components/LiveListDetails';
import DownloadFile from "./components/DownloadFile.js"
import CreateProduct from './components/CreateProduct.js';
import MyMarketplace from './components/MyMarketplace.js';
import MarketplaceListing from './components/MarketplaceListing.js';
import Marketplace from './components/Marketplace.js';
import ShopifyRegister from './components/ShopifyRegister.js';


const LoginPage = () => (
  <div className="login-page-container">
    <LanguageSwitcher />
    <Login />
  </div>
);

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/shopify/callback" element={<ShopifyCallback />} />
        <Route path="/live/:publicToken" element={<LiveBuyForm />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-failure" element={<PaymentFailure />} />
        <Route path="/download/:filename" element={<DownloadFile />} />
        <Route path="/shopify-register" element={<ShopifyRegister />} />


        <Route
          path="/*"
          element={
            <PrivateRoute
              component={({ isAuthenticated, userRole }) => (
                <Layout userRole={userRole} />
              )}
            />
          }
        >
          <Route path="dashboard" element={<Dashboard />} roles={['admin', 'employee']} />
          <Route path="dashboard-logs" element={<LogisticsDashboardTickets />} roles={['logistics']} />
          <Route path="parameters" element={<UserParameter />} roles={['admin','logistics']} />
          <Route path="create-agent" element={<CreateAgent />} roles={['admin']} />
          <Route path="tickets/:ticketId/messages" element={<MessageWindow />} roles={['admin', 'employee','logistics']} />
          <Route path="ai-assistants" element={<AIAssistantManagement />} roles={['admin']} />
          <Route path="my-ai-assistants" element={<AIAssistant />} roles={['admin']} />
          <Route path="logistics-ai-assistants" element={<LogisticsAIAssistantManagement />} roles={['logistics']} />
          <Route path="statistics" element={<SentimentStatistics />} roles={['admin']} />
          <Route path="shop-statistics" element={<ShopStatistics />} roles={['admin']} />
          <Route path="billing" element={<Billing />} roles={['admin']} />
          <Route path="billing-success" element={<Billing />} roles={['admin']} />
          <Route path="billing-failed" element={<Billing />} roles={['admin']} />
          <Route path="chatbot" element={<Chatbot />} roles={['admin']} />
          <Route path="logistician" element={<Logistician />} roles={['admin']} />
          <Route path="Admin_log" element={<Logistician />} roles={['logistics']} />
          <Route path="order/:orderId" element={<OrderDetails />} roles={['admin']}/>
          <Route path="order-logistics/:reference" element={<LogisticsOrderDetails />} roles={['logistics']}/>
          <Route path="logistics-statistics" element={<LogisticsStatisticsPanel />} roles={['logistics']}/>
          <Route path="conversation/:conversationId" element={<ConversationDetails />} roles={['admin', 'logistics']} />
          <Route path="form" element={<CreateForm />} roles={['admin']} />
          <Route path="knowledge-board" element={<KnowledgeBoard />} roles={['admin']} />
          <Route path="form-result/:form_id" element={<FormResult />} roles={['admin','logistics']} />
          <Route path="payment" element={<Payment />} roles={['admin']} />
          <Route path="returns" element={<ManageReturns />} roles={['admin']} />
          <Route path="return/:returnId" element={<ReturnDetails />} roles={['admin']} />
          <Route path="tickets/archive" element={<Archive />} roles={['admin','logistics']} />
          <Route path="tickets" element={<Tickets />} roles={['admin','logistics']} />
          <Route path="tags" element={<TagList />} roles={['admin']} />
          <Route path="chatbot-settings" element={<ChatbotSettingsPage />} roles={['admin', 'logistics']} />
          <Route path="instagram/account-selection" element={<InstagramAccountSelection />} roles={['admin']} />
          <Route path="orders" element={<OrdersList />} roles={['admin', 'logistics']} />
          <Route path="product/:productId" element={<ProductDetails />} roles={['admin', 'logistics']} />
          <Route
            path="customers"
            element={<CustomerList />}
            roles={['admin', 'logistics']}
          />
          <Route
            path="products"
            element={<ProductsList />}
            roles={['admin', 'logistics']}
          />
          <Route
            path="customers/:customerId"
            element={<CustomerDetails />}
            roles={['admin', 'logistics']}
          />
          <Route path="products/create" element={<CreateProduct />} roles={['admin']} />
          <Route path="chatbot-test" element={<ChatbotTest />} roles={['admin']} />
          <Route path="fast-live" element={<FastLive />} roles={['admin']} />
          <Route path="live-list" element={<LiveList />} roles={['admin']} />
          <Route path="live-list/:listId" element={<LiveListDetails />} roles={['admin']} />
          <Route path="my-marketplace" element={<MyMarketplace />} roles={['admin']} />
          <Route path="marketplace-listing" element={<MarketplaceListing />} roles={['admin']} />
          <Route path="marketplace" element={<Marketplace />} roles={['admin']} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;