import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaUsers, FaCrown, FaEnvelope, FaShoppingBag, FaWallet, FaUserFriends, FaChartLine, FaStore } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './CustomerList.css';

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [shopStatus, setShopStatus] = useState('configured');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/shopify/get_customers', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCustomers(response.data.customers || []);
        if (response.data.status === 'unconfigured') {
          setShopStatus('unconfigured');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  const handleCustomerClick = (customerId) => {
    navigate(`/customers/${customerId}`);
  };

  // Sort customers by total spent to identify the top customer
  const sortedCustomers = [...customers].sort((a, b) => 
    parseFloat(b.total_spent) - parseFloat(a.total_spent)
  );

  if (error) return <div className="una-customer-error">Error: {error}</div>;

  if (isLoading) {
    return (
      <div className="una-dashboard-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
          <h2>Loading Customers</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaUsers />
              <span>Fetching Customer Data</span>
            </div>
            <div className="una-metric-item">
              <FaShoppingBag />
              <span>Processing Orders</span>
            </div>
            <div className="una-metric-item">
              <FaStore />
              <span>Loading Store Data</span>
            </div>
            <div className="una-metric-item">
              <FaWallet />
              <span>Calculating Totals</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-customer-container">
      <div className="una-customer-header">
        <div className="una-customer-title">
          <FaUsers />
          <h2>Customers</h2>
        </div>
      </div>

      {isLoading ? (
        <div className="una-customers-loading-state">
          <div className="loading-spinner"></div>
          <p>Loading customers...</p>
        </div>
      ) : shopStatus === 'unconfigured' ? (
        <div className="una-customers-empty-state">
          <FaStore className="empty-icon" />
          <p>Please complete your shop configuration to view customers</p>
          <button 
            className="una-configure-shop-button"
            onClick={() => navigate('/settings')}
          >
            Configure Shop
          </button>
        </div>
      ) : !customers.length ? (
        <div className="una-customers-empty-state">
          <FaUsers className="empty-icon" />
          <p>No customers found</p>
        </div>
      ) : (
        <div className="una-customer-grid">
          {sortedCustomers.map((customer, index) => (
            <div 
              key={customer.id} 
              className={`una-customer-card ${index === 0 ? 'una-customer-card-top' : ''}`}
              onClick={() => handleCustomerClick(customer.id)}
            >
              {index === 0 && (
                <div className="una-customer-crown">
                  <FaCrown />
                </div>
              )}
              
              <div className="una-customer-card-header">
                <h3>{`${customer.first_name} ${customer.last_name}`}</h3>
              </div>

              <div className="una-customer-card-content">
                <div className="una-customer-info">
                  <FaEnvelope />
                  <span>{customer.email}</span>
                </div>

                <div className="una-customer-info">
                  <FaShoppingBag />
                  <span>{customer.orders_count} orders</span>
                </div>

                <div className="una-customer-info">
                  <FaWallet />
                  <span>{customer.total_spent} {customer.currency}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomerList;