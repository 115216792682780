import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaUser, FaEnvelope, FaPhone, FaMapMarkerAlt, FaShoppingCart, FaHistory, FaTags, FaUserCircle, FaAddressCard, FaFileInvoiceDollar } from 'react-icons/fa';
import './CustomerDetails.css';

const CustomerDetails = () => {
  const [customer, setCustomer] = useState(null);
  const [error, setError] = useState(null);
  const { customerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get(`/api/shopify/get_customer/${customerId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCustomer(response.data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchCustomerDetails();
  }, [customerId]);

  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  const handleContactCustomer = (method) => {
    switch (method) {
      case 'email':
        window.location.href = `mailto:${customer.email}`;
        break;
      case 'phone':
        window.location.href = `tel:${customer.phone}`;
        break;
      default:
        break;
    }
  };

  if (error) return <div className="una-customer-error">Error: {error}</div>;
  
  if (!customer) {
    return (
      <div className="una-dashboard-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
          <h2>Loading Customer Details</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaUser />
              <span>Loading Profile Information</span>
            </div>
            <div className="una-metric-item">
              <FaShoppingCart />
              <span>Fetching Order History</span>
            </div>
            <div className="una-metric-item">
              <FaMapMarkerAlt />
              <span>Processing Address Details</span>
            </div>
            <div className="una-metric-item">
              <FaTags />
              <span>Loading Customer Tags</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-customer-details-container">
      <button className="una-back-button" onClick={() => navigate('/customers')}>
        <FaArrowLeft /> Back to Customers
      </button>

      <div className="una-customer-details-grid">
        <div className="una-customer-info-section">
          <div className="una-customer-profile-card">
            <div className="una-customer-avatar">
              <FaUser size={40} />
            </div>
            <h1>{`${customer.first_name} ${customer.last_name}`}</h1>
            <div className="una-customer-stats">
              <div className="una-stat">
                <span className="una-stat-label-customer">Total Orders</span>
                <span className="una-stat-value-customer">{customer.orders_count}</span>
              </div>
              <div className="una-stat">
                <span className="una-stat-label-customer">Total Spent</span>
                <span className="una-stat-value-customer">{customer.total_spent} {customer.currency}</span>
              </div>
              <div className="una-stat">
                <span className="una-stat-label-customer">Average Order</span>
                <span className="una-stat-value-customer">
                  {customer.orders_count > 0 
                    ? `${(parseFloat(customer.total_spent) / customer.orders_count).toFixed(2)} ${customer.currency}`
                    : '0'}
                </span>
              </div>
            </div>
          </div>

          <div className="una-contact-card">
            <h3><FaEnvelope /> Contact Information</h3>
            <div className="una-contact-details">
              <p>{customer.email}</p>
              {customer.phone && <p>{customer.phone}</p>}
              <div className="una-contact-actions">
                <button onClick={() => handleContactCustomer('email')}>
                  <FaEnvelope /> Send Email
                </button>
                {customer.phone && (
                  <button onClick={() => handleContactCustomer('phone')}>
                    <FaPhone /> Call
                  </button>
                )}
              </div>
            </div>
          </div>

          {customer.default_address && (
            <div className="una-address-card">
              <h3><FaMapMarkerAlt /> Default Address</h3>
              <div className="una-address-details">
                <p>{customer.default_address.address1}</p>
                {customer.default_address.address2 && <p>{customer.default_address.address2}</p>}
                <p>{customer.default_address.city}, {customer.default_address.province_code}</p>
                <p>{customer.default_address.country}</p>
                <p>{customer.default_address.zip}</p>
              </div>
            </div>
          )}

          <div className="una-tags-card">
            <h3><FaTags /> Tags</h3>
            <div className="una-customer-tags">
              {Array.isArray(customer.tags) ? customer.tags.map((tag, index) => (
                <span key={index} className="una-customer-tag">
                  {tag.trim()}
                </span>
              )) : null}
            </div>
          </div>
        </div>

        <div className="una-customer-orders-section">
          <div className="una-section-header">
            <h2><FaShoppingCart /> Order History</h2>
          </div>
          
          <div className="una-orders-timeline">
            {customer.orders.map((order) => (
              <div 
                key={order.id} 
                className="una-timeline-order"
                onClick={() => handleOrderClick(order.id)}
              >
                <div className="una-order-date">
                  <FaHistory />
                  {new Date(order.created_at).toLocaleDateString()}
                </div>
                <div className="una-order-card">
                  <div className="una-order-header">
                    <h4>Order #{order.order_number}</h4>
                    <span className={`una-order-status ${order.financial_status}`}>
                      {order.financial_status}
                    </span>
                  </div>
                  <div className="una-order-items">
                    {order.line_items.map((item) => (
                      <div key={item.id} className="una-order-item">
                        <span>{item.title}</span>
                        <span>x{item.quantity}</span>
                        <span>${item.price}</span>
                      </div>
                    ))}
                  </div>
                  <div className="una-order-total">
                    <span>Total</span>
                    <span>${order.total_price}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetails;