import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  FaShoppingCart, FaUser, FaCalendar, FaBox, FaTag, 
  FaFileInvoice, FaShoppingBag, FaCreditCard, FaSort,
  FaSortUp, FaSortDown, FaEye, FaSearch, FaFilter,
  FaExclamationCircle, FaCheckCircle, FaCircle, FaTruck
} from 'react-icons/fa';
import './OrdersList.css';
import { useTranslation } from 'react-i18next';

const OrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [sortConfig, setSortConfig] = useState({
    key: 'created_at',
    direction: 'desc'
  });
  
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/shopify/get_orders', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrders(response.data.orders);
        setFilteredOrders(response.data.orders);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, []);

  // Filter and sort orders when filter criteria change
  useEffect(() => {
    let result = [...orders];
    
    // Apply search filter
    if (searchTerm) {
      const lowerSearch = searchTerm.toLowerCase();
      result = result.filter(order => 
        order.order_number.toString().includes(lowerSearch) ||
        (order.customer?.name && order.customer.name.toLowerCase().includes(lowerSearch)) ||
        (order.customer?.email && order.customer.email.toLowerCase().includes(lowerSearch)) ||
        order.line_items.some(item => item.title.toLowerCase().includes(lowerSearch))
      );
    }
    
    // Apply status filter
    if (statusFilter !== 'all') {
      result = result.filter(order => 
        order.financial_status === statusFilter || 
        order.fulfillment_status === statusFilter
      );
    }
    
    // Apply sorting
    if (sortConfig.key) {
      result.sort((a, b) => {
        let aValue, bValue;
        
        if (sortConfig.key === 'created_at') {
          aValue = new Date(a.created_at).getTime();
          bValue = new Date(b.created_at).getTime();
        } else if (sortConfig.key === 'total_price') {
          aValue = parseFloat(a.total_price);
          bValue = parseFloat(b.total_price);
        } else if (sortConfig.key === 'customer') {
          aValue = a.customer?.name || a.customer?.email || '';
          bValue = b.customer?.name || b.customer?.email || '';
        } else if (sortConfig.key === 'items_count') {
          aValue = a.line_items.reduce((sum, item) => sum + item.quantity, 0);
          bValue = b.line_items.reduce((sum, item) => sum + item.quantity, 0);
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }
        
        if (aValue < bValue) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    
    setFilteredOrders(result);
  }, [orders, searchTerm, statusFilter, sortConfig]);

  const handleOrderClick = (orderId) => {
    navigate(`/order/${orderId}`);
  };

  const getStatusIcon = (status) => {
    if (!status) return <FaCircle className="status-icon status-neutral" />;
    
    switch (status.toLowerCase()) {
      case 'paid':
      case 'fulfilled':
        return <FaCheckCircle className="status-icon status-success" />;
      case 'pending':
      case 'partially_fulfilled':
        return <FaCircle className="status-icon status-pending" />;
      case 'unfulfilled':
      case 'refunded':
      case 'voided':
        return <FaExclamationCircle className="status-icon status-danger" />;
      default:
        return <FaCircle className="status-icon status-neutral" />;
    }
  };

  const getTrackingStatusIcon = (tracking) => {
    if (!tracking || tracking.length === 0) return null;
    
    return <FaTruck className="status-icon tracking-icon" />;
  };

  const getStatusClass = (status) => {
    if (!status) return 'una-status-neutral';
    
    switch (status.toLowerCase()) {
      case 'paid':
      case 'fulfilled':
        return 'una-status-success';
      case 'pending':
      case 'partially_fulfilled':
        return 'una-status-pending';
      case 'unfulfilled':
      case 'refunded':
      case 'voided':
        return 'una-status-danger';
      default:
        return 'una-status-neutral';
    }
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) return <FaSort className="sort-icon" />;
    
    return sortConfig.direction === 'asc' 
      ? <FaSortUp className="sort-icon active" />
      : <FaSortDown className="sort-icon active" />;
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const getItemsCount = (lineItems) => {
    return lineItems.reduce((sum, item) => sum + item.quantity, 0);
  };

  if (error) return (
    <div className="una-order-error">
      <FaExclamationCircle />
      <h2>{t('orders.error')}</h2>
      <p>{error}</p>
    </div>
  );
  
  if (isLoading) {
    return (
      <div className="una-dashboard-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
          <h2>{t('ordersP.loadingTitle')}</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaShoppingCart />
              <span>{t('ordersP.fetchingOrders')}</span>
            </div>
            <div className="una-metric-item">
              <FaUser />
              <span>{t('ordersP.loadingCustomerData')}</span>
            </div>
            <div className="una-metric-item">
              <FaShoppingBag />
              <span>{t('ordersP.processingItems')}</span>
            </div>
            <div className="una-metric-item">
              <FaCreditCard />
              <span>{t('ordersP.verifyingPayments')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-orders-container">
      <div className="una-orders-header">
        <div className="una-orders-title">
          <FaShoppingCart />
          <h2>{t('ordersP.title')}</h2>
          <span className="una-orders-count">{orders.length} {t('ordersP.totalOrders')}</span>
        </div>
        
        <div className="una-orders-actions">
          <div className="una-search-wrapper">
            <FaSearch className="una-search-icon" />
            <input
              type="text"
              className="una-search-input"
              placeholder={t('ordersP.searchPlaceholder')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {searchTerm && (
              <button 
                className="una-clear-search" 
                onClick={() => setSearchTerm('')}
              >
                ×
              </button>
            )}
          </div>
          
          <div className="una-filter-wrapper">
            <FaFilter className="una-filter-icon" />
            <select 
              className="una-filter-select"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="all">{t('ordersP.allStatuses')}</option>
              <option value="paid">{t('ordersP.paid')}</option>
              <option value="pending">{t('ordersP.pending')}</option>
              <option value="refunded">{t('ordersP.refunded')}</option>
              <option value="fulfilled">{t('ordersP.fulfilled')}</option>
              <option value="unfulfilled">{t('ordersP.unfulfilled')}</option>
            </select>
          </div>
        </div>
      </div>

      {!filteredOrders.length ? (
        <div className="una-orders-empty-state">
          <FaShoppingCart className="empty-icon" />
          <p>
            {searchTerm || statusFilter !== 'all'
              ? t('ordersP.noOrdersFiltered')
              : t('ordersP.noOrders')
            }
          </p>
          {(searchTerm || statusFilter !== 'all') && (
            <button 
              className="una-clear-filters-button"
              onClick={() => {
                setSearchTerm('');
                setStatusFilter('all');
              }}
            >
              {t('ordersP.clearFilters')}
            </button>
          )}
        </div>
      ) : (
        <div className="una-orders-table-container">
          <table className="una-orders-table">
            <thead>
              <tr>
                <th onClick={() => requestSort('order_number')} className="sortable">
                  {t('ordersP.orderNumber')}
                  {getSortIcon('order_number')}
                </th>
                <th onClick={() => requestSort('created_at')} className="sortable">
                  {t('ordersP.date')}
                  {getSortIcon('created_at')}
                </th>
                <th onClick={() => requestSort('customer')} className="sortable">
                  {t('ordersP.customer')}
                  {getSortIcon('customer')}
                </th>
                <th className="status-column">
                  {t('ordersP.status')}
                </th>
                <th onClick={() => requestSort('items_count')} className="sortable items-column">
                  {t('ordersP.items')}
                  {getSortIcon('items_count')}
                </th>
                <th onClick={() => requestSort('total_price')} className="sortable price-column">
                  {t('ordersP.total')}
                  {getSortIcon('total_price')}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order) => (
                <tr 
                  key={order.id} 
                  className="una-order-row" 
                  onClick={() => handleOrderClick(order.id)}
                >
                  <td className="order-number">
                    #{order.order_number}
                  </td>
                  <td>
                    <div className="una-cell-with-icon">
                      <FaCalendar className="cell-icon" />
                      <span>{formatDate(order.created_at)}</span>
                    </div>
                  </td>
                  <td>
                    <div className="una-cell-with-icon">
                      <FaUser className="cell-icon" />
                      <span>{order.customer?.name || order.customer?.email || t('ordersP.guestCustomer')}</span>
                    </div>
                  </td>
                  <td className="status-cell">
                    <div className="una-status-group">
                      <div className={`una-status-pill ${getStatusClass(order.financial_status)}`}>
                        {getStatusIcon(order.financial_status)}
                        <span>{order.financial_status || t('ordersP.unknown')}</span>
                      </div>
                      {order.fulfillment_status && (
                        <div className={`una-status-pill ${getStatusClass(order.fulfillment_status)}`}>
                          {getStatusIcon(order.fulfillment_status)}
                          <span>{order.fulfillment_status}</span>
                        </div>
                      )}
                      {order.tracking && order.tracking.length > 0 && (
                        <div className="una-status-pill una-status-tracking">
                          <FaTruck className="status-icon" />
                          <span>{t('ordersP.tracked')}</span>
                          <div className="una-tracking-tooltip">
                            <div className="una-tooltip-header">{t('ordersP.trackingInfo')}</div>
                            <ul className="una-tracking-items">
                              {order.tracking.map((track, idx) => (
                                <li key={idx}>
                                  <div className="una-tracking-item-header">
                                    <span>{track.tracking_company || t('ordersP.unknownCarrier')}</span>
                                    <span className={`una-tracking-status ${track.status?.toLowerCase()}`}>
                                      {track.status}
                                    </span>
                                  </div>
                                  <div className="una-tracking-number">
                                    {track.tracking_number}
                                    {track.tracking_url && (
                                      <a 
                                        href={track.tracking_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) => e.stopPropagation()}
                                        className="una-tracking-link"
                                      >
                                        {t('ordersP.track')}
                                      </a>
                                    )}
                                  </div>
                                  {track.tracking_details && (
                                    <div className="una-tracking-details">
                                      <div className="una-tracking-status-label">
                                        {track.tracking_details.status_description || track.tracking_details.status || t('ordersP.inTransit')}
                                      </div>
                                      {track.tracking_details.estimated_delivery && (
                                        <div className="una-delivery-estimate">
                                          {t('ordersP.estimatedDelivery')}: {formatDate(track.tracking_details.estimated_delivery)}
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="items-cell">
                    <div className="una-cell-with-icon">
                      <FaBox className="cell-icon" />
                      <span>{getItemsCount(order.line_items)}</span>
                    </div>
                    <div className="una-cell-tooltip">
                      <ul className="una-tooltip-items">
                        {order.line_items.map((item, index) => (
                          <li key={index}>
                            <span>{item.title}</span>
                            <div>
                              <span>x{item.quantity}</span>
                              <span>${item.price}</span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </td>
                  <td className="price-cell">${parseFloat(order.total_price).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      
      <div className="una-orders-footer">
        <div className="una-pagination-info">
          {t('ordersP.showing')} {filteredOrders.length} {t('ordersP.of')} {orders.length} {t('ordersP.orders')}
        </div>
      </div>
    </div>
  );
};

export default OrdersList;