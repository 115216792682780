import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaBoxOpen, FaTruck, FaShippingFast, FaBox, FaBarcode, FaClipboardList, FaSearch, FaFilter, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './ManageReturns.css';

const ManageReturns = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [returns, setReturns] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchReturns = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/webhook/returns', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setReturns(response.data.returns);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReturns();
  }, []);

  const formatDate = (isoString) => {
    return new Date(isoString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleReturnClick = (returnId) => {
    navigate(`/return/${returnId}`);
  };

  // Filter returns by status
  const filteredReturns = statusFilter === 'all' 
    ? returns 
    : returns.filter(returnItem => returnItem.status.toLowerCase() === statusFilter.toLowerCase());

  // Calculate pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentReturns = filteredReturns.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredReturns.length / itemsPerPage);

  // Handle pagination
  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const prevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  if (error) return <div className="una-returns-error">{t('returns.error')}: {error}</div>;

  return (
    <div className="una-returns-container">
      <div className="una-returns-header">
        <div className="una-returns-title">
          <FaBoxOpen />
          <h2>{t('returns.title')}</h2>
        </div>
      </div>

      {isLoading ? (
        <div className="una-dashboard-loading-screen">
          <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
            <h2>{t('returns.loadingTitle')}</h2>
            <div className="una-loading-progress">
              <div className="una-progress-bar">
                <div className="una-progress-glow"></div>
              </div>
            </div>
            <div className="una-loading-metrics">
              <div className="una-metric-item">
                <FaBox />
                <span>{t('returns.fetchingRequests')}</span>
              </div>
              <div className="una-metric-item">
                <FaBarcode />
                <span>{t('returns.processingTracking')}</span>
              </div>
              <div className="una-metric-item">
                <FaShippingFast />
                <span>{t('returns.loadingLabels')}</span>
              </div>
              <div className="una-metric-item">
                <FaClipboardList />
                <span>{t('returns.processingStatus')}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="table-controls">
            <div className="filters-container">
              <div className="status-filter">
                <label htmlFor="status-select">{t('returns.statusFilter')}</label>
                <select 
                  id="status-select" 
                  value={statusFilter} 
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="all">{t('returns.allStatuses')}</option>
                  <option value="pending">{t('returns.statusPending')}</option>
                  <option value="approved">{t('returns.statusApproved')}</option>
                  <option value="rejected">{t('returns.statusRejected')}</option>
                  <option value="completed">{t('returns.statusCompleted')}</option>
                </select>
              </div>
            </div>
            
            {filteredReturns.length > itemsPerPage && (
              <div className="pagination">
                <button 
                  onClick={prevPage} 
                  disabled={currentPage === 1}
                >
                  <FaChevronLeft /> {t('returns.previous')}
                </button>
                <span>{currentPage} / {totalPages}</span>
                <button 
                  onClick={nextPage} 
                  disabled={currentPage === totalPages}
                >
                  {t('returns.next')} <FaChevronRight />
                </button>
              </div>
            )}
          </div>
          
          {!filteredReturns.length ? (
            <div className="una-returns-empty-state">
              <FaBoxOpen className="empty-icon" />
              <p>{t('returns.noReturnsFound')}</p>
            </div>
          ) : (
            <table className="una-returns-table">
              <thead>
                <tr>
                  <th>{t('returns.returnId')}</th>
                  <th>{t('returns.status')}</th>
                  <th>{t('returns.createdDate')}</th>
                  <th>{t('returns.items')}</th>
                  <th>{t('returns.tracking')}</th>
                  <th>{t('returns.actions')}</th>
                </tr>
              </thead>
              <tbody>
                {currentReturns.map((returnItem) => (
                  <tr 
                    key={returnItem._id}
                    onClick={() => handleReturnClick(returnItem._id)}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleReturnClick(returnItem._id);
                      }
                    }}
                  >
                    <td className="primary-info">{returnItem._id.substring(0, 8)}...</td>
                    <td>
                      <span className={`status-badge ${returnItem.status.toLowerCase()}`}>
                        {returnItem.status}
                      </span>
                    </td>
                    <td className="tertiary-info date-info">{formatDate(returnItem.created_at)}</td>
                    <td>
                      <div className="visual-indicator">
                        <FaBox />
                        <span>{returnItem.items.length} {t('returns.itemsCount')}</span>
                      </div>
                    </td>
                    <td className="secondary-info">
                      {returnItem.tracking_number ? 
                        returnItem.tracking_number : 
                        <span className="no-tracking">{t('returns.noTracking')}</span>}
                    </td>
                    <td>
                      {returnItem.label_url && (
                        <a 
                          href={returnItem.label_url} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="una-action-btn"
                          onClick={(e) => e.stopPropagation()}
                        >
                          {t('returns.downloadLabel')}
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </div>
  );
};

export default ManageReturns;