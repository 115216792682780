import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Line, Pie, Bar } from 'react-chartjs-2';
import { 
  FaStore, FaChartLine, FaUsers, FaBox, 
  FaMoneyBillWave, FaShoppingCart, FaGlobe,
  FaExclamationTriangle, FaTrophy, FaRegSadTear,
  FaExclamationCircle, FaSearch, FaImage, FaTag, FaBoxes,
  FaSmile, FaTicketAlt, FaRobot, FaChartPie, FaTags, FaBoxOpen
} from 'react-icons/fa';
import './ShopStatistics.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
} from 'chart.js';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
);

const CHART_COLORS = {
  sentiment: {
    line: '#00ff9d', // neon green
    background: 'rgba(0, 255, 157, 0.1)',
  },
  resolved: {
    line: '#00ffff', // neon cyan
    background: 'rgba(0, 255, 255, 0.1)',
  },
  aiResolved: {
    line: '#ff00ff', // neon magenta
    background: 'rgba(255, 0, 255, 0.1)',
  }
};

const PIE_COLORS = [
  '#00ff9d', // neon green
  '#00ffff', // neon cyan
  '#ff00ff', // neon magenta
  '#8000ff', // neon purple
  '#ffff00', // neon yellow
  '#ff0000', // neon red
  '#0099ff', // neon blue
];

const ShopStatistics = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  // Add shopStatus state
  const [shopStatus, setShopStatus] = useState('configured');
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('overview');
  const [attentionProducts, setAttentionProducts] = useState([]);
  const [isLoadingAttention, setIsLoadingAttention] = useState(true);
  
  // Sentiment statistics state
  const [sentimentStats, setSentimentStats] = useState(null);
  const [isSentimentLoading, setIsSentimentLoading] = useState(true);
  const [sentimentError, setSentimentError] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState('7_days');
  const [tagsStats, setTagsStats] = useState(null);
  
  // Combined loading state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        await Promise.all([
          fetchShopStats(),
          fetchAttentionProducts(),
          fetchSentimentStatistics(),
          fetchTagsStatistics()
        ]);
        setIsLoading(false);
      } catch (err) {
        console.error('Error loading statistics data:', err);
        setIsLoading(false);
      }
    };
    
    fetchAllData();
  }, []);

  // Shop Statistics fetching functions
  const fetchShopStats = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/shopify/get_shop_statistics', {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status === 'unconfigured') {
        setShopStatus('unconfigured');
      } else {
        setStats(response.data);
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const fetchAttentionProducts = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/shopify/get_products_needing_attention', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAttentionProducts(response.data.products);
    } catch (err) {
      console.error('Error fetching attention products:', err);
    } finally {
      setIsLoadingAttention(false);
    }
  };

  // Sentiment Statistics fetching functions
  const fetchSentimentStatistics = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/tickets/tickets/sentiment-statistics', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSentimentStats(response.data);
      setIsSentimentLoading(false);
    } catch (error) {
      console.error('Error fetching sentiment statistics:', error);
      setSentimentError(t('sentimentStatistics.fetchError'));
      setIsSentimentLoading(false);
    }
  };

  const fetchTagsStatistics = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/tickets/tickets-tags-stats', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setTagsStats(response.data);
    } catch (error) {
      console.error('Error fetching tags statistics:', error);
    }
  };

  if (error || sentimentError) return <div className="una-stats-error">{error || sentimentError}</div>;
  
  if (isLoading) return (
    <div className="una-dashboard-loading-screen">
      <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
        <h2>{t('shopStats.loadingTitle')}</h2>
        <div className="una-loading-progress">
          <div className="una-progress-bar">
            <div className="una-progress-glow"></div>
          </div>
        </div>
        <div className="una-loading-metrics">
          <div className="una-metric-item">
            <FaShoppingCart />
            <span>{t('shopStats.analyzingOrders')}</span>
          </div>
          <div className="una-metric-item">
            <FaUsers />
            <span>{t('shopStats.processingCustomers')}</span>
          </div>
          <div className="una-metric-item">
            <FaTicketAlt />
            <span>{t('sentimentStatistics.loadingMetrics.tickets')}</span>
          </div>
          <div className="una-metric-item">
            <FaSmile />
            <span>{t('sentimentStatistics.loadingMetrics.sentiment')}</span>
          </div>
        </div>
      </div>
    </div>
  );

  // Add check for unconfigured status
  if (shopStatus === 'unconfigured') {
    return (
      <div className="una-stats-unconfigured">
        <div className="una-unconfigured-content">
          <FaStore className="una-unconfigured-icon" />
          <h2>{t('shopStats.unconfigured.title', 'Shop Configuration Required')}</h2>
          <p>{t('shopStats.unconfigured.message', 'Please complete your shop configuration to view statistics')}</p>
          <button 
            className="una-configure-shop-button"
            onClick={() => navigate('/settings')}
          >
            {t('shopStats.unconfigured.button', 'Configure Shop')}
          </button>
        </div>
      </div>
    );
  }

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  // Prepare data for sentiment statistics chart
  const sentimentData = sentimentStats?.sentiment_statistics[selectedPeriod];
  let sentimentChartData = null;
  if (sentimentData) {
    const labels = sentimentData.raw.map((item) => item._id);
    const averageSentiments = sentimentData.raw.map(
      (item) => item.average_sentiment
    );

    sentimentChartData = {
      labels: labels,
      datasets: [{
        label: t('sentimentStatistics.averageSentiment'),
        data: averageSentiments,
        borderColor: CHART_COLORS.sentiment.line,
        backgroundColor: CHART_COLORS.sentiment.background,
        borderWidth: 2,
        pointRadius: 4,
        pointHoverRadius: 6,
        tension: 0.4,
        fill: true
      }]
    };
  }

  // Prepare data for resolved tickets statistics chart
  const resolvedData = sentimentStats?.resolved_statistics[selectedPeriod];
  let resolvedChartData = null;
  if (resolvedData) {
    const labels = resolvedData.raw.map((item) => item._id);
    const resolvedCounts = resolvedData.raw.map((item) => item.resolved);
    const aiResolvedCounts = resolvedData.raw.map((item) => item.ai_resolved);

    resolvedChartData = {
      labels: labels,
      datasets: [
        {
          label: t('sentimentStatistics.resolvedTickets'),
          data: resolvedCounts,
          borderColor: CHART_COLORS.resolved.line,
          backgroundColor: CHART_COLORS.resolved.background,
          borderWidth: 2,
          pointRadius: 4,
          pointHoverRadius: 6,
          tension: 0.4,
          fill: true
        },
        {
          label: t('sentimentStatistics.aiResolvedTickets'),
          data: aiResolvedCounts,
          borderColor: CHART_COLORS.aiResolved.line,
          backgroundColor: CHART_COLORS.aiResolved.background,
          borderWidth: 2,
          pointRadius: 4,
          pointHoverRadius: 6,
          tension: 0.4,
          fill: true
        }
      ]
    };
  }

  // Add tags chart data preparation
  let tagsChartData = null;
  if (tagsStats?.tags_statistics) {
    tagsChartData = {
      labels: tagsStats.tags_statistics.map(item => item._id),
      datasets: [{
        data: tagsStats.tags_statistics.map(item => item.percentage),
        backgroundColor: PIE_COLORS,
      }],
    };
  }

  const sentimentChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    plugins: {
      legend: {
        labels: {
          color: '#fff',
          padding: 20,
          font: {
            size: 13,
            family: "'Roboto', sans-serif"
          },
          usePointStyle: true,
          filter: function(item) {
            return !item.text.includes('Background');
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(13, 14, 26, 0.95)',
        titleColor: '#fff',
        bodyColor: '#fff',
        padding: 12,
        boxPadding: 8,
        cornerRadius: 8,
        displayColors: true,
        borderColor: 'rgba(0, 255, 157, 0.1)',
        borderWidth: 1,
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
          maxRotation: 45,
          minRotation: 45
        },
        border: {
          display: false
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.03)',
          drawBorder: false,
          lineWidth: 1
        },
        ticks: {
          color: 'rgba(255, 255, 255, 0.7)',
          padding: 10,
          maxTicksLimit: 5
        },
        border: {
          display: false
        },
        beginAtZero: true
      }
    }
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: '#fff'
        }
      }
    },
    scales: {
      x: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: '#fff'
        }
      },
      y: {
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        ticks: {
          color: '#fff'
        }
      }
    }
  };

  // Add null check for stats before rendering overview section
  const renderOverviewSection = () => {
    if (!stats || !stats.orders) {
      return (
        <div className="una-stats-error">
          {t('shopStats.noData', 'No statistics data available')}
        </div>
      );
    }

    return (
      <div className="una-stats-overview">


        <div className="una-stats-grid">
          <div className="una-stat-card revenue">
            <FaMoneyBillWave />
            <div className="una-stat-content">
              <h3>{t('shopStats.totalRevenue')}</h3>
              <p>${stats.orders.total_revenue.toFixed(2)}</p>
              <span>{t('shopStats.avgOrder')}: ${stats.orders.average_order_value.toFixed(2)}</span>
            </div>
          </div>

          <div className="una-stat-card orders">
            <FaShoppingCart />
            <div className="una-stat-content">
              <h3>{t('shopStats.totalOrders')}</h3>
              <p>{stats.orders.total_orders}</p>
              <span>{t('shopStats.avgItems')}: {stats.sales_analysis.average_items_per_order.toFixed(1)}</span>
            </div>
          </div>

          <div className="una-stat-card customers">
            <FaUsers />
            <div className="una-stat-content">
              <h3>{t('shopStats.customers')}</h3>
              <p>{stats.customers.total_customers}</p>
              <span>{t('shopStats.newCustomers')}: {stats.customers.new_customers_30d}</span>
            </div>
          </div>

          <div className="una-stat-card products">
            <FaBox />
            <div className="una-stat-content">
              <h3>{t('shopStats.products')}</h3>
              <p>{stats.products.total_products}</p>
              <span>{t('shopStats.variants')}: {stats.products.total_variants}</span>
            </div>
          </div>
        </div>

        <div className="una-alerts-section">
          {stats.products.out_of_stock_count > 0 && (
            <div className="una-alert out-of-stock">
              <FaExclamationTriangle />
              <span>{stats.products.out_of_stock_count} {t('shopStats.outOfStock')}</span>
            </div>
          )}
          {stats.products.low_stock_count > 0 && (
            <div className="una-alert low-stock">
              <FaExclamationTriangle />
              <span>{stats.products.low_stock_count} {t('shopStats.lowStock')}</span>
            </div>
          )}
        </div>

        <div className="una-charts-grid">
          <div className="una-chart-card">
            <Bar 
              data={{
                labels: Object.keys(stats.sales_analysis.sales_by_day),
                datasets: [{
                  label: t('shopStats.charts.sales'),
                  data: Object.values(stats.sales_analysis.sales_by_day),
                  backgroundColor: 'rgba(0, 255, 157, 0.2)',
                  borderColor: 'rgba(0, 255, 157, 1)',
                  borderWidth: 1
                }]
              }}
              options={chartOptions}
            />
          </div>

          <div className="una-chart-card">
            <Line 
              data={{
                labels: Object.keys(stats.orders.orders_by_month),
                datasets: [{
                  label: t('shopStats.charts.orders'),
                  data: Object.values(stats.orders.orders_by_month),
                  borderColor: 'rgba(0, 255, 255, 1)',
                  backgroundColor: 'rgba(0, 255, 255, 0.1)',
                  tension: 0.4,
                  fill: true
                }]
              }}
              options={chartOptions}
            />
          </div>
        </div>

      </div>
    );
  };

  const renderAttentionSection = () => (
    <div className="una-attention-section">
      
      {isLoadingAttention ? (
        <div className="una-loading">{t('shopStats.loadingAttention')}</div>
      ) : (
        <div className="una-attention-grid">
          {attentionProducts.map((product) => (
            <div 
              key={product.id} 
              className="una-attention-card una-clickable-item"
              onClick={() => handleProductClick(product.id)}
            >
              <div className="una-attention-header">
                <h3>{product.title}</h3>
                <span className="una-attention-type">{product.product_type}</span>
              </div>
              
              <div className="una-reasons-list">
                {product.health_status.reasons.map((reason, index) => (
                  <span key={index} className={`una-reason-tag ${reason}`}>
                    {formatReason(reason)}
                  </span>
                ))}
              </div>

              <div className="una-metrics-grid">
                {product.health_status.metrics.sales_30_days !== undefined && (
                  <div className="una-metric">
                    <FaShoppingCart />
                    <span>30d Sales: {product.health_status.metrics.sales_30_days}</span>
                  </div>
                )}
                {product.health_status.metrics.image_count !== undefined && (
                  <div className="una-metric">
                    <FaImage />
                    <span>Images: {product.health_status.metrics.image_count}</span>
                  </div>
                )}
                {product.variants && (
                  <div className="una-metric">
                    <FaBoxes />
                    <span>Variants: {product.variants.length}</span>
                  </div>
                )}
              </div>

              <div className="una-recommendations">
                <h4>{t('shopStats.recommendations')}</h4>
                <ul>
                  {product.health_status.recommendations.map((rec, index) => (
                    <li key={index}>{rec}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  // New section for sentiment statistics
  const renderSentimentSection = () => (
    <div className="una-sentiment-section">

      <div className="una-stats-grid una-support-stats">
        <div className="una-stat-card sentiment">
          <FaSmile />
          <div className="una-stat-content">
            <h3>{t('sentimentStatistics.averageSentiment')}</h3>
            <p>{sentimentStats?.overall_sentiment?.toFixed(1) || 0}</p>
            <span>{t('sentimentStatistics.scale', 'Scale: 1-5')}</span>
          </div>
        </div>

        <div className="una-stat-card resolved">
          <FaTicketAlt />
          <div className="una-stat-content">
            <h3>{t('sentimentStatistics.resolvedTickets')}</h3>
            <p>{sentimentStats?.total_resolved || 0}</p>
            <span>{sentimentStats?.resolution_rate ? `${sentimentStats.resolution_rate.toFixed(1)}%` : '0%'} {t('sentimentStatistics.resolutionRate')}</span>
          </div>
        </div>

        <div className="una-stat-card ai-resolved">
          <FaRobot />
          <div className="una-stat-content">
            <h3>{t('sentimentStatistics.aiResolvedTickets')}</h3>
            <p>{sentimentStats?.total_ai_resolved || 0}</p>
            <span>{sentimentStats?.ai_resolution_rate ? `${sentimentStats.ai_resolution_rate.toFixed(1)}%` : '0%'} {t('sentimentStatistics.aiResolutionRate')}</span>
          </div>
        </div>

        <div className="una-stat-card tags">
          <FaTags />
          <div className="una-stat-content">
            <h3>{t('sentimentStatistics.tagsTitle')}</h3>
            <p>{tagsStats?.total_tickets || 0}</p>
            <span>{tagsStats?.tags_statistics?.length || 0} {t('sentimentStatistics.uniqueTags')}</span>
          </div>
        </div>
      </div>

      <div className="una-period-selector">
        {['7_days', '14_days', '30_days'].map((period) => (
          <button
            key={period}
            className={selectedPeriod === period ? 'active' : ''}
            onClick={() => setSelectedPeriod(period)}
          >
            {t(`sentimentStatistics.period.${period}`)}
          </button>
        ))}
      </div>

      <div className="una-sentiment-charts-grid">
        {sentimentChartData && (
          <div className="una-chart-card">
            <Line data={sentimentChartData} options={sentimentChartOptions} />
          </div>
        )}

        {resolvedChartData && (
          <div className="una-chart-card">
            <Line data={resolvedChartData} options={sentimentChartOptions} />
          </div>
        )}

        {tagsChartData && (
          <div className="una-chart-card tag-distribution">
            <Pie
              data={tagsChartData}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: {
                    position: 'right',
                    labels: {
                      color: '#fff',
                      boxWidth: 12,
                      font: {
                        size: 11
                      }
                    },
                  },
                  tooltip: {
                    callbacks: {
                      label: (context) => {
                        const label = context.label || '';
                        const value = context.raw || 0;
                        return `${label}: ${value.toFixed(1)}%`;
                      },
                    },
                  },
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  );

  const formatReason = (reason) => {
    const reasons = {
      out_of_stock: t('shopStats.reasons.outOfStock'),
      low_stock: t('shopStats.reasons.lowStock'),
      overstock: t('shopStats.reasons.overstock'),
      low_sales_velocity: t('shopStats.reasons.lowSales'),
      high_discount: t('shopStats.reasons.highDiscount'),
      missing_handle: t('shopStats.reasons.seoIssue'),
      thin_content: t('shopStats.reasons.thinContent'),
      no_images: t('shopStats.reasons.noImages'),
      few_images: t('shopStats.reasons.fewImages'),
      no_tags: t('shopStats.reasons.noTags'),
      single_variant: t('shopStats.reasons.singleVariant')
    };
    return reasons[reason] || reason;
  };

  return (
    <div className="una-shop-statistics">
      <div className="una-tabs">
        <button 
          className={activeTab === 'overview' ? 'active' : ''} 
          onClick={() => setActiveTab('overview')}
        >
          <FaStore /> {t('shopStats.tabs.overview')}
        </button>
        <button 
          className={activeTab === 'products' ? 'active' : ''} 
          onClick={() => setActiveTab('products')}
        >
          <FaBoxOpen /> {t('shopStats.tabs.productHealth')}
        </button>
        <button 
          className={activeTab === 'support' ? 'active' : ''} 
          onClick={() => setActiveTab('support')}
        >
          <FaSmile /> {t('shopStats.tabs.supportAnalytics')}
        </button>
      </div>
      
      {activeTab === 'overview' && renderOverviewSection()}
      {activeTab === 'products' && renderAttentionSection()}
      {activeTab === 'support' && renderSentimentSection()}
    </div>
  );
};

export default ShopStatistics;