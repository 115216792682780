import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { 
  FaStore, FaChartLine, FaTags, FaPercent, FaCalendarAlt, 
  FaTrash, FaFilter, FaSearch, FaSortAmountDown, 
  FaUpload, FaStar, FaStarHalfAlt, FaEye, FaMousePointer,
  FaShoppingCart, FaMoneyBillWave, FaHandshake, FaExclamationCircle
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './MyMarketplace.css';

const MyMarketplace = () => {
  const { t } = useTranslation();
  const [myProducts, setMyProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortOption, setSortOption] = useState('published_at-desc');
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMyMarketplaceProducts = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        
        const response = await axios.get(
          '/api/shopify/my/marketplace/products',
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        // Extract unique categories from the products
        const allCategories = response.data.products
          .flatMap(product => product.categories || [])
          .filter((category, index, self) => self.indexOf(category) === index);
        
        setMyProducts(response.data.products);
        setCategories(allCategories);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMyMarketplaceProducts();
  }, []);

  const handleRemoveFromMarketplace = async (productId) => {
    try {
      if (!window.confirm(t('myMarketplace.confirmRemove'))) {
        return;
      }
      
      const token = sessionStorage.getItem('access_token');
      
      await axios.delete(
        '/api/shopify/remove_from_marketplace',
        {
          headers: { Authorization: `Bearer ${token}` },
          data: {
            product_ids: [productId]
          }
        }
      );
      
      // Update the products list
      setMyProducts(myProducts.filter(product => 
        product.product_id !== productId
      ));
      
      alert(t('myMarketplace.removeSuccess'));
    } catch (err) {
      setError(err.message);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return t('myMarketplace.noExpiration');
    return new Date(dateString).toLocaleDateString();
  };

  const formatNumber = (num) => {
    return num.toLocaleString();
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount);
  };

  // Filter products based on user input
  const filterProducts = () => {
    let filtered = myProducts;
    
    // Apply search filter
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(product => 
        product.title.toLowerCase().includes(term) ||
        (product.description && product.description.toLowerCase().includes(term))
      );
    }
    
    // Apply category filter
    if (filterCategory !== 'all') {
      filtered = filtered.filter(product => 
        product.categories && product.categories.includes(filterCategory)
      );
    }
    
    // Apply status filter
    if (filterStatus !== 'all') {
      filtered = filtered.filter(product => product.status === filterStatus);
    }
    
    // Apply sort
    const [field, direction] = sortOption.split('-');
    filtered = [...filtered].sort((a, b) => {
      let comparison = 0;
      
      switch (field) {
        case 'title':
          comparison = a.title.localeCompare(b.title);
          break;
        case 'price':
          comparison = a.price_range.min - b.price_range.min;
          break;
        case 'commission':
          comparison = a.commission_rate - b.commission_rate;
          break;
        case 'published_at':
          comparison = new Date(a.published_at) - new Date(b.published_at);
          break;
        case 'views':
          comparison = a.marketplace_stats.views - b.marketplace_stats.views;
          break;
        case 'sales':
          comparison = a.marketplace_stats.sales - b.marketplace_stats.sales;
          break;
        case 'revenue':
          comparison = a.marketplace_stats.revenue - b.marketplace_stats.revenue;
          break;
        default:
          comparison = 0;
      }
      
      return direction === 'asc' ? comparison : -comparison;
    });
    
    return filtered;
  };

  const filteredProducts = filterProducts();

  if (error) return (
    <div className="una-marketplace-error">
      <FaExclamationCircle />
      <h2>{t('common.error')}</h2>
      <p>{error}</p>
    </div>
  );
  
  if (isLoading) {
    return (
      <div className="una-marketplace-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
          <h2>{t('myMarketplace.loadingTitle')}</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaStore />
              <span>{t('myMarketplace.fetchingProducts')}</span>
            </div>
            <div className="una-metric-item">
              <FaChartLine />
              <span>{t('myMarketplace.loadingStats')}</span>
            </div>
            <div className="una-metric-item">
              <FaHandshake />
              <span>{t('myMarketplace.processingData')}</span>
            </div>
            <div className="una-metric-item">
              <FaMoneyBillWave />
              <span>{t('myMarketplace.calculatingRevenue')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-my-marketplace-container">
      <div className="una-marketplace-content">
        <div className="una-marketplace-filters">
          <div className="una-search-group">
            <FaSearch className="una-search-icon" />
            <input
              type="text"
              className="una-search-input"
              placeholder={t('myMarketplace.searchPlaceholder')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="una-filter-group">
            <label className="una-filter-label">
              <FaFilter /> {t('myMarketplace.categoryFilter')}
            </label>
            <select 
              className="una-filter-select"
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
            >
              <option value="all">{t('myMarketplace.allCategories')}</option>
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          
          <div className="una-filter-group">
            <label className="una-filter-label">
              <FaFilter /> {t('myMarketplace.statusFilter')}
            </label>
            <select 
              className="una-filter-select"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="all">{t('myMarketplace.allStatuses')}</option>
              <option value="active">{t('myMarketplace.statusActive')}</option>
              <option value="inactive">{t('myMarketplace.statusInactive')}</option>
            </select>
          </div>
          
          <div className="una-filter-group">
            <label className="una-filter-label">
              <FaSortAmountDown /> {t('myMarketplace.sort')}
            </label>
            <select 
              className="una-filter-select"
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value="published_at-desc">{t('myMarketplace.sortNewest')}</option>
              <option value="published_at-asc">{t('myMarketplace.sortOldest')}</option>
              <option value="title-asc">{t('myMarketplace.sortTitleAZ')}</option>
              <option value="title-desc">{t('myMarketplace.sortTitleZA')}</option>
              <option value="price-asc">{t('myMarketplace.sortPriceLow')}</option>
              <option value="price-desc">{t('myMarketplace.sortPriceHigh')}</option>
              <option value="commission-desc">{t('myMarketplace.sortCommissionHigh')}</option>
              <option value="views-desc">{t('myMarketplace.sortMostViews')}</option>
              <option value="sales-desc">{t('myMarketplace.sortMostSales')}</option>
              <option value="revenue-desc">{t('myMarketplace.sortMostRevenue')}</option>
            </select>
          </div>
        </div>

        <div className="una-marketplace-stats-summary">
          <div className="una-stat-card">
            <div className="una-stat-icon"><FaEye /></div>
            <div className="una-stat-content">
              <div className="una-stat-value">
                {formatNumber(myProducts.reduce((sum, product) => sum + product.marketplace_stats.views, 0))}
              </div>
              <div className="una-stat-label">{t('myMarketplace.totalViews')}</div>
            </div>
          </div>
          
          <div className="una-stat-card">
            <div className="una-stat-icon"><FaHandshake /></div>
            <div className="una-stat-content">
              <div className="una-stat-value">
                {formatNumber(myProducts.reduce((sum, product) => sum + product.marketplace_stats.tiktoker_selections, 0))}
              </div>
              <div className="una-stat-label">{t('myMarketplace.tiktokerSelections')}</div>
            </div>
          </div>
          
          <div className="una-stat-card">
            <div className="una-stat-icon"><FaShoppingCart /></div>
            <div className="una-stat-content">
              <div className="una-stat-value">
                {formatNumber(myProducts.reduce((sum, product) => sum + product.marketplace_stats.sales, 0))}
              </div>
              <div className="una-stat-label">{t('myMarketplace.totalSales')}</div>
            </div>
          </div>
          
          <div className="una-stat-card">
            <div className="una-stat-icon"><FaMoneyBillWave /></div>
            <div className="una-stat-content">
              <div className="una-stat-value">
                {formatCurrency(myProducts.reduce((sum, product) => sum + product.marketplace_stats.revenue, 0))}
              </div>
              <div className="una-stat-label">{t('myMarketplace.totalRevenue')}</div>
            </div>
          </div>
        </div>

        {filteredProducts.length === 0 ? (
          <div className="una-empty-state">
            <FaStore />
            <p>{t('myMarketplace.noProducts')}</p>
            <Link to="/marketplace-listing" className="una-create-button">
              {t('myMarketplace.addProductsToMarketplace')}
            </Link>
          </div>
        ) : (
          <div className="una-marketplace-grid">
            {filteredProducts.map((product) => (
              <div key={product._id} className="una-marketplace-card">
                <div className="una-product-image-container">
                  {product.images && product.images.length > 0 ? (
                    <img 
                      src={product.images[0].url} 
                      alt={product.title} 
                      className="una-product-image" 
                    />
                  ) : (
                    <div className="una-product-no-image">
                      <FaStore />
                    </div>
                  )}
                  
                  {product.is_featured && (
                    <div className="una-featured-badge">
                      <FaStar /> {t('myMarketplace.featured')}
                    </div>
                  )}
                  
                  <div className={`una-status-badge ${product.status}`}>
                    {product.status === 'active' 
                      ? t('myMarketplace.statusActive') 
                      : t('myMarketplace.statusInactive')}
                  </div>
                </div>
                
                <div className="una-product-details">
                  <h4>{product.title}</h4>
                  
                  <div className="una-product-meta">
                    <p className="una-product-price">
                      <FaMoneyBillWave /> 
                      {product.price_range.min} {product.price_range.currency}
                      {product.price_range.min !== product.price_range.max && 
                        ` - ${product.price_range.max} ${product.price_range.currency}`}
                    </p>
                    
                    <p className="una-product-commission">
                      <FaPercent /> {t('myMarketplace.commission')}: {product.commission_rate}%
                    </p>
                    
                    {product.available_until && (
                      <p className="una-product-expiry">
                        <FaCalendarAlt /> {t('myMarketplace.availableUntil')}: {formatDate(product.available_until)}
                      </p>
                    )}
                  </div>
                  
                  <div className="una-product-categories">
                    {product.categories && product.categories.map(category => (
                      <span key={category} className="una-category-tag">
                        <FaTags /> {category}
                      </span>
                    ))}
                  </div>
                  
                  <div className="una-product-stats">
                    <h5><FaChartLine /> {t('myMarketplace.performanceMetrics')}</h5>
                    <div className="una-stats-grid">
                      <div className="una-stat-item">
                        <FaEye />
                        <span>{t('myMarketplace.views')}</span>
                        <strong>{formatNumber(product.marketplace_stats.views)}</strong>
                      </div>
                      <div className="una-stat-item">
                        <FaMousePointer />
                        <span>{t('myMarketplace.clicks')}</span>
                        <strong>{formatNumber(product.marketplace_stats.clicks)}</strong>
                      </div>
                      <div className="una-stat-item">
                        <FaHandshake />
                        <span>{t('myMarketplace.selected')}</span>
                        <strong>{formatNumber(product.marketplace_stats.tiktoker_selections)}</strong>
                      </div>
                      <div className="una-stat-item">
                        <FaShoppingCart />
                        <span>{t('myMarketplace.sales')}</span>
                        <strong>{formatNumber(product.marketplace_stats.sales)}</strong>
                      </div>
                      <div className="una-stat-item wider">
                        <FaMoneyBillWave />
                        <span>{t('myMarketplace.revenue')}</span>
                        <strong>{formatCurrency(product.marketplace_stats.revenue)}</strong>
                      </div>
                      <div className="una-stat-item wider">
                        <FaPercent />
                        <span>{t('myMarketplace.commissionPaid')}</span>
                        <strong>{formatCurrency(product.marketplace_stats.commission_paid)}</strong>
                      </div>
                    </div>
                  </div>
                  
                  <button
                    onClick={() => handleRemoveFromMarketplace(product.product_id)}
                    className="una-remove-button"
                  >
                    <FaTrash /> {t('myMarketplace.removeFromMarketplace')}
                  </button>
                </div>
              </div>
            ))}
            <Link to="/marketplace-listing" className="una-marketplace-add-card">
              <FaUpload />
              <span>{t('myMarketplace.goToMarketplace')}</span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyMarketplace;