import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaUser, FaLock, FaShopify, FaExclamationCircle, FaCheckCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './ShopifyRegister.css';

const ShopifyRegister = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [token, setToken] = useState('');
  const [passwordError, setPasswordError] = useState('');
  
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    // Extract token from URL query parameters
    const searchParams = new URLSearchParams(location.search);
    const urlToken = searchParams.get('token');
    
    if (!urlToken) {
      setError(t('shopifyRegister.noTokenError'));
      return;
    }
    
    setToken(urlToken);
  }, [location, t]);

  const validateForm = () => {
    if (!username || !password || !confirmPassword) {
      setError(t('shopifyRegister.allFieldsRequired'));
      return false;
    }

    if (password !== confirmPassword) {
      setPasswordError(t('shopifyRegister.passwordMismatch'));
      return false;
    }

    if (password.length < 8) {
      setPasswordError(t('shopifyRegister.passwordTooShort'));
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Clear previous errors
    setError(null);
    setPasswordError('');
    
    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    
    try {
      // Create form data
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);
      formData.append('token', token);
      
      // Submit registration
      const response = await axios.post('/api/shopify/shopify/complete-registration', formData);
      
      if (response.data && response.data.token) {
        // Store the JWT token
        sessionStorage.setItem('access_token', response.data.token);
        
        // Redirect to dashboard
        navigate('/dashboard');
      } else {
        setError(t('shopifyRegister.unexpectedResponse'));
      }
    } catch (err) {
      console.error('Registration error:', err);
      setError(err.response?.data?.detail || t('shopifyRegister.registrationFailed'));
    } finally {
      setLoading(false);
    }
  };

  if (error && !token) {
    return (
      <div className="una-shopify-register-error">
        <FaExclamationCircle className="error-icon" />
        <h2>{t('shopifyRegister.errorTitle')}</h2>
        <p>{error}</p>
        <button 
          className="una-return-button"
          onClick={() => window.location.href = 'https://apps.shopify.com/unanimity-ai'}
        >
          {t('shopifyRegister.returnToShopify')}
        </button>
      </div>
    );
  }

  return (
    <div className="una-shopify-register-container">
      <div className="una-shopify-register-card">
        <div className="una-register-header">
          <img src="/bgless_logo.png" alt="Unanimity AI Logo" className="una-logo" />
          <h2>{t('shopifyRegister.completeRegistration')}</h2>
          <div className="una-shopify-badge">
            <FaShopify />
            <span>{t('shopifyRegister.shopifyConnection')}</span>
          </div>
        </div>
        
        {error && (
          <div className="una-register-error-message">
            <FaExclamationCircle />
            <span>{error}</span>
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="una-register-form">
          <div className="una-form-group">
            <label htmlFor="username">
              <FaUser className="una-input-icon" />
              {t('shopifyRegister.username')}
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder={t('shopifyRegister.usernamePlaceholder')}
              disabled={loading}
            />
          </div>
          
          <div className="una-form-group">
            <label htmlFor="password">
              <FaLock className="una-input-icon" />
              {t('shopifyRegister.password')}
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('shopifyRegister.passwordPlaceholder')}
              disabled={loading}
              className={passwordError ? 'una-input-error' : ''}
            />
          </div>
          
          <div className="una-form-group">
            <label htmlFor="confirmPassword">
              <FaLock className="una-input-icon" />
              {t('shopifyRegister.confirmPassword')}
            </label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t('shopifyRegister.confirmPasswordPlaceholder')}
              disabled={loading}
              className={passwordError ? 'una-input-error' : ''}
            />
            {passwordError && (
              <div className="una-password-error">
                <FaExclamationCircle />
                <span>{passwordError}</span>
              </div>
            )}
          </div>
          
          <div className="una-password-requirements">
            <p>{t('shopifyRegister.passwordRequirements')}</p>
            <ul>
              <li className={password.length >= 8 ? 'una-requirement-met' : ''}>
                {password.length >= 8 ? <FaCheckCircle /> : <FaExclamationCircle />}
                {t('shopifyRegister.minCharacters')}
              </li>
            </ul>
          </div>
          
          <button 
            type="submit" 
            className="una-register-button"
            disabled={loading}
          >
            {loading ? (
              <div className="una-button-loading">
                <span className="una-loading-spinner"></span>
                {t('shopifyRegister.registering')}
              </div>
            ) : (
              t('shopifyRegister.completeRegistration')
            )}
          </button>
        </form>
        
        <div className="una-register-footer">
          <p>{t('shopifyRegister.termsNotice')}</p>
          <div className="una-help-links">
            <a href="mailto:support@unanimity.ai">{t('shopifyRegister.needHelp')}</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopifyRegister;