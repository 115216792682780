import React from 'react';
import PropTypes from 'prop-types';
import './Button.css';

const Button = ({
  children,
  variant,
  onClick,
  type,
  disabled,
  className,
  ...props
}) => {
  const baseClass = 'custom-button';
  const variantClass = variant ? `${baseClass}--${variant}` : '';
  const disabledClass = disabled ? `${baseClass}--disabled` : '';
  const classes = [baseClass, variantClass, disabledClass, className]
    .filter(Boolean)
    .join(' ');

  return (
    <button
      className={classes}
      onClick={onClick}
      type={type}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  variant: 'primary',
  onClick: () => {},
  type: 'button',
  disabled: false,
  className: '',
};

export default Button;
