import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  FaArrowLeft, FaBox, FaTruck, FaUser, FaMapMarkerAlt, 
  FaFileInvoice, FaCreditCard, FaShippingFast, FaCalendarAlt, 
  FaTag, FaExclamationCircle, FaCheckCircle, FaTimesCircle, 
  FaReceipt, FaExchangeAlt, FaInfoCircle, FaEnvelope, 
  FaPrint, FaDownload, FaClock, FaBars, FaPhone
} from 'react-icons/fa';
import './OrderDetails.css';
import { useTranslation } from 'react-i18next';

const OrderDetails = () => {
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedItems, setSelectedItems] = useState({});
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refundNote, setRefundNote] = useState('');
  const [notifyCustomer, setNotifyCustomer] = useState(true);
  const [refundError, setRefundError] = useState(null);
  const [activeTab, setActiveTab] = useState('items');
  const { orderId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const fetchOrderDetails = async () => {
    try {
      setIsLoading(true);
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get(`/api/shopify/get_order/${orderId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrder(response.data);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, [orderId]);

  const handleRefund = async () => {
    try {
      setRefundError(null);
      const token = sessionStorage.getItem('access_token');
      const lineItems = Object.entries(selectedItems)
        .filter(([_, quantity]) => quantity > 0)
        .map(([id, quantity]) => ({
          line_item_id: id,
          quantity: quantity,
          restock_type: "NO_RESTOCK"
        }));

      if (lineItems.length === 0) {
        setRefundError('Please select items to refund');
        return;
      }

      const refundData = {
        order_id: orderId,
        line_items: lineItems,
        note: refundNote,
        notify_customer: notifyCustomer
      };

      await axios.post('/api/shopify/create_refund', refundData, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setShowRefundModal(false);
      setSelectedItems({});
      fetchOrderDetails();
    } catch (err) {
      setRefundError(err.response?.data?.detail || err.message);
    }
  };

  const getStatusIcon = (status) => {
    if (!status) return <FaInfoCircle className="status-icon status-neutral" />;
    
    switch (status.toLowerCase()) {
      case 'paid':
      case 'fulfilled':
        return <FaCheckCircle className="status-icon status-success" />;
      case 'pending':
      case 'partially_fulfilled':
        return <FaClock className="status-icon status-pending" />;
      case 'unfulfilled':
      case 'refunded':
      case 'partially_refunded':
      case 'voided':
        return <FaTimesCircle className="status-icon status-danger" />;
      default:
        return <FaInfoCircle className="status-icon status-neutral" />;
    }
  };

  const getStatusClass = (status) => {
    if (!status) return 'una-status-neutral';
    
    switch (status.toLowerCase()) {
      case 'paid':
      case 'fulfilled':
        return 'una-status-success';
      case 'pending':
      case 'partially_fulfilled':
        return 'una-status-pending';
      case 'unfulfilled':
      case 'refunded':
      case 'partially_refunded':
      case 'voided':
        return 'una-status-danger';
      default:
        return 'una-status-neutral';
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const options = { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const renderItemsList = () => (
    <div className="una-items-list">
      {order.line_items.map((item) => (
        <div key={item.id} className="una-item">
          <div className="una-item-checkbox-order">
            <input
              type="checkbox"
              checked={selectedItems[item.id] > 0}
              onChange={(e) => {
                setSelectedItems({
                  ...selectedItems,
                  [item.id]: e.target.checked ? item.quantity : 0
                });
              }}
            />
          </div>
          <div className="una-item-content">
            <div className="una-item-details">
              <h4>{item.title}</h4>
              <div className="una-item-meta">
                <span>SKU: {item.sku || 'N/A'}</span>
                {item.variant_title && <span>Variant: {item.variant_title}</span>}
              </div>
              {selectedItems[item.id] > 0 && (
                <div className="una-quantity-control">
                  <label>Refund quantity:</label>
                  <input
                    type="number"
                    min="1"
                    max={item.quantity}
                    value={selectedItems[item.id]}
                    onChange={(e) => {
                      setSelectedItems({
                        ...selectedItems,
                        [item.id]: parseInt(e.target.value) || 0
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div className="una-item-info">
              <div className="una-item-quantity">
                <span>Qty: {item.quantity}</span>
              </div>
              <div className="una-item-price">
                ${parseFloat(item.price).toFixed(2)}
              </div>
              {item.total_discount > 0 && (
                <div className="una-discount">
                  -{parseFloat(item.total_discount).toFixed(2)}
                </div>
              )}
              <div className="una-item-total">
                ${((item.price * item.quantity) - item.total_discount).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const renderRefundModal = () => (
    showRefundModal && (
      <div className="una-modal-overlay">
        <div className="una-modal">
          <div className="una-modal-header">
            <h2>Create Refund</h2>
            <button 
              className="una-modal-close" 
              onClick={() => setShowRefundModal(false)}
            >
              ×
            </button>
          </div>
          {refundError && (
            <div className="una-error">
              <FaExclamationCircle /> {refundError}
            </div>
          )}
          <div className="una-modal-content">
            <div className="una-modal-summary">
              <h3>Refund Summary</h3>
              <div className="una-refund-items">
                {Object.entries(selectedItems)
                  .filter(([_, quantity]) => quantity > 0)
                  .map(([id, quantity]) => {
                    const item = order.line_items.find(item => item.id === id);
                    return item ? (
                      <div key={id} className="una-refund-item">
                        <div>{item.title}</div>
                        <div>
                          {quantity} × ${parseFloat(item.price).toFixed(2)} = ${(quantity * item.price).toFixed(2)}
                        </div>
                      </div>
                    ) : null;
                  })
                }
              </div>
              <div className="una-refund-total">
                <span>Total Refund:</span>
                <span>
                  ${Object.entries(selectedItems)
                    .filter(([_, quantity]) => quantity > 0)
                    .reduce((total, [id, quantity]) => {
                      const item = order.line_items.find(item => item.id === id);
                      return total + (item ? quantity * item.price : 0);
                    }, 0).toFixed(2)}
                </span>
              </div>
            </div>
            <div className="una-form-group">
              <label htmlFor="refund-note">Refund note (optional):</label>
              <textarea
                id="refund-note"
                placeholder="Enter reason for refund or additional details"
                value={refundNote}
                onChange={(e) => setRefundNote(e.target.value)}
              />
            </div>
            <div className="una-form-group una-checkbox-group">
              <label>
                <input
                  type="checkbox"
                  checked={notifyCustomer}
                  onChange={(e) => setNotifyCustomer(e.target.checked)}
                />
                <div className="una-checkbox-label">
                  <FaEnvelope />
                  <span>Notify customer about this refund</span>
                </div>
              </label>
            </div>
          </div>
          <div className="una-modal-actions">
            <button className="una-btn-cancel" onClick={() => setShowRefundModal(false)}>
              Cancel
            </button>
            <button className="una-btn-confirm" onClick={handleRefund}>
              Process Refund
            </button>
          </div>
        </div>
      </div>
    )
  );

  if (error) return (
    <div className="una-order-details-container">
      <button className="una-back-button" onClick={() => navigate('/orders')}>
        <FaArrowLeft /> Back to Orders
      </button>
      <div className="una-error-container">
        <FaExclamationCircle />
        <h2>Failed to load order details</h2>
        <p>{error}</p>
        <button className="una-retry-button" onClick={fetchOrderDetails}>
          Try Again
        </button>
      </div>
    </div>
  );
  
  if (isLoading) {
    return (
      <div className="una-order-details-container">
        <button className="una-back-button" onClick={() => navigate('/orders')}>
          <FaArrowLeft /> Back to Orders
        </button>
        <div className="una-dashboard-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
            <h2>Loading Order Details</h2>
            <div className="una-loading-progress">
              <div className="una-progress-bar">
                <div className="una-progress-glow"></div>
              </div>
            </div>
            <div className="una-loading-metrics">
              <div className="una-metric-item">
                <FaFileInvoice />
                <span>Loading Order Information</span>
              </div>
              <div className="una-metric-item">
                <FaBox />
                <span>Processing Order Items</span>
              </div>
              <div className="una-metric-item">
                <FaTruck />
                <span>Fetching Shipping Details</span>
              </div>
              <div className="una-metric-item">
                <FaCreditCard />
                <span>Verifying Payment Status</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-order-details-container">
      <div className="una-order-details-header">
        <button className="una-back-button" onClick={() => navigate('/orders')}>
          <FaArrowLeft /> Back to Orders
        </button>
        
        <div className="una-order-actions">
          <button className="una-action-button">
            <FaPrint /> Print
          </button>
          <button className="una-action-button">
            <FaDownload /> Export
          </button>
        </div>
      </div>

      <div className="una-order-overview">
        <div className="una-order-title">
          <h1>Order #{order.order_number}</h1>
          <div className="una-order-date">
            <FaCalendarAlt />
            <span>{formatDate(order.created_at)}</span>
          </div>
        </div>
        
        <div className="una-order-status-section">
          <div className="una-status-group">
            <div className="una-status-label">Payment</div>
            <div className={`una-status-pill ${getStatusClass(order.financial_status)}`}>
              {getStatusIcon(order.financial_status)}
              <span>{order.financial_status}</span>
            </div>
          </div>
          
          <div className="una-status-group">
            <div className="una-status-label">Fulfillment</div>
            <div className={`una-status-pill ${getStatusClass(order.fulfillment_status || 'unfulfilled')}`}>
              {getStatusIcon(order.fulfillment_status)}
              <span>{order.fulfillment_status || 'Unfulfilled'}</span>
            </div>
          </div>
          
          {order.cancelled_at && (
            <div className="una-status-group">
              <div className="una-status-label">Order Status</div>
              <div className="una-status-pill una-status-danger">
                <FaTimesCircle className="status-icon status-danger" />
                <span>Cancelled</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="una-order-tabs">
        <button 
          className={`una-tab ${activeTab === 'items' ? 'active' : ''}`}
          onClick={() => setActiveTab('items')}
        >
          <FaBox /> Items & Payment
        </button>
        <button 
          className={`una-tab ${activeTab === 'customer' ? 'active' : ''}`}
          onClick={() => setActiveTab('customer')}
        >
          <FaUser /> Customer Info
        </button>
        <button 
          className={`una-tab ${activeTab === 'shipping' ? 'active' : ''}`}
          onClick={() => setActiveTab('shipping')}
        >
          <FaTruck /> Shipping Details
        </button>
        {order.note && (
          <button 
            className={`una-tab ${activeTab === 'notes' ? 'active' : ''}`}
            onClick={() => setActiveTab('notes')}
          >
            <FaFileInvoice /> Notes
          </button>
        )}
      </div>

      <div className="una-order-tab-content">
        {activeTab === 'items' && (
          <div className="una-tab-panel">
            <div className="una-panel-header">
              <h2><FaBox /> Order Items</h2>
              {!order.cancelled_at && (
                <button 
                  className="una-refund-button"
                  onClick={() => setShowRefundModal(true)}
                  disabled={Object.values(selectedItems).every(v => v === 0)}
                >
                  <FaExchangeAlt /> Create Refund
                </button>
              )}
            </div>

            <div className="una-items-card">
              <div className="una-items-table-header">
                <div className="una-th-checkbox"></div>
                <div className="una-th-product">Product</div>
                <div className="una-th-quantity">Quantity</div>
                <div className="una-th-price">Price</div>
                <div className="una-th-discount">Discount</div>
                <div className="una-th-total">Total</div>
              </div>
              {renderItemsList()}
            </div>

            <div className="una-order-summary-card">
              <h3><FaReceipt /> Order Summary</h3>
              <div className="una-summary-grid">
                <div className="una-summary-section">
                  <div className="una-summary-group">
                    <div className="una-summary-row">
                      <span>Subtotal</span>
                      <span>${parseFloat(order.subtotal_price).toFixed(2)}</span>
                    </div>
                    
                    {order.total_discounts > 0 && (
                      <div className="una-summary-row discount">
                        <span>Discounts</span>
                        <span>-${parseFloat(order.total_discounts).toFixed(2)}</span>
                      </div>
                    )}
                    
                    {order.total_shipping > 0 && (
                      <div className="una-summary-row">
                        <span>Shipping</span>
                        <span>${parseFloat(order.total_shipping).toFixed(2)}</span>
                      </div>
                    )}
                    
                    {order.total_tax > 0 && (
                      <div className="una-summary-row">
                        <span>Tax</span>
                        <span>${parseFloat(order.total_tax).toFixed(2)}</span>
                      </div>
                    )}
                  </div>
                </div>
                
                <div className="una-total-section">
                  <div className="una-total-row">
                    <span>Total</span>
                    <span>${parseFloat(order.total_price).toFixed(2)}</span>
                  </div>
                  
                  {order.financial_status === 'paid' && (
                    <div className="una-total-row paid">
                      <span>Paid by customer</span>
                      <span>${parseFloat(order.total_price).toFixed(2)}</span>
                    </div>
                  )}
                  
                  {order.refunds && order.refunds.length > 0 && (
                    <div className="una-total-row refunded">
                      <span>Refunded</span>
                      <span>-${order.refunds.reduce((sum, refund) => 
                        sum + parseFloat(refund.total_price || 0), 0).toFixed(2)}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            
            {order.payment_details && (
              <div className="una-payment-card">
                <h3><FaCreditCard /> Payment Details</h3>
                <div className="una-payment-info">
                  <div className="una-payment-method">
                    <strong>Method:</strong> {order.payment_details.credit_card_company || 'Credit Card'}
                  </div>
                  {order.payment_details.credit_card_number && (
                    <div className="una-payment-card-number">
                      <strong>Card:</strong> •••• {order.payment_details.credit_card_number}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {activeTab === 'customer' && (
          <div className="una-tab-panel">
            <div className="una-panel-header">
              <h2><FaUser /> Customer Information</h2>
            </div>
            
            <div className="una-customer-card">
              <div className="una-customer-details">
                <div className="una-customer-avatar">
                  {(order.customer.name || '').charAt(0) || 
                   (order.customer.email || '').charAt(0) || 'G'}
                </div>
                <div className="una-customer-info">
                  <h3>{order.customer.name || 'Guest Customer'}</h3>
                  <div className="una-customer-contact">
                    {order.customer.email && (
                      <div className="una-customer-email">
                        <FaEnvelope />
                        <span>{order.customer.email}</span>
                      </div>
                    )}
                    {order.customer.phone && (
                      <div className="una-customer-phone">
                        <FaPhone />
                        <span>{order.customer.phone}</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              {order.customer.orders_count > 0 && (
                <div className="una-customer-stats">
                  <div className="una-stat">
                    <span className="una-stat-value">{order.customer.orders_count}</span>
                    <span className="una-stat-label">Orders</span>
                  </div>
                  {order.customer.total_spent && (
                    <div className="una-stat">
                      <span className="una-stat-value">${parseFloat(order.customer.total_spent).toFixed(2)}</span>
                      <span className="una-stat-label">Total Spent</span>
                    </div>
                  )}
                </div>
              )}
              
              {order.billing_address && (
                <div className="una-address-section">
                  <h3><FaReceipt /> Billing Address</h3>
                  <div className="una-address">
                    <p>{order.billing_address.name}</p>
                    <p>{order.billing_address.address1}</p>
                    {order.billing_address.address2 && <p>{order.billing_address.address2}</p>}
                    <p>{order.billing_address.city}, {order.billing_address.province_code} {order.billing_address.zip}</p>
                    <p>{order.billing_address.country}</p>
                    {order.billing_address.phone && <p>Phone: {order.billing_address.phone}</p>}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {activeTab === 'shipping' && (
          <div className="una-tab-panel">
            <div className="una-panel-header">
              <h2><FaTruck /> Shipping Details</h2>
            </div>
            
            {order.shipping_address && (
              <div className="una-shipping-card">
                <h3><FaMapMarkerAlt /> Shipping Address</h3>
                <div className="una-address">
                  <p>{order.shipping_address.name}</p>
                  <p>{order.shipping_address.address1}</p>
                  {order.shipping_address.address2 && <p>{order.shipping_address.address2}</p>}
                  <p>{order.shipping_address.city}, {order.shipping_address.province_code} {order.shipping_address.zip}</p>
                  <p>{order.shipping_address.country}</p>
                  {order.shipping_address.phone && <p>Phone: {order.shipping_address.phone}</p>}
                </div>
              </div>
            )}

            {order.shipping_label_url && (
              <div className="una-shipping-card">
                <h3><FaPrint /> Shipping Label</h3>
                <div className="una-shipping-label-action">
                  <a 
                    href={order.shipping_label_url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="una-action-button"
                  >
                    <FaDownload /> View/Download Label
                  </a>
                </div>
              </div>
            )}

            {order.shipping_lines.length > 0 && (
              <div className="una-shipping-card">
                <h3><FaShippingFast /> Shipping Method</h3>
                {order.shipping_lines.map((line, index) => (
                  <div key={index} className="una-shipping-method">
                    <div className="una-shipping-method-details">
                      <div className="una-shipping-method-name">{line.title}</div>
                      {line.carrier_identifier && (
                        <div className="una-shipping-carrier">{line.carrier_identifier}</div>
                      )}
                      {line.delivery_category && (
                        <div className="una-shipping-category">{line.delivery_category}</div>
                      )}
                    </div>
                    <div className="una-shipping-price">${parseFloat(line.price).toFixed(2)}</div>
                  </div>
                ))}
              </div>
            )}
            
            {order.fulfillments && order.fulfillments.length > 0 && (
              <div className="una-shipping-card">
                <h3><FaBox /> Fulfillment</h3>
                {order.fulfillments.map((fulfillment, index) => (
                  <div key={index} className="una-fulfillment">
                    <div className="una-fulfillment-header">
                      <div className={`una-status-pill ${getStatusClass(fulfillment.status)}`}>
                        {getStatusIcon(fulfillment.status)}
                        <span>{fulfillment.status}</span>
                      </div>
                      <div className="una-fulfillment-date">
                        <FaCalendarAlt />
                        <span>{formatDate(fulfillment.created_at)}</span>
                      </div>
                    </div>
                    
                    {fulfillment.tracking_number && (
                      <div className="una-tracking-info">
                        <div className="una-tracking-number">
                          <strong>Tracking:</strong> {fulfillment.tracking_number}
                        </div>
                        {fulfillment.tracking_company && (
                          <div className="una-tracking-company">
                            <strong>Carrier:</strong> {fulfillment.tracking_company}
                          </div>
                        )}
                        {fulfillment.tracking_url && (
                          <a 
                            href={fulfillment.tracking_url} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            className="una-tracking-link"
                          >
                            Track Package
                          </a>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}

            {order.tracking && order.tracking.length > 0 && (
              <div className="una-shipping-card">
                <h3><FaShippingFast /> Tracking Details</h3>
                {order.tracking.map((track, index) => (
                  <div key={index} className="una-tracking-detail-container">
                    <div className="una-tracking-detail-header">
                      <div className={`una-status-pill ${getStatusClass(track.status)}`}>
                        {getStatusIcon(track.status)}
                        <span>{track.status}</span>
                      </div>
                      <div className="una-tracking-info-summary">
                        <div className="una-tracking-number">
                          <strong>{t('Tracking')}:</strong> {track.tracking_number}
                        </div>
                        {track.tracking_company && (
                          <div className="una-tracking-company">
                            <strong>{t('Carrier')}:</strong> {track.tracking_company}
                          </div>
                        )}
                      </div>
                      {track.tracking_url && (
                        <a 
                          href={track.tracking_url} 
                          target="_blank" 
                          rel="noopener noreferrer"
                          className="una-tracking-link"
                        >
                          {t('Track Package')}
                        </a>
                      )}
                    </div>
                    
                    {track.tracking_details && (
                      <div className="una-tracking-timeline">
                        {track.tracking_details.events && track.tracking_details.events.length > 0 ? (
                          <>
                            <div className="una-timeline-current-status">
                              <FaInfoCircle />
                              <span>{track.tracking_details.status || t('In Transit')}</span>
                            </div>
                            <div className="una-timeline-events">
                              {track.tracking_details.events.map((event, eventIndex) => (
                                <div key={eventIndex} className="una-timeline-event">
                                  <div className="una-timeline-dot"></div>
                                  <div className="una-timeline-content">
                                    <div className="una-timeline-date">
                                      {formatDate(event.timestamp)}
                                    </div>
                                    <div className="una-timeline-description">
                                      {event.description}
                                    </div>
                                    {event.location && (
                                      <div className="una-timeline-location">
                                        <FaMapMarkerAlt />
                                        <span>{event.location}</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <div className="una-no-tracking-events">
                            <FaInfoCircle />
                            <span>{t('No tracking events available yet')}</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {activeTab === 'notes' && order.note && (
          <div className="una-tab-panel">
            <div className="una-panel-header">
              <h2><FaFileInvoice /> Order Notes</h2>
            </div>
            
            <div className="una-notes-card">
              <div className="una-note">
                <div className="una-note-content">
                  {order.note}
                </div>
              </div>
              
              {order.tags && order.tags.length > 0 && (
                <div className="una-tags-section">
                  <h3><FaTag /> Tags</h3>
                  <div className="una-tags">
                    {Array.isArray(order.tags) ? order.tags.map((tag, index) => (
                      <span key={index} className="una-tag">
                        {tag.trim()}
                      </span>
                    )) : (
                      <span className="una-tag">{order.tags}</span>
                    )}
                  </div>
                </div>
              )}
            </div>
            
            {order.cancelled_at && (
              <div className="una-cancelled-card">
                <h3><FaTimesCircle /> Cancellation Details</h3>
                <div className="una-cancelled-info">
                  <div className="una-cancelled-date">
                    <strong>Cancelled on:</strong> {formatDate(order.cancelled_at)}
                  </div>
                  {order.cancel_reason && (
                    <div className="una-cancelled-reason">
                      <strong>Reason:</strong> {order.cancel_reason}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {renderRefundModal()}
    </div>
  );
};

export default OrderDetails;