import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  FaArrowLeft, FaStore, FaBoxes, FaShoppingCart, 
  FaBarcode, FaTags, FaBoxOpen, FaTag, FaInfoCircle,
  FaExclamationTriangle, FaCheck
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './ProductDetails.css';

const ProductDetails = () => {
  const { t } = useTranslation();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const { productId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get(`/api/shopify/get_product/${productId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProduct(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  const getStockStatus = (inventory) => {
    if (inventory <= 0) return 'out-of-stock';
    if (inventory < 10) return 'low-stock';
    return 'in-stock';
  };

  if (error) return (
    <div className="una-product-error">
      <FaExclamationTriangle />
      <h2>{t('common.error')}</h2>
      <p>{error}</p>
      <button className="una-back-button" onClick={() => navigate('/products')}>
        <FaArrowLeft /> {t('common.backToProducts')}
      </button>
    </div>
  );

  if (isLoading) {
    return (
      <div className="una-dashboard-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
          <h2>{t('products.loadingDetails')}</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaStore />
              <span>{t('products.fetchingProduct')}</span>
            </div>
            <div className="una-metric-item">
              <FaBoxOpen />
              <span>{t('products.processingInventory')}</span>
            </div>
            <div className="una-metric-item">
              <FaBarcode />
              <span>{t('products.loadingVariants')}</span>
            </div>
            <div className="una-metric-item">
              <FaTags />
              <span>{t('products.processingTags')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-product-details-container">
      <button className="una-back-button" onClick={() => navigate('/products')}>
        <FaArrowLeft /> {t('common.backToProducts')}
      </button>
      
      <div className="una-product-hero">
        <div className="una-product-hero-media">
          <div className="una-product-image-container">
            {product.images && product.images.length > 0 ? (
              <img 
                src={product.images[activeImageIndex].src}
                alt={product.title}
                className="una-product-image"
              />
            ) : (
              <div className="una-product-no-image">
                <FaBoxOpen />
                <span>{t('products.noImage')}</span>
              </div>
            )}
          </div>
          
          {product.images && product.images.length > 1 && (
            <div className="una-product-thumbnails">
              {product.images.map((image, index) => (
                <div 
                  key={index} 
                  className={`una-thumbnail ${activeImageIndex === index ? 'active' : ''}`}
                  onClick={() => setActiveImageIndex(index)}
                >
                  <img src={image.src} alt={`${product.title} ${index + 1}`} />
                </div>
              ))}
            </div>
          )}
        </div>
        
        <div className="una-product-hero-info">
          <div className="una-product-headline">
            <h1 className="una-product-title">{product.title}</h1>
            <div className="una-product-status-badge">{product.status}</div>
          </div>
          
          <div className="una-product-meta">
            <div className="una-meta-item">
              <span className="una-meta-label">{t('products.vendor')}</span>
              <span className="una-meta-value">{product.vendor || '-'}</span>
            </div>
            <div className="una-meta-item">
              <span className="una-meta-label">{t('products.type')}</span>
              <span className="una-meta-value">{product.product_type || '-'}</span>
            </div>
            <div className="una-meta-item">
              <span className="una-meta-label">{t('products.totalInventory')}</span>
              <span className="una-meta-value">
                {product.variants.reduce((sum, v) => sum + v.inventory_quantity, 0)} {t('products.units')}
              </span>
            </div>
            <div className="una-meta-item">
              <span className="una-meta-label">{t('products.variants')}</span>
              <span className="una-meta-value">{product.variants.length}</span>
            </div>
          </div>
          
          {product.body_html && (
            <div className="una-product-description">
              <h3>
                <FaInfoCircle /> {t('products.description')}
              </h3>
              <div className="una-description-content" dangerouslySetInnerHTML={{ __html: product.body_html }} />
            </div>
          )}
        </div>
      </div>
      
      <div className="una-product-grid">
        <div className="una-product-media">
          {Array.isArray(product.tags) && product.tags.length > 0 && (
            <div className="una-product-tags-section">
              <h3>
                <FaTag /> {t('products.tags')}
              </h3>
              <div className="una-product-tags">
                {product.tags.map((tag, index) => (
                  <span key={index} className="una-product-tag">
                    {tag.trim()}
                  </span>
                ))}
              </div>
            </div>
          )}
        </div>
        
        <div className="una-product-info">
          {product.options && product.options.length > 0 && (
            <div className="una-options-section">
              <h3>
                <FaTags /> {t('products.availableOptions')}
              </h3>
              <div className="una-options-grid">
                {product.options.map(option => (
                  <div key={option.name} className="una-option-group">
                    <h4>{option.name}</h4>
                    <div className="una-option-values">
                      {option.values.map((value, index) => (
                        <span key={index} className="una-option-value">
                          {value}
                        </span>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="una-variants-section">
            <h3>
              <FaBarcode /> {t('products.variants')}
            </h3>
            <div className="una-variants-grid">
              {product.variants.map(variant => {
                const stockStatus = getStockStatus(variant.inventory_quantity);
                return (
                  <div key={variant.id} className={`una-variant-card ${stockStatus}`}>
                    <div className="una-variant-header">
                      <h4 className="una-variant-title">{variant.title}</h4>
                      <div className={`una-variant-stock ${stockStatus}`}>
                        {stockStatus === 'out-of-stock' && (
                          <><FaExclamationTriangle /> {t('products.outOfStock')}</>
                        )}
                        {stockStatus === 'low-stock' && (
                          <><FaExclamationTriangle /> {variant.inventory_quantity} {t('products.left')}</>
                        )}
                        {stockStatus === 'in-stock' && (
                          <><FaCheck /> {variant.inventory_quantity} {t('products.inStock')}</>
                        )}
                      </div>
                    </div>
                    
                    <div className="una-variant-content">
                      <div className="una-variant-price-group">
                        <div className="una-price-main">
                          <span className="una-price-label">{t('products.price')}</span>
                          <span className="una-price-value">${parseFloat(variant.price).toFixed(2)}</span>
                        </div>
                        
                        {variant.compare_at_price && (
                          <div className="una-price-compare">
                            <span className="una-price-label">{t('products.compareAt')}</span>
                            <span className="una-price-value una-price-compare-value">
                              ${parseFloat(variant.compare_at_price).toFixed(2)}
                            </span>
                          </div>
                        )}
                      </div>
                      
                      <div className="una-variant-details">
                        {variant.sku && (
                          <div className="una-variant-detail-item">
                            <span className="una-detail-label">{t('products.sku')}</span>
                            <span className="una-detail-value">{variant.sku}</span>
                          </div>
                        )}
                        
                        {variant.barcode && (
                          <div className="una-variant-detail-item">
                            <span className="una-detail-label">{t('products.barcode')}</span>
                            <span className="una-detail-value">{variant.barcode}</span>
                          </div>
                        )}
                        
                        <div className="una-variant-detail-item">
                          <span className="una-detail-label">{t('products.taxable')}</span>
                          <span className="una-detail-value">{variant.taxable ? t('common.yes') : t('common.no')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;