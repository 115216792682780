import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { 
  FaStore, FaTag, FaTags, FaPercent, FaCalendarAlt, 
  FaTrash, FaFilter, FaSearch, FaSortAmountDown, 
  FaUpload, FaStar, FaStarHalfAlt, FaPlus, FaExclamationCircle, FaChartLine, FaHandshake
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './MarketplaceListing.css';

const MarketplaceListing = () => {
  const { t } = useTranslation();
  const [marketplaceProducts, setMarketplaceProducts] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [productQuantities, setProductQuantities] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('all');
  const [sortOption, setSortOption] = useState('published_at-desc');
  const [categories, setCategories] = useState([]);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [commissionRate, setCommissionRate] = useState(10);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const [availableUntil, setAvailableUntil] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        
        // Fetch products already in the marketplace
        const marketplaceResponse = await axios.get(
          '/api/shopify/marketplace/products',
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        // Fetch all products from shop
        const productsResponse = await axios.get(
          '/api/shopify/get_products',
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        
        // Extract unique categories from marketplace products
        const allCategories = marketplaceResponse.data.products
          .flatMap(product => product.categories || [])
          .filter((category, index, self) => self.indexOf(category) === index);
        
        setMarketplaceProducts(marketplaceResponse.data.products);
        setProductsList(productsResponse.data.products);
        setCategories(allCategories);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCheckboxChange = (productId) => {
    setSelectedProducts(prev => {
      if (prev.includes(productId)) {
        return prev.filter(id => id !== productId);
      } else {
        return [...prev, productId];
      }
    });

    // Initialize quantity to 1 when adding a product
    if (!productQuantities[productId]) {
      setProductQuantities(prev => ({
        ...prev,
        [productId]: 1
      }));
    }
  };

  const handleQuantityChange = (productId, quantity) => {
    setProductQuantities(prev => ({
      ...prev,
      [productId]: Math.max(1, parseInt(quantity) || 1)
    }));
  };

  const handlePublishClick = () => {
    setShowPublishModal(true);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => {
      if (prev.includes(category)) {
        return prev.filter(c => c !== category);
      } else {
        return [...prev, category];
      }
    });
  };

  const handlePublishSubmit = async () => {
    try {
      setIsPublishing(true);
      
      const token = sessionStorage.getItem('access_token');
      
      // Format products with quantities for the updated API
      const productsWithQuantities = selectedProducts.map(productId => ({
        product_id: productId,
        quantity: productQuantities[productId] || 1
      }));
      
      await axios.post(
        '/api/shopify/publish_to_marketplace',
        {
          products: productsWithQuantities,
          commission_rate: commissionRate,
          categories: selectedCategories,
          is_featured: isFeatured,
          available_until: availableUntil ? new Date(availableUntil).toISOString() : null
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      // Refresh marketplace products
      const response = await axios.get(
        '/api/shopify/marketplace/products',
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      setMarketplaceProducts(response.data.products);
      setSelectedProducts([]);
      setProductQuantities({});
      setShowPublishModal(false);
      alert(t('marketplace.publishSuccess'));
    } catch (err) {
      setError(err.message);
    } finally {
      setIsPublishing(false);
    }
  };

  const handleRemoveFromMarketplace = async (productId) => {
    try {
      const token = sessionStorage.getItem('access_token');
      
      await axios.delete(
        '/api/shopify/remove_from_marketplace',
        {
          headers: { Authorization: `Bearer ${token}` },
          data: {
            product_ids: [productId]
          }
        }
      );
      
      // Update the marketplace products list
      setMarketplaceProducts(marketplaceProducts.filter(product => 
        product.product_id !== productId
      ));
      
      alert(t('marketplace.removeSuccess'));
    } catch (err) {
      setError(err.message);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return t('marketplace.noExpiration');
    return new Date(dateString).toLocaleDateString();
  };

  // Filter marketplace products
  const filterProducts = () => {
    let filtered = marketplaceProducts;
    
    // Apply search filter
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(product => 
        product.title.toLowerCase().includes(term) ||
        (product.description && product.description.toLowerCase().includes(term))
      );
    }
    
    // Apply category filter
    if (filterCategory !== 'all') {
      filtered = filtered.filter(product => 
        product.categories && product.categories.includes(filterCategory)
      );
    }
    
    // Apply sort
    const [field, direction] = sortOption.split('-');
    filtered = [...filtered].sort((a, b) => {
      let comparison = 0;
      
      switch (field) {
        case 'title':
          comparison = a.title.localeCompare(b.title);
          break;
        case 'price':
          comparison = a.price_range.min - b.price_range.min;
          break;
        case 'commission':
          comparison = a.commission_rate - b.commission_rate;
          break;
        case 'published_at':
          comparison = new Date(a.published_at) - new Date(b.published_at);
          break;
        default:
          comparison = 0;
      }
      
      return direction === 'asc' ? comparison : -comparison;
    });
    
    return filtered;
  };

  // Get products not yet in marketplace
  const getAvailableProducts = () => {
    const marketplaceProductIds = marketplaceProducts.map(p => p.product_id);
    return productsList.filter(product => !marketplaceProductIds.includes(product.id));
  };

  const filteredProducts = filterProducts();
  const availableProducts = getAvailableProducts();

  if (error) return (
    <div className="una-marketplace-container">
      <div className="una-marketplace-error">
        <FaExclamationCircle />
        <h2>{t('common.error')}</h2>
        <p>{error}</p>
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="una-marketplace-container">
        <div className="una-marketplace-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
            <h2>{t('marketplace.loadingTitle')}</h2>
            <div className="una-loading-progress">
              <div className="una-progress-bar">
                <div className="una-progress-glow"></div>
              </div>
            </div>
            <div className="una-loading-metrics">
              <div className="una-metric-item">
                <FaStore />
                <span>{t('marketplace.fetchingProducts')}</span>
              </div>
              <div className="una-metric-item">
                <FaChartLine />
                <span>{t('marketplace.loadingStats')}</span>
              </div>
              <div className="una-metric-item">
                <FaHandshake />
                <span>{t('marketplace.processingData')}</span>
              </div>
              <div className="una-metric-item">
                <FaUpload />
                <span>{t('marketplace.preparingMarketplace')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-marketplace-container">
      <div className="una-marketplace-content">
        <div className="una-marketplace-filters">
          <div className="una-search-group">
            <FaSearch className="una-search-icon" />
            <input
              type="text"
              className="una-search-input"
              placeholder={t('marketplace.searchPlaceholder')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="una-filter-group">
            <label className="una-filter-label">
              <FaFilter /> {t('marketplace.categoryFilter')}
            </label>
            <select 
              className="una-filter-select"
              value={filterCategory}
              onChange={(e) => setFilterCategory(e.target.value)}
            >
              <option value="all">{t('marketplace.allCategories')}</option>
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          
          <div className="una-filter-group">
            <label className="una-filter-label">
              <FaSortAmountDown /> {t('marketplace.sort')}
            </label>
            <select 
              className="una-filter-select"
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value="published_at-desc">{t('marketplace.sortNewest')}</option>
              <option value="published_at-asc">{t('marketplace.sortOldest')}</option>
              <option value="title-asc">{t('marketplace.sortTitleAZ')}</option>
              <option value="title-desc">{t('marketplace.sortTitleZA')}</option>
              <option value="price-asc">{t('marketplace.sortPriceLow')}</option>
              <option value="price-high">{t('marketplace.sortPriceHigh')}</option>
              <option value="commission-desc">{t('marketplace.sortCommissionHigh')}</option>
            </select>
          </div>
        </div>

        <div className="una-marketplace-sections">
          <div className="una-section">
            <div className="una-section-header">
              <h3>{t('marketplace.availableProducts')} ({availableProducts.length})</h3>
              <button 
                onClick={handlePublishClick}
                className="una-publish-button"
                disabled={selectedProducts.length === 0}
              >
                <FaUpload /> {t('marketplace.publishSelected')} 
                {selectedProducts.length > 0 && ` (${selectedProducts.length})`}
              </button>
            </div>
            
            {availableProducts.length === 0 ? (
              <div className="una-empty-state">
                <FaStore />
                <p>{t('marketplace.noAvailableProducts')}</p>
              </div>
            ) : (
              <div className="una-products-selection-grid">
                {availableProducts.map((product) => (
                  <div key={product.id} className="una-product-selection-card">
                    <div className="una-product-checkbox-wrapper">
                      <input
                        type="checkbox"
                        className="una-product-checkbox"
                        checked={selectedProducts.includes(product.id)}
                        onChange={() => handleCheckboxChange(product.id)}
                        aria-label={`Select ${product.title}`}
                      />
                    </div>
                    
                    <div className="una-product-content">
                      <div className="una-product-image-wrapper">
                        {product.images && product.images.length > 0 ? (
                          <img 
                            src={product.images[0].src} 
                            alt={product.title}
                            className="una-product-thumbnail"
                          />
                        ) : (
                          <div className="una-product-no-image">
                            <FaStore />
                          </div>
                        )}
                      </div>
                      
                      <div className="una-product-info">
                        <h4>{product.title}</h4>
                        
                        {product.variants && product.variants.length > 0 && (
                          <p className="una-product-price">
                            {product.variants[0].price} €
                          </p>
                        )}
                        
                        {product.status && (
                          <span className={`una-product-status ${product.status.toLowerCase()}`}>
                            {product.status}
                          </span>
                        )}
                        
                        {selectedProducts.includes(product.id) && (
                          <div className="una-product-quantity">
                            <label>{t('marketplace.quantity')}:</label>
                            <input
                              type="number"
                              min="1"
                              value={productQuantities[product.id] || 1}
                              onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                              className="una-quantity-input"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          
          <div className="una-section">
            <h3>{t('marketplace.publishedProducts')} ({marketplaceProducts.length})</h3>
            
            {filteredProducts.length === 0 ? (
              <div className="una-empty-state">
                <FaStore />
                <p>{t('marketplace.noPublishedProducts')}</p>
              </div>
            ) : (
              <div className="una-marketplace-grid">
                {filteredProducts.map((product) => (
                  <div key={product._id} className="una-marketplace-card">
                    <div className="una-product-image-container">
                      {product.images && product.images.length > 0 ? (
                        <img 
                          src={product.images[0].url} 
                          alt={product.title} 
                          className="una-product-image" 
                        />
                      ) : (
                        <div className="una-product-no-image">
                          <FaStore />
                        </div>
                      )}
                      
                      {product.is_featured && (
                        <div className="una-featured-badge">
                          <FaStar /> {t('marketplace.featured')}
                        </div>
                      )}
                    </div>
                    
                    <div className="una-product-details">
                      <h4>{product.title}</h4>
                      
                      <div className="una-product-meta">
                        <p className="una-product-price">
                          {product.price_range.min} {product.price_range.currency}
                          {product.price_range.min !== product.price_range.max && 
                            ` - ${product.price_range.max} ${product.price_range.currency}`}
                        </p>
                        
                        <p className="una-product-commission">
                          <FaPercent /> {t('marketplace.commission')}: {product.commission_rate}%
                        </p>
                        
                        {product.available_until && (
                          <p className="una-product-expiry">
                            <FaCalendarAlt /> {t('marketplace.availableUntil')}: {formatDate(product.available_until)}
                          </p>
                        )}
                      </div>
                      
                      <div className="una-product-categories">
                        {product.categories && product.categories.map(category => (
                          <span key={category} className="una-category-tag">
                            <FaTags /> {category}
                          </span>
                        ))}
                      </div>
                      
                      <button
                        onClick={() => handleRemoveFromMarketplace(product.product_id)}
                        className="una-remove-button"
                      >
                        <FaTrash /> {t('marketplace.removeFromMarketplace')}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        
        {showPublishModal && (
          <div className="una-modal-overlay">
            <div className="una-modal">
              <h3>{t('marketplace.publishToMarketplace')}</h3>
              
              <div className="una-modal-content">
                <div className="una-form-group">
                  <label>{t('marketplace.commissionRate')}</label>
                  <div className="una-range-input-group">
                    <input
                      type="range"
                      min="1"
                      max="50"
                      value={commissionRate}
                      onChange={(e) => setCommissionRate(parseInt(e.target.value))}
                    />
                    <span className="una-range-value">{commissionRate}%</span>
                  </div>
                  <p className="una-helper-text">{t('marketplace.commissionHelp')}</p>
                </div>
                
                <div className="una-form-group">
                  <label>{t('marketplace.selectCategories')}</label>
                  <div className="una-categories-selection">
                    {categories.map(category => (
                      <label key={category} className="una-category-checkbox">
                        <input
                          type="checkbox"
                          checked={selectedCategories.includes(category)}
                          onChange={() => handleCategoryChange(category)}
                        />
                        {category}
                      </label>
                    ))}
                  </div>
                  <button 
                    className="una-add-category-button"
                    onClick={() => {
                      const newCategory = prompt(t('marketplace.newCategoryPrompt'));
                      if (newCategory && !categories.includes(newCategory)) {
                        setCategories([...categories, newCategory]);
                        setSelectedCategories([...selectedCategories, newCategory]);
                      }
                    }}
                  >
                    <FaPlus /> {t('marketplace.addNewCategory')}
                  </button>
                </div>
                
                <div className="una-form-group">
                  <label className="una-checkbox-label">
                    <input
                      type="checkbox"
                      checked={isFeatured}
                      onChange={(e) => setIsFeatured(e.target.checked)}
                    />
                    {t('marketplace.markAsFeatured')}
                  </label>
                  <p className="una-helper-text">{t('marketplace.featuredHelp')}</p>
                </div>
                
                <div className="una-form-group">
                  <label>
                    <FaCalendarAlt /> {t('marketplace.availableUntil')}
                  </label>
                  <input
                    type="date"
                    value={availableUntil}
                    onChange={(e) => setAvailableUntil(e.target.value)}
                    min={new Date().toISOString().split('T')[0]}
                  />
                  <p className="una-helper-text">{t('marketplace.availableUntilHelp')}</p>
                </div>
              </div>
              
              <div className="una-modal-footer">
                <button 
                  className="una-cancel-button"
                  onClick={() => setShowPublishModal(false)}
                >
                  {t('common.cancel')}
                </button>
                <button 
                  className="una-submit-button"
                  onClick={handlePublishSubmit}
                  disabled={isPublishing}
                >
                  {isPublishing ? t('common.publishing') : t('marketplace.publishNow')}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketplaceListing;