import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTruck, FaEdit } from 'react-icons/fa';
import './CarriersConversation.css';
import axios from 'axios';
import Button from './UI/Button';

// Carrier requirements used to display the extra fields in the manual form.
const carrierRequirements = {
  "Mondial Relay": ["tracking_number", "option_text", "shop_name", "message"],
  "Colis Privé": ["tracking_number", "package_value", "motif", "comment"],
  "Chronopost": {
    always: ["tracking_number", "objet"],
    conditional: {
      "Instruction de livraison": ["motif", "comment"],
      "Contestation de livraison": ["motif", "comment"],
      "Demande de preuve de livraison": ["motif", "comment"],
      "SWAP": ["motif", "comment"],
      "Signaler un dysfonctionnement": ["motif", "comment"],
      "Fraude": ["package_content", "product_family", "comment"],
      "Livraison partielle ou endommagée": ["package_content", "product_family", "comment"],
      "Position Colis": ["package_content", "product_family", "comment"],
      "Retour Expéditeur": ["package_content", "product_family", "comment"],
      "Récupérer colis en Pack City": ["package_content", "product_family", "comment"],
      "Retard / Hors délais": ["comment"],
      "Retour express de paiement": ["package_content", "comment"]
    }
  },
  "Colissimo": ["tracking_number", "motif_text", "comments", "colis_content", "colis_value", "file_type", "file_name"],
  "Amazon": ["tracking_id", "price", "file_name"],
  "DHL": ["tracking_id", "comment", "file_name"]
};

/**
 * This form is rendered only when no carriers conversation exists.
 * It displays fields based on the selected carrier and its requirements.
 */
const ManualCarrierConversationForm = ({ onRefresh }) => {
  const { t } = useTranslation();
  const [manualCarrier, setManualCarrier] = useState("");
  const [manualTrackingId, setManualTrackingId] = useState("");
  const [manualTicketId, setManualTicketId] = useState("");
  const [manualUserId, setManualUserId] = useState("");
  const [manualAsstId, setManualAsstId] = useState("");
  const [manualParams, setManualParams] = useState({});
  const [manualFiles, setManualFiles] = useState([]);
  // For Chronopost conditional issue types
  const [chronopostConditional, setChronopostConditional] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleCarrierChange = (e) => {
    const carrier = e.target.value;
    setManualCarrier(carrier);
    // Reset any previously entered extra fields when carrier changes.
    setManualParams({});
    setChronopostConditional("");
  };

  const handleParamChange = (key, value) => {
    setManualParams(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const handleFileChange = (e) => {
    setManualFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setSuccessMessage("");

    const token = sessionStorage.getItem('access_token');
    const formData = new FormData();
    formData.append("carrier_name", manualCarrier);
    formData.append("tracking_id", manualTrackingId);
    formData.append("ticket_id", manualTicketId);
    formData.append("user_id", manualUserId);
    if (manualAsstId) {
      formData.append("asst_id", manualAsstId);
    }

    // For Chronopost, you may want to include the chosen conditional type.
    let paramsToSend = { ...manualParams };
    if (manualCarrier === "Chronopost" && chronopostConditional) {
      paramsToSend.conditional_type = chronopostConditional;
    }
    formData.append("params", JSON.stringify(paramsToSend));

    manualFiles.forEach(file => {
      formData.append("files", file);
    });

    try {
      await axios.post(
        '/api/tickets/manual-carrier-conversation',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      setSuccessMessage(t('carriersConversation.manualSuccess') || "Manual conversation started successfully.");
      // Reset form fields
      setManualCarrier("");
      setManualTrackingId("");
      setManualTicketId("");
      setManualUserId("");
      setManualAsstId("");
      setManualParams({});
      setManualFiles([]);
      setChronopostConditional("");
      if (onRefresh) {
        onRefresh();
      }
    } catch (err) {
      console.error('Error starting manual carrier conversation:', err);
      setError(t('carriersConversation.manualError') || "Failed to start manual conversation.");
    }
    setIsSubmitting(false);
  };

  // Render the extra parameters based on the selected carrier.
  const renderAdditionalFields = () => {
    if (!manualCarrier) return null;
    
    const requirements = carrierRequirements[manualCarrier];
    
    if (Array.isArray(requirements)) {
      // Simple array of required fields (e.g. Mondial Relay, Colis Privé, Colissimo, Amazon, DHL)
      return requirements.map(field => (
        <div key={field} className="param-item">
          <label className="param-label">
            {t(`carriersConversation.${field}`) || field}:
          </label>
          <input
            type="text"
            value={manualParams[field] || ""}
            onChange={(e) => handleParamChange(field, e.target.value)}
            className="param-input"
          />
        </div>
      ));
    } else if (typeof requirements === 'object') {
      // Chronopost requires both the "always" fields and a conditional set based on an issue type.
      const alwaysFields = requirements.always;
      const conditionalOptions = Object.keys(requirements.conditional);
      return (
        <>
          {alwaysFields.map(field => (
            <div key={field} className="param-item">
              <label className="param-label">
                {t(`carriersConversation.${field}`) || field}:
              </label>
              <input
                type="text"
                value={manualParams[field] || ""}
                onChange={(e) => handleParamChange(field, e.target.value)}
                className="param-input"
              />
            </div>
          ))}
          <div className="param-item">
            <label className="param-label">
              {t('carriersConversation.chronopostConditional') || "Select Issue Type"}:
            </label>
            <select
              value={chronopostConditional}
              onChange={(e) => setChronopostConditional(e.target.value)}
              className="param-input"
            >
              <option value="">
                {t('carriersConversation.selectOption') || "Select an option"}
              </option>
              {conditionalOptions.map(option => (
                <option key={option} value={option}>
                  {t(`carriersConversation.${option}`) || option}
                </option>
              ))}
            </select>
          </div>
          {chronopostConditional &&
            requirements.conditional[chronopostConditional] &&
            requirements.conditional[chronopostConditional].map(field => (
              <div key={field} className="param-item">
                <label className="param-label">
                  {t(`carriersConversation.${field}`) || field}:
                </label>
                <input
                  type="text"
                  value={manualParams[field] || ""}
                  onChange={(e) => handleParamChange(field, e.target.value)}
                  className="param-input"
                />
              </div>
            ))
          }
        </>
      );
    }
    return null;
  };

  return (
    <div className="manual-carrier-conversation-form">
      <h2>{t('carriersConversation.manualFormTitle') || "Contact Carrier Manually"}</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>
            {t('carriersConversation.carrierName') || "Carrier Name"}:
          </label>
          <select value={manualCarrier} onChange={handleCarrierChange}>
            <option value="">{t('carriersConversation.selectCarrier') || "Select Carrier"}</option>
            {Object.keys(carrierRequirements).map(carrier => (
              <option key={carrier} value={carrier}>
                {carrier}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>
            {t('carriersConversation.trackingId') || "Tracking ID"}:
          </label>
          <input
            type="text"
            value={manualTrackingId}
            onChange={(e) => setManualTrackingId(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            {t('carriersConversation.ticketId') || "Ticket ID"}:
          </label>
          <input
            type="text"
            value={manualTicketId}
            onChange={(e) => setManualTicketId(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            {t('carriersConversation.userId') || "User ID"}:
          </label>
          <input
            type="text"
            value={manualUserId}
            onChange={(e) => setManualUserId(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>
            {t('carriersConversation.asstId') || "Assistant ID (optional)"}:
          </label>
          <input
            type="text"
            value={manualAsstId}
            onChange={(e) => setManualAsstId(e.target.value)}
          />
        </div>

        {renderAdditionalFields()}

        <div className="form-group">
          <label>{t('carriersConversation.files') || "Files"}:</label>
          <input type="file" multiple onChange={handleFileChange} />
        </div>

        {error && <div className="error-message">{error}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}

        <Button 
          variant="primary" 
          type="submit" 
          disabled={isSubmitting}
        >
          {isSubmitting
            ? t('carriersConversation.submitting') || "Submitting..."
            : t('carriersConversation.submit') || "Submit"}
        </Button>
      </form>
    </div>
  );
};

/**
 * Main CarriersConversation component.
 * If a conversation exists (provided as a prop), it renders the conversation view.
 * Otherwise, it displays the manual conversation form.
 */
const CarriersConversation = ({ conversation, onValidateMessage, isValidating, onRefresh }) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editedParams, setEditedParams] = useState({});
  const [editedMessage, setEditedMessage] = useState('');

  // When no conversation exists, show the manual form.
  if (!conversation) {
    return <ManualCarrierConversationForm onRefresh={onRefresh} />;
  }

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString();
  };

  const getCarrierClass = (carrierName) => {
    const carrier = carrierName?.toLowerCase().replace(/\s+/g, '-') || '';
    return `carrier-${carrier}`;
  };

  // If there are no messages yet but the conversation is pending validation,
  // create a default message from the interaction_params.
  const messages =
    conversation.messages ||
    (conversation.status === 'pending_validation'
      ? [
          {
            direction: 'sent',
            content:
              conversation.interaction_params?.message ||
              conversation.interaction_params?.comment ||
              '',
            timestamp: conversation.created_at,
            status: 'pending'
          }
        ]
      : []);

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedParams(conversation.interaction_params || {});
    setEditedMessage(messages[0]?.content || '');
  };

  const handleSaveEdit = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `/api/tickets/carriers_conversation/${conversation.ticket_id}/update`,
        {
          interaction_params: editedParams,
          first_message: editedMessage
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      setIsEditing(false);
      // Trigger a refresh of the conversation data
      if (onRefresh) {
        onRefresh();
      }
    } catch (error) {
      console.error('Failed to update carriers conversation:', error);
    }
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditedParams(conversation.interaction_params || {});
    setEditedMessage(messages[0]?.content || '');
  };

  const parseCarrierResponse = (content) => {
    if (!content) return { details: '' };

    // Split the content by email separator pattern (line starting with '>')
    const lines = content.split('\n');
    
    // Get the actual response (everything before the first email quote)
    const response = lines
      .filter(line => !line.trim().startsWith('>'))
      .join('\n')
      .trim();

    return {
      details: response || content // Fallback to full content if parsing fails
    };
  };

  return (
    <div className="carriers-conversation">
      {(conversation.tracking_id || conversation.carrier_name) && (
        <div className="carriers-conversation-tracking">
          {conversation.carrier_name && (
            <span className={`tracking-badge carrier ${getCarrierClass(conversation.carrier_name)}`}>
              {conversation.carrier_name}
            </span>
          )}
          {conversation.tracking_id && (
            <span className="tracking-badge">
              {t('carriersConversation.trackingId')}: {conversation.tracking_id}
            </span>
          )}
        </div>
      )}

      <div className="carriers-conversation-content">
        {conversation.interaction_params && (
          <div className="carriers-conversation-params">
            {isEditing ? (
              <>
                {Object.entries(editedParams).map(([key, value]) => (
                  <div key={key} className="param-item">
                    <span className="param-label">{t(`carriersConversation.${key}`)}:</span>
                    <input
                      type="text"
                      value={value}
                      onChange={(e) =>
                        setEditedParams({
                          ...editedParams,
                          [key]: e.target.value
                        })
                      }
                      className="param-input"
                    />
                  </div>
                ))}
                <div className="edit-actions">
                  <Button 
                    variant="primary" 
                    onClick={handleSaveEdit} 
                    className="edit-button save"
                  >
                    {t('carriersConversation.save')}
                  </Button>
                  <Button 
                    variant="secondary" 
                    onClick={handleCancelEdit} 
                    className="edit-button cancel"
                  >
                    {t('carriersConversation.cancel')}
                  </Button>
                </div>
              </>
            ) : (
              <div className="params-content">
                {conversation.interaction_params.option_text && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.option')}:</span>
                    <span className="param-value">{conversation.interaction_params.option_text}</span>
                  </div>
                )}
                {conversation.interaction_params.tracking_id && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.trackingId')}:</span>
                    <span className="param-value">{conversation.interaction_params.tracking_id}</span>
                  </div>
                )}
                {conversation.interaction_params.price && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.price')}:</span>
                    <span className="param-value">{conversation.interaction_params.price}</span>
                  </div>
                )}
                {conversation.interaction_params.file_path && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.filePath')}:</span>
                    <span className="param-value">{conversation.interaction_params.file_path}</span>
                  </div>
                )}
                {conversation.interaction_params.motif_text && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.motif')}:</span>
                    <span className="param-value">{conversation.interaction_params.motif_text}</span>
                  </div>
                )}
                {conversation.interaction_params.tracking_number && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.trackingNumber')}:</span>
                    <span className="param-value">{conversation.interaction_params.tracking_number}</span>
                  </div>
                )}
                {conversation.interaction_params.comments && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.comments')}:</span>
                    <span className="param-value">{conversation.interaction_params.comments}</span>
                  </div>
                )}
                {conversation.interaction_params.colis_content && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.colisContent')}:</span>
                    <span className="param-value">{conversation.interaction_params.colis_content}</span>
                  </div>
                )}
                {conversation.interaction_params.colis_value && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.colisValue')}:</span>
                    <span className="param-value">{conversation.interaction_params.colis_value}</span>
                  </div>
                )}
                {conversation.interaction_params.package_value && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.packageValue')}:</span>
                    <span className="param-value">{conversation.interaction_params.package_value}</span>
                  </div>
                )}
                {conversation.interaction_params.objet && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.objet')}:</span>
                    <span className="param-value">{conversation.interaction_params.objet}</span>
                  </div>
                )}
                {conversation.interaction_params.package_content && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.packageContent')}:</span>
                    <span className="param-value">{conversation.interaction_params.package_content}</span>
                  </div>
                )}
                {conversation.interaction_params.product_family && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.productFamily')}:</span>
                    <span className="param-value">{conversation.interaction_params.product_family}</span>
                  </div>
                )}
                {conversation.interaction_params.comment && (
                  <div className="param-item">
                    <span className="param-label">{t('carriersConversation.comment')}:</span>
                    <span className="param-value">{conversation.interaction_params.comment}</span>
                  </div>
                )}
                {conversation.status === 'pending_validation' && (
                  <Button 
                    variant="tertiary" 
                    onClick={handleEditClick} 
                    className="edit-button"
                  >
                    <FaEdit /> {t('carriersConversation.edit')}
                  </Button>
                )}
              </div>
            )}
          </div>
        )}

        {conversation.status !== 'completed' && (
          <div className="carriers-conversation-messages">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`carriers-conversation-message carriers-conversation-message--${message.direction}`}
              >
                <div className="carriers-conversation-message__sender">
                  {message.direction === 'received'
                    ? t('carriersConversation.carrier')
                    : t('carriersConversation.us')}
                </div>
                <div className="carriers-conversation-message__content">
                  {isEditing && index === 0 ? (
                    <textarea
                      value={editedMessage}
                      onChange={(e) => setEditedMessage(e.target.value)}
                      className="message-edit-textarea"
                    />
                  ) : (
                    <div className="carrier-response">
                      {message.direction === 'received' ? (
                        <div className="carrier-response__details">
                          <div className="carrier-response__details-label">
                            {t('carriersConversation.details')}:
                          </div>
                          <div className="carrier-response__details-content">
                            {parseCarrierResponse(message.content).details}
                          </div>
                        </div>
                      ) : (
                        <div className="carrier-response__details-content">
                          {message.content}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="carriers-conversation-message__meta">
                  <span className="carriers-conversation-message__timestamp">
                    {formatTimestamp(message.timestamp)}
                  </span>
                  <span className="carriers-conversation-message__status">
                    {t(`carriersConversation.status.${message.status || conversation.status}`)}
                  </span>
                </div>
                {(message.status === 'pending' || conversation.status === 'pending_validation') &&
                  message.direction !== 'received' &&
                  message.status !== 'completed' && (
                    <div className="carriers-conversation-message__validation">
                      <Button
                        variant="tertiary"
                        className="carriers-conversation-message__button carriers-conversation-message__button--approve"
                        onClick={() => onValidateMessage(conversation._id, true)}
                        disabled={isValidating}
                      >
                        {t('carriersConversation.approve')}
                      </Button>
                      <Button
                        variant="tertiary"
                        className="carriers-conversation-message__button carriers-conversation-message__button--reject"
                        onClick={() => onValidateMessage(conversation._id, false)}
                        disabled={isValidating}
                      >
                        {t('carriersConversation.reject')}
                      </Button>
                    </div>
                  )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CarriersConversation;
