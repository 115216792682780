import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaSave, FaTimes, FaPlus, FaFileAlt, FaKey, FaSearch } from 'react-icons/fa';
import './CarriersInstructions.css';
import Button from './UI/Button';

const CarriersInstructions = () => {
  const { t } = useTranslation();
  const [carriers, setCarriers] = useState([]);
  const [editingCarrier, setEditingCarrier] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [automatedCarriers, setAutomatedCarriers] = useState([]);
  const [showSelectionOverlay, setShowSelectionOverlay] = useState(false);
  const [newCarrier, setNewCarrier] = useState({
    name: '',
    emails: [],
    contact_rules: '',
    automation: false,
    credentials: {
      username: '',
      password: ''
    },
    isAutomated: false
  });

  const ITEMS_PER_PAGE = 6;
  
  // Filter carriers based on search query
  const filteredCarriers = carriers.filter(carrier => 
    carrier.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  const totalPages = Math.ceil(filteredCarriers.length / ITEMS_PER_PAGE);
  const paginatedCarriers = filteredCarriers.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Reset to first page when search query changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    fetchCarriers();
  }, []);

  const fetchCarriers = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/logistics/carriers', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCarriers(response.data);
    } catch (error) {
      console.error('Failed to fetch carriers:', error);
    }
  };

  const fetchAutomatedCarriers = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/logistics/automated-carriers', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAutomatedCarriers(response.data);
    } catch (error) {
      console.error('Failed to fetch automated carriers:', error);
    }
  };

  const handleUpdateCarrier = async (carrierId) => {
    if (!editingCarrier) return;
    
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `/api/logistics/carriers/${carrierId}`,
        {
          name: editingCarrier.name,
          emails: editingCarrier.emails,
          contact_rules: editingCarrier.contact_rules,
          automation: editingCarrier.automation,
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      await fetchCarriers();
      handleCloseModal();
    } catch (error) {
      console.error('Failed to update carrier:', error);
    }
  };

  const handleCreateCarrier = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post('/api/logistics/carriers', newCarrier, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setShowCreateModal(false);
      setNewCarrier({
        name: '',
        emails: [],
        contact_rules: '',
        automation: false,
        credentials: {
          username: '',
          password: ''
        },
        isAutomated: false
      });
      fetchCarriers();
    } catch (error) {
      console.error('Failed to create carrier:', error);
    }
  };

  const handleCarrierClick = (carrier) => {
    setSelectedCarrier(carrier);
    setEditingCarrier({ ...carrier });
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    setShowCreateModal(false);
    setShowSelectionOverlay(false);
    setEditingCarrier(null);
    setSelectedCarrier(null);
  };

  const handleCreateButtonClick = async () => {
    await fetchAutomatedCarriers();
    setShowSelectionOverlay(true);
  };

  const handleSelectAutomatedCarrier = (carrier) => {
    setNewCarrier({
      ...carrier,
      credentials: {
        username: '',
        password: ''
      },
      isAutomated: true
    });
    setShowSelectionOverlay(false);
    setShowCreateModal(true);
  };

  const handleSelectManualCreation = () => {
    setNewCarrier({
      name: '',
      emails: [],
      contact_rules: '',
      automation: false,
      credentials: {
        username: '',
        password: ''
      },
      isAutomated: false
    });
    setShowSelectionOverlay(false);
    setShowCreateModal(true);
  };

  const CarrierCard = ({ carrier, onClick }) => (
    <div className="carrier-card" onClick={onClick}>
      <div className="carrier-content">
        <h3 className="carrier-title">{carrier.name}</h3>
        <div className="carrier-emails-container">
          {carrier.emails && carrier.emails.length > 0 ? (
            <div className="carrier-emails-list">
              {carrier.emails.map((email, idx) => (
                <div key={idx} className="carrier-email">{email}</div>
              ))}
            </div>
          ) : (
            <div className="carrier-email no-emails">{t('carriersInstructions.noEmails')}</div>
          )}
        </div>
        <div className="carrier-info-grid">
          <div className="info-item">
            <span className="info-label">{t('carriersInstructions.rules')}</span>
            <FaFileAlt className={carrier.contact_rules ? 'success' : 'failure'} />
          </div>
          <div className="info-item">
            <span className="info-label">{t('carriersInstructions.credentials')}</span>
            <FaKey className={carrier.credentials?.username ? 'success' : 'failure'} />
          </div>
          <div className="info-item">
            <span className="info-label">{t('carriersInstructions.automation')}</span>
            <div className={`automation-status ${carrier.automation ? 'enabled' : 'disabled'}`}>
              {carrier.automation ? t('common.on') : t('common.off')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // Email management functions
  const handleAddEmail = (isNewCarrier = false) => {
    if (isNewCarrier) {
      setNewCarrier({
        ...newCarrier,
        emails: [...newCarrier.emails, '']
      });
    } else {
      setEditingCarrier({
        ...editingCarrier,
        emails: [...(editingCarrier.emails || []), '']
      });
    }
  };

  const handleEmailChange = (index, value, isNewCarrier = false) => {
    if (isNewCarrier) {
      const updatedEmails = [...newCarrier.emails];
      updatedEmails[index] = value;
      setNewCarrier({
        ...newCarrier,
        emails: updatedEmails
      });
    } else {
      const updatedEmails = [...(editingCarrier.emails || [])];
      updatedEmails[index] = value;
      setEditingCarrier({
        ...editingCarrier,
        emails: updatedEmails
      });
    }
  };

  const handleRemoveEmail = (index, isNewCarrier = false) => {
    if (isNewCarrier) {
      const updatedEmails = [...newCarrier.emails];
      updatedEmails.splice(index, 1);
      setNewCarrier({
        ...newCarrier,
        emails: updatedEmails
      });
    } else {
      const updatedEmails = [...(editingCarrier.emails || [])];
      updatedEmails.splice(index, 1);
      setEditingCarrier({
        ...editingCarrier,
        emails: updatedEmails
      });
    }
  };

  return (
    <div className="carriers-instructions-container">
      <div className="header">
        <div className="pagination">
          <Button 
            variant="tertiary"
            className="page-button"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(p => p - 1)}
          >
            {t('common.previous')}
          </Button>
          <span className="page-info">
            {t('common.page')} {currentPage} / {Math.max(1, totalPages)}
          </span>
          <Button 
            variant="tertiary"
            className="page-button"
            disabled={currentPage === totalPages || totalPages === 0}
            onClick={() => setCurrentPage(p => p + 1)}
          >
            {t('common.next')}
          </Button>
        </div>
        
        <div className="search-container">
          <div className="search-input-wrapper">
            <FaSearch className="search-icon" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('common.search')}
              className="search-input"
            />
            {searchQuery && (
              <Button 
                variant="tertiary"
                className="clear-search" 
                onClick={() => setSearchQuery('')}
              >
                <FaTimes />
              </Button>
            )}
          </div>
        </div>
        
        <Button 
          variant="tertiary"
          className="create-button" 
          onClick={handleCreateButtonClick}
        >
          <FaPlus /> {t('carriersInstructions.createNew')}
        </Button>
      </div>

      <div className="carriers-grid">
        {paginatedCarriers.length > 0 ? (
          paginatedCarriers.map(carrier => (
            <CarrierCard 
              key={carrier.id} 
              carrier={carrier}
              onClick={() => handleCarrierClick(carrier)}
            />
          ))
        ) : (
          <div className="no-results">
            {searchQuery ? t('common.noSearchResults') : t('carriersInstructions.noCarriers')}
          </div>
        )}
      </div>

      {/* Selection Overlay */}
      {showSelectionOverlay && (
        <div className="modalai">
          <div className="modal-content carrier-modal">
            <h3>{t('carriersInstructions.addCarrier')}</h3>
            <div className="carrier-selection-options">
              <div className="selection-section">
                <h4>{t('carriersInstructions.selectAutomatedCarrier')}</h4>
                {automatedCarriers.length > 0 ? (
                  <div className="automated-carriers-list">
                    {automatedCarriers.map((carrier) => (
                      <div 
                        key={carrier.id} 
                        className="automated-carrier-item"
                        onClick={() => handleSelectAutomatedCarrier(carrier)}
                      >
                        <span>{carrier.name}</span>
                        <div className="automation-badge">{t('carriersInstructions.automated')}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>{t('carriersInstructions.noAutomatedCarriers')}</p>
                )}
              </div>
              
              <div className="selection-divider">
                <span>{t('common.or')}</span>
              </div>
              
              <div className="selection-section">
                <Button 
                  variant="primary"
                  onClick={handleSelectManualCreation}
                >
                  {t('carriersInstructions.addCustomCarrier')}
                </Button>
              </div>
            </div>
            <div className="modal-actions">
              <Button 
                variant="secondary"
                onClick={handleCloseModal}
              >
                <FaTimes /> {t('common.cancel')}
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Modal */}
      {showEditModal && (
        <div className="modalai">
          <div className="modal-content carrier-modal">
            <h3>{t('carriersInstructions.editCarrier')}</h3>
            <div className="form-group">
              <label>{t('carriersInstructions.name')}:</label>
              <input
                type="text"
                value={editingCarrier.name}
                onChange={(e) => setEditingCarrier({
                  ...editingCarrier,
                  name: e.target.value
                })}
                className="ai-agent-input"
              />
            </div>
            <div className="form-group">
              <label>{t('carriersInstructions.emails')}:</label>
              {editingCarrier.emails && editingCarrier.emails.map((email, index) => (
                <div key={index} className="email-input-group">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    className="ai-agent-input"
                    placeholder={t('carriersInstructions.emailPlaceholder')}
                  />
                  <Button 
                    variant="tertiary"
                    className="remove-email-btn"
                    onClick={() => handleRemoveEmail(index)}
                  >
                    <FaTimes />
                  </Button>
                </div>
              ))}
              <Button 
                variant="tertiary"
                className="add-email-btn"
                onClick={() => handleAddEmail()}
              >
                <FaPlus /> {t('carriersInstructions.addEmail')}
              </Button>
            </div>
            <div className="form-group">
              <label>{t('carriersInstructions.contactRules')}:</label>
              <textarea
                value={editingCarrier.contact_rules}
                onChange={(e) => setEditingCarrier({
                  ...editingCarrier,
                  contact_rules: e.target.value
                })}
                rows="8"
                className="ai-agent-textarea"
              />
            </div>
            <div className="form-group">
              <label>{t('carriersInstructions.automation')}:</label>
              <div className="toggle-container">
                <input
                  type="checkbox"
                  id="automation-toggle"
                  className="toggle-checkbox"
                  checked={editingCarrier.automation || false}
                  onChange={(e) => setEditingCarrier({
                    ...editingCarrier,
                    automation: e.target.checked
                  })}
                />
                <label htmlFor="automation-toggle" className="toggle-label">
                  {editingCarrier.automation ? t('common.on') : t('common.off')}
                </label>
              </div>
            </div>
            
            {/* Only display credentials for carriers without emails */}
            {(!editingCarrier.emails || editingCarrier.emails.length === 0) && (
              <div className="form-group">
                <label>{t('carriersInstructions.credentials')}:</label>
                <input
                  type="text"
                  value={editingCarrier.credentials?.username || ''}
                  onChange={(e) => setEditingCarrier({
                    ...editingCarrier,
                    credentials: {
                      ...editingCarrier.credentials,
                      username: e.target.value
                    }
                  })}
                  placeholder={t('carriersInstructions.username')}
                  className="ai-agent-input"
                />
                <input
                  type="password"
                  value={editingCarrier.credentials?.password || ''}
                  onChange={(e) => setEditingCarrier({
                    ...editingCarrier,
                    credentials: {
                      ...editingCarrier.credentials,
                      password: e.target.value
                    }
                  })}
                  placeholder={t('carriersInstructions.password')}
                  className="ai-agent-input"
                />
              </div>
            )}
            
            <div className="modal-actions">
              <Button 
                variant="primary"
                onClick={() => handleUpdateCarrier(selectedCarrier.id)}
              >
                <FaSave /> {t('common.save')}
              </Button>
              <Button 
                variant="secondary"
                onClick={handleCloseModal}
              >
                <FaTimes /> {t('common.cancel')}
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Create Modal */}
      {showCreateModal && (
        <div className="modalai">
          <div className="modal-content carrier-modal">
            <h3>{t('carriersInstructions.createNew')}</h3>
            <input
              type="text"
              value={newCarrier.name}
              onChange={(e) => setNewCarrier({
                ...newCarrier,
                name: e.target.value
              })}
              placeholder={t('carriersInstructions.name')}
            />
            
            {/* Only show email fields for manual carriers */}
            {!newCarrier.isAutomated && (
              <div className="form-group">
                <label>{t('carriersInstructions.emails')}:</label>
                {newCarrier.emails.map((email, index) => (
                  <div key={index} className="email-input-group">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => handleEmailChange(index, e.target.value, true)}
                      placeholder={t('carriersInstructions.emailPlaceholder')}
                    />
                    <Button 
                      variant="tertiary"
                      className="remove-email-btn"
                      onClick={() => handleRemoveEmail(index, true)}
                    >
                      <FaTimes />
                    </Button>
                  </div>
                ))}
                <Button 
                  variant="tertiary"
                  className="add-email-btn"
                  onClick={() => handleAddEmail(true)}
                >
                  <FaPlus /> {t('carriersInstructions.addEmail')}
                </Button>
              </div>
            )}
            
            <textarea
              value={newCarrier.contact_rules}
              onChange={(e) => setNewCarrier({
                ...newCarrier,
                contact_rules: e.target.value
              })}
              placeholder={t('carriersInstructions.contactRules')}
            />
            <div className="form-group">
              <label>{t('carriersInstructions.automation')}:</label>
              <div className="toggle-container">
                <input
                  type="checkbox"
                  id="new-automation-toggle"
                  className="toggle-checkbox"
                  checked={newCarrier.automation}
                  onChange={(e) => setNewCarrier({
                    ...newCarrier,
                    automation: e.target.checked
                  })}
                />
                <label htmlFor="new-automation-toggle" className="toggle-label">
                  {newCarrier.automation ? t('common.on') : t('common.off')}
                </label>
              </div>
            </div>
            
            {/* Only show credentials for automated carriers */}
            {newCarrier.isAutomated && (
              <>
                <input
                  type="text"
                  value={newCarrier.credentials.username}
                  onChange={(e) => setNewCarrier({
                    ...newCarrier,
                    credentials: {
                      ...newCarrier.credentials,
                      username: e.target.value
                    }
                  })}
                  placeholder={t('carriersInstructions.username')}
                />
                <input
                  type="password"
                  value={newCarrier.credentials.password}
                  onChange={(e) => setNewCarrier({
                    ...newCarrier,
                    credentials: {
                      ...newCarrier.credentials,
                      password: e.target.value
                    }
                  })}
                  placeholder={t('carriersInstructions.password')}
                />
              </>
            )}
            
            <div className="modal-actions">
              <Button 
                variant="primary" 
                onClick={handleCreateCarrier}
              >
                {t('common.create')}
              </Button>
              <Button 
                variant="secondary" 
                onClick={() => setShowCreateModal(false)}
              >
                {t('common.cancel')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarriersInstructions;
