import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  FaStore, FaStar, FaTags, FaPercent, FaCalendarAlt,
  FaFilter, FaSearch, FaSortAmountDown, FaChevronLeft,
  FaChevronRight, FaEye, FaShoppingCart, FaCubes, FaLayerGroup, FaExclamationCircle, FaRegClock
} from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './Marketplace.css';

const Marketplace = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalProducts, setTotalProducts] = useState(0);
  
  // Filter states
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isFeaturedOnly, setIsFeaturedOnly] = useState(false);
  
  // Sorting states
  const [sortBy, setSortBy] = useState('published_at');
  const [sortOrder, setSortOrder] = useState('desc');
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10);

  useEffect(() => {
    fetchProducts();
    fetchCategories();
  }, [selectedCategories, isFeaturedOnly, searchQuery, sortBy, sortOrder, currentPage, productsPerPage]);

  const fetchCategories = async () => {
    try {
      // This would be better if provided by a dedicated API endpoint
      // For now, we'll get them from the products we fetch
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/shopify/marketplace/products', {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const allCategories = response.data.products
        .flatMap(product => product.categories || [])
        .filter((category, index, self) => self.indexOf(category) === index);
      
      setCategories(allCategories);
    } catch (err) {
      console.error('Error fetching categories:', err);
    }
  };

  const fetchProducts = async () => {
    try {
      setIsLoading(true);
      const token = sessionStorage.getItem('access_token');
      
      // Calculate pagination params
      const skip = (currentPage - 1) * productsPerPage;
      const limit = productsPerPage;
      
      const response = await axios.get('/api/shopify/marketplace/products', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          categories: selectedCategories.length > 0 ? selectedCategories : undefined,
          is_featured: isFeaturedOnly ? true : undefined,
          search_query: searchQuery || undefined,
          sort_by: sortBy,
          sort_order: sortOrder,
          skip,
          limit
        }
      });
      
      setProducts(response.data.products);
      setTotalProducts(response.data.total);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => {
      if (prev.includes(category)) {
        return prev.filter(c => c !== category);
      } else {
        return [...prev, category];
      }
    });
    setCurrentPage(1); // Reset pagination when changing filters
  };

  const handleFeaturedChange = (e) => {
    setIsFeaturedOnly(e.target.checked);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    // Search is triggered by the useEffect
  };

  const handleSortChange = (e) => {
    const [newSortBy, newSortOrder] = e.target.value.split('-');
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const formatDate = (dateString) => {
    if (!dateString) return t('marketplace.noExpiration');
    return new Date(dateString).toLocaleDateString();
  };

  // Calculate total pages
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  
  // Generate page numbers array
  const getPageNumbers = () => {
    const pageNumbers = [];
    const MAX_VISIBLE_PAGES = 5;
    
    if (totalPages <= MAX_VISIBLE_PAGES) {
      // Show all pages if there are few
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always show first page
      pageNumbers.push(1);
      
      // Calculate middle range
      let startPage = Math.max(2, currentPage - 1);
      let endPage = Math.min(totalPages - 1, currentPage + 1);
      
      // Adjust range if at edges
      if (currentPage <= 2) {
        endPage = 4;
      } else if (currentPage >= totalPages - 1) {
        startPage = totalPages - 3;
      }
      
      // Add ellipsis if needed
      if (startPage > 2) {
        pageNumbers.push('...');
      }
      
      // Add middle pages
      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }
      
      // Add ellipsis if needed
      if (endPage < totalPages - 1) {
        pageNumbers.push('...');
      }
      
      // Always show last page
      pageNumbers.push(totalPages);
    }
    
    return pageNumbers;
  };

  if (error) return <div className="una-error">{t('common.error')}: {error}</div>;

  return (
    <div className="una-marketplace-main-container">
      <div className="una-marketplace-header">
        
        <div className="una-marketplace-header-actions">
          <Link to="/marketplace-listing" className="una-header-button">
            <FaCubes /> {t('marketplace.myListings')}
          </Link>
          <Link to="/my-marketplace" className="una-header-button">
            <FaLayerGroup /> {t('marketplace.myProducts')}
          </Link>
        </div>
      </div>
      
      <div className="una-marketplace-filters-container">
        <div className="una-filter-panel">
          <h3><FaFilter /> {t('marketplace.filters')}</h3>
          
          <div className="una-filter-section">
            <h4><FaTags /> {t('marketplace.categories')}</h4>
            <div className="una-categories-list">
              {categories.map(category => (
                <label key={category} className="una-category-checkbox">
                  <input
                    type="checkbox"
                    checked={selectedCategories.includes(category)}
                    onChange={() => handleCategoryChange(category)}
                  />
                  {category}
                </label>
              ))}
            </div>
          </div>
          
          <div className="una-filter-section star-filter">
            <button 
              className={`una-star-toggle ${isFeaturedOnly ? 'active' : ''}`}
              onClick={() => setIsFeaturedOnly(!isFeaturedOnly)}
              aria-label={t('marketplace.featuredOnly')}
              title={t('marketplace.featuredOnly')}
            >
              <FaStar />
            </button>
            <div className="una-star-toggle-label">
              {t('marketplace.featuredOnly')}
            </div>
          </div>
        </div>
        
        <div className="una-marketplace-content">
          <div className="una-marketplace-toolbar">
            <form onSubmit={handleSearchSubmit} className="una-search-container">
              <button type="submit" className="una-search-button">
                <FaSearch />
              </button>
              <input
                type="text"
                placeholder={t('marketplace.searchPlaceholder')}
                className="una-search-input"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </form>
            
            <div className="una-sort-container">
              <label className="una-sort-label">
                <FaSortAmountDown /> {t('marketplace.sortBy')}:
              </label>
              <select 
                className="una-sort-select"
                value={`${sortBy}-${sortOrder}`}
                onChange={handleSortChange}
              >
                <option value="published_at-desc">{t('marketplace.newest')}</option>
                <option value="published_at-asc">{t('marketplace.oldest')}</option>
                <option value="price_min-asc">{t('marketplace.priceLowToHigh')}</option>
                <option value="price_min-desc">{t('marketplace.priceHighToLow')}</option>
                <option value="title-asc">{t('marketplace.titleAZ')}</option>
                <option value="title-desc">{t('marketplace.titleZA')}</option>
              </select>
            </div>
          </div>
          
          {isLoading ? (
            <div className="una-loading-container">
              <div className="una-loading-spinner"></div>
              <div className="una-loading-text">{t('common.loading')}</div>
            </div>
          ) : products.length === 0 ? (
            <div className="una-empty-state">
              <FaExclamationCircle />
              <p>{t('marketplace.noProductsFound')}</p>
            </div>
          ) : (
            <>
              <div className="una-marketplace-table-container">
                <table className="una-marketplace-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>{t('marketplace.product')}</th>
                      <th>{t('marketplace.price')}</th>
                      <th>{t('marketplace.commission')}</th>
                      <th>{t('marketplace.categories')}</th>
                      <th>{t('marketplace.availableUntil')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map(product => (
                      <tr key={product._id}>
                        <td className="una-product-image-cell">
                          {product.images && product.images.length > 0 ? (
                            <img 
                              src={product.images[0].url} 
                              alt={product.title} 
                              className="una-product-thumbnail" 
                            />
                          ) : (
                            <div className="una-product-no-image">
                              <FaStore />
                            </div>
                          )}
                          {product.is_featured && (
                            <span className="una-featured-indicator">
                              <FaStar />
                            </span>
                          )}
                        </td>
                        <td>
                          <div className="una-product-title">{product.title}</div>
                          {product.description && (
                            <div className="una-product-description">
                              {product.description.substring(0, 100)}
                              {product.description.length > 100 ? '...' : ''}
                            </div>
                          )}
                        </td>
                        <td>
                          {product.price_range.min} {product.price_range.currency}
                          {product.price_range.min !== product.price_range.max && 
                            ` - ${product.price_range.max} ${product.price_range.currency}`}
                        </td>
                        <td>
                          <span className="una-commission-rate">
                            <FaPercent /> {product.commission_rate}%
                          </span>
                        </td>
                        <td>
                          <div className="una-table-categories">
                            {product.categories && product.categories.map(category => (
                              <span key={category} className="una-category-pill">
                                <FaTags /> {category}
                              </span>
                            ))}
                          </div>
                        </td>
                        <td>
                          {product.available_until ? (
                            <span className="una-expiry-date">
                              <FaRegClock /> {formatDate(product.available_until)}
                            </span>
                          ) : (
                            <span className="una-no-expiry">{t('marketplace.noExpiration')}</span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              
              <div className="una-pagination-container">
                <div className="una-pagination-info">
                  {t('marketplace.showing')} {(currentPage - 1) * productsPerPage + 1} - {Math.min(currentPage * productsPerPage, totalProducts)} {t('marketplace.of')} {totalProducts}
                </div>
                
                <div className="una-pagination-controls">
                  <button 
                    className="una-pagination-button" 
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <FaChevronLeft />
                  </button>
                  
                  {getPageNumbers().map((pageNum, index) => (
                    <button 
                      key={index}
                      className={`una-pagination-button ${pageNum === currentPage ? 'active' : ''}`}
                      onClick={() => typeof pageNum === 'number' && handlePageChange(pageNum)}
                      disabled={typeof pageNum !== 'number'}
                    >
                      {pageNum}
                    </button>
                  ))}
                  
                  <button 
                    className="una-pagination-button" 
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <FaChevronRight />
                  </button>
                </div>
                
                <div className="una-pagination-per-page">
                  <select 
                    value={productsPerPage} 
                    onChange={e => {
                      setProductsPerPage(Number(e.target.value));
                      setCurrentPage(1);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  <span>{t('marketplace.perPage')}</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Marketplace;