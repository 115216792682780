import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { 
  FaStore, FaExclamationTriangle, FaBoxes, FaTags, 
  FaShoppingCart, FaBarcode, FaBoxOpen, FaPlus,
  FaSearch, FaFilter, FaSortAmountDown, FaEllipsisV,
  FaTag, FaInfoCircle
} from 'react-icons/fa';
import './ProductsList.css';

const ProductList = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isOptimizing, setIsOptimizing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortOption, setSortOption] = useState('title-asc');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = sessionStorage.getItem('access_token');
        const response = await axios.get('/api/shopify/get_products', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProducts(response.data.products);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleCheckboxChange = (productId) => {
    setSelectedProducts(prev => {
      if (prev.includes(productId)) {
        return prev.filter(id => id !== productId);
      } else {
        return [...prev, productId];
      }
    });
  };

  const handleOptimizeClick = async () => {
    if (selectedProducts.length === 0) {
      alert(t('products.selectAtLeastOne'));
      return;
    }

    setIsOptimizing(true);
    try {
      const token = sessionStorage.getItem('access_token');
      
      // Convert numbers to strings
      const productIdsAsStrings = selectedProducts.map(id => id.toString());
      
      const response = await axios.post(
        '/api/shopify/optimize_products', 
        { product_ids: productIdsAsStrings },
        {
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const updatedProducts = await axios.get('/api/shopify/get_products', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProducts(updatedProducts.data.products);
      
      setSelectedProducts([]);
      alert(t('products.optimizeSuccess'));
    } catch (err) {
      console.error('Optimization error details:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status,
        requestData: selectedProducts
      });
      setError(`${t('products.optimizeFailed')}: ${err.message}.`);
    } finally {
      setIsOptimizing(false);
    }
  };

  const formatVariants = (variants) => {
    // Group variants by their options
    const groupedVariants = variants.reduce((acc, variant) => {
      const [size, color] = variant.title.split(' / ');
      if (size) acc.sizes.add(size);
      if (color) acc.colors.add(color);
      return acc;
    }, { sizes: new Set(), colors: new Set() });

    return {
      sizes: Array.from(groupedVariants.sizes),
      colors: Array.from(groupedVariants.colors)
    };
  };

  const hasLowStock = (variants) => {
    return variants.some(variant => variant.inventory_quantity < 10);
  };

  // Add sort functionality
  const sortProducts = (products) => {
    const [field, direction] = sortOption.split('-');
    
    return [...products].sort((a, b) => {
      let comparison = 0;
      
      switch (field) {
        case 'title':
          comparison = a.title.localeCompare(b.title);
          break;
        case 'status':
          comparison = a.status.localeCompare(b.status);
          break;
        case 'type':
          comparison = a.product_type.localeCompare(b.product_type);
          break;
        case 'vendor':
          comparison = a.vendor.localeCompare(b.vendor);
          break;
        case 'inventory':
          comparison = (
            a.variants.reduce((sum, v) => sum + v.inventory_quantity, 0) - 
            b.variants.reduce((sum, v) => sum + v.inventory_quantity, 0)
          );
          break;
        default:
          comparison = 0;
      }
      
      return direction === 'asc' ? comparison : -comparison;
    });
  };

  // Add filter functionality
  const filterProducts = () => {
    let filteredProducts = products;
    
    // Apply search
    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase();
      filteredProducts = filteredProducts.filter(product => 
        product.title.toLowerCase().includes(searchLower) ||
        product.product_type.toLowerCase().includes(searchLower) ||
        product.vendor.toLowerCase().includes(searchLower) ||
        (product.tags && product.tags.some(tag => tag.toLowerCase().includes(searchLower)))
      );
    }
    
    // Apply status filter
    if (filterStatus !== 'all') {
      filteredProducts = filteredProducts.filter(product => product.status === filterStatus);
    }
    
    // Apply sort
    return sortProducts(filteredProducts);
  };

  const displayedProducts = filterProducts();
  const getStockStatus = (variants) => {
    const totalInventory = variants.reduce((sum, v) => sum + v.inventory_quantity, 0);
    if (totalInventory <= 0) return 'out-of-stock';
    if (totalInventory < 10) return 'low-stock';
    return 'in-stock';
  };

  if (error) return <div className="una-product-error">{t('common.error')}: {error}</div>;

  if (isLoading) {
    return (
      <div className="una-dashboard-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
          <h2>{t('products.loadingTitle')}</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaStore />
              <span>{t('products.fetchingStore')}</span>
            </div>
            <div className="una-metric-item">
              <FaBoxOpen />
              <span>{t('products.processingInventory')}</span>
            </div>
            <div className="una-metric-item">
              <FaBarcode />
              <span>{t('products.loadingVariants')}</span>
            </div>
            <div className="una-metric-item">
              <FaTags />
              <span>{t('products.processingTags')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="una-products-container">
      <div className="una-products-header">
        <div className="una-products-title">
          <FaStore />
          <h1>{t('products.title')}</h1>
          <span className="una-products-count">{products.length} {t('products.itemsCount')}</span>
        </div>
        
        <div className="una-products-actions">
          <button 
            className={`una-optimize-button ${selectedProducts.length > 0 ? 'active' : ''}`}
            onClick={handleOptimizeClick}
            disabled={isOptimizing || selectedProducts.length === 0}
          >
            {isOptimizing ? (
              <>
                <div className="una-spinner"></div>
                {t('products.optimizing')}
              </>
            ) : (
              <>
                <FaTag />
                {t('products.optimizeSelected')} {selectedProducts.length > 0 && `(${selectedProducts.length})`}
              </>
            )}
          </button>
          <button 
            className="una-create-button"
            onClick={() => navigate('/products/create')}
          >
            <FaPlus /> {t('products.create')}
          </button>
        </div>
      </div>

      <div className="una-products-toolbar">
        <div className="una-search-wrapper">
          <FaSearch className="una-search-icon" />
          <input
            type="text"
            className="una-search-input"
            placeholder={t('products.searchPlaceholder')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {searchTerm && (
            <button className="una-clear-search" onClick={() => setSearchTerm('')}>
              ×
            </button>
          )}
        </div>
        
        <div className="una-filters">
          <div className="una-filter-group">
            <label className="una-filter-label">
              <FaFilter /> {t('products.filter')}
            </label>
            <select 
              className="una-filter-select"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="all">{t('products.allStatuses')}</option>
              <option value="active">{t('products.active')}</option>
              <option value="draft">{t('products.draft')}</option>
              <option value="archived">{t('products.archived')}</option>
            </select>
          </div>
          
          <div className="una-filter-group">
            <label className="una-filter-label">
              <FaSortAmountDown /> {t('products.sort')}
            </label>
            <select 
              className="una-filter-select"
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
            >
              <option value="title-asc">{t('products.sortTitleAsc')}</option>
              <option value="title-desc">{t('products.sortTitleDesc')}</option>
              <option value="status-asc">{t('products.sortStatusAsc')}</option>
              <option value="type-asc">{t('products.sortTypeAsc')}</option>
              <option value="inventory-desc">{t('products.sortInventoryDesc')}</option>
              <option value="inventory-asc">{t('products.sortInventoryAsc')}</option>
            </select>
          </div>
          
          <div className="una-selected-info">
            {selectedProducts.length > 0 && (
              <span>
                {selectedProducts.length} {t('products.selected')}
                <button 
                  className="una-clear-selection"
                  onClick={() => setSelectedProducts([])}
                >
                  {t('products.clearSelection')}
                </button>
              </span>
            )}
          </div>
        </div>
      </div>

      {!displayedProducts.length ? (
        <div className="una-products-empty-state">
          <FaStore className="empty-icon" />
          <p>
            {searchTerm || filterStatus !== 'all' 
              ? t('products.noProductsFiltered') 
              : t('products.noProducts')
            }
          </p>
          {(searchTerm || filterStatus !== 'all') && (
            <button 
              className="una-clear-filters"
              onClick={() => {
                setSearchTerm('');
                setFilterStatus('all');
              }}
            >
              {t('products.clearFilters')}
            </button>
          )}
        </div>
      ) : (
        <div className="una-products-grid">
          {displayedProducts.map((product) => {
            const { sizes, colors } = formatVariants(product.variants);
            const stockStatus = getStockStatus(product.variants);
            const totalInventory = product.variants.reduce((sum, v) => sum + v.inventory_quantity, 0);
            
            return (
              <div key={product.id} className={`una-product-card ${stockStatus}`}>
                <div className="una-product-checkbox-wrapper">
                  <input
                    type="checkbox"
                    className="una-product-checkbox"
                    checked={selectedProducts.includes(product.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleCheckboxChange(product.id);
                    }}
                    aria-label={`Select ${product.title}`}
                  />
                </div>

                <div className="una-product-content" onClick={() => handleProductClick(product.id)}>
                  <div className="una-product-image-wrapper">
                    {product.images.length > 0 ? (
                      <img 
                        src={product.images[0].src} 
                        alt={product.title}
                        className="una-product-image"
                        loading="lazy"
                      />
                    ) : (
                      <div className="una-product-no-image">
                        <FaBoxOpen />
                        <span>{t('products.noImage')}</span>
                      </div>
                    )}
                    <div className="una-product-status-badge">
                      {product.status}
                    </div>
                  </div>

                  <div className="una-product-details">
                    <div className="una-product-header">
                      <h3 className="una-product-title">
                        {product.title}
                      </h3>
                      <div className={`una-stock-indicator ${stockStatus}`}>
                        {stockStatus === 'out-of-stock' && (
                          <span><FaExclamationTriangle /> {t('products.outOfStock')}</span>
                        )}
                        {stockStatus === 'low-stock' && (
                          <span><FaExclamationTriangle /> {t('products.lowStock')}</span>
                        )}
                        {stockStatus === 'in-stock' && (
                          <span>{totalInventory} {t('products.inStock')}</span>
                        )}
                      </div>
                    </div>

                    <div className="una-product-meta">
                      {product.product_type && (
                        <span className="una-product-type">{product.product_type}</span>
                      )}
                      {product.vendor && (
                        <span className="una-product-vendor">{product.vendor}</span>
                      )}
                    </div>

                    {(sizes.length > 0 || colors.length > 0) && (
                      <div className="una-variants-summary">
                        {sizes.length > 0 && (
                          <div className="una-variant-group">
                            <span className="una-variant-label">{t('products.sizes')}</span>
                            <div className="una-variant-pills">
                              {sizes.length <= 3 ? (
                                sizes.map((size, i) => (
                                  <span key={i} className="una-variant-pill">{size}</span>
                                ))
                              ) : (
                                <>
                                  {sizes.slice(0, 2).map((size, i) => (
                                    <span key={i} className="una-variant-pill">{size}</span>
                                  ))}
                                  <span className="una-variant-pill more">+{sizes.length - 2}</span>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                        
                        {colors.length > 0 && (
                          <div className="una-variant-group">
                            <span className="una-variant-label">{t('products.colors')}</span>
                            <div className="una-variant-pills">
                              {colors.length <= 3 ? (
                                colors.map((color, i) => (
                                  <span key={i} className="una-variant-pill">{color}</span>
                                ))
                              ) : (
                                <>
                                  {colors.slice(0, 2).map((color, i) => (
                                    <span key={i} className="una-variant-pill">{color}</span>
                                  ))}
                                  <span className="una-variant-pill more">+{colors.length - 2}</span>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {Array.isArray(product.tags) && product.tags.length > 0 && (
                      <div className="una-product-tags">
                        {product.tags.slice(0, 3).map((tag, index) => (
                          <span key={index} className="una-product-tag">
                            {tag.trim()}
                          </span>
                        ))}
                        {product.tags.length > 3 && (
                          <span className="una-product-tag more">
                            +{product.tags.length - 3}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  
                  <div className="una-product-action-overlay">
                    <div className="una-action-buttons">
                      <button className="una-action-button primary">
                        {t('products.edit')}
                      </button>
                      <button className="una-action-button">
                        <FaEllipsisV />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      
      {displayedProducts.length > 0 && (
        <div className="una-products-footer">
          <span className="una-showing-count">
            {t('products.showing')} {displayedProducts.length} {t('products.of')} {products.length} {t('products.items')}
          </span>
          {displayedProducts.length !== products.length && (
            <button 
              className="una-clear-filters-btn"
              onClick={() => {
                setSearchTerm('');
                setFilterStatus('all');
              }}
            >
              {t('products.clearFilters')}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductList;