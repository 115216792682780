import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaStore, FaPlus, FaImage, FaTags } from 'react-icons/fa';
import './CreateProduct.css';

const CreateProduct = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const [productData, setProductData] = useState({
    title: '',
    body_html: '',
    vendor: '',
    product_type: '',
    tags: [],
    status: 'ACTIVE',
    options: [{
      name: 'Size',
      values: []
    }],
    variants: [{
      title: 'Default Variant',
      price: 0,
      compare_at_price: null,
      sku: '',
      barcode: '',
      inventory_quantity: 0,
      inventory_policy: 'DENY',
      inventory_management: 'shopify',
      weight: null,
      weight_unit: 'KILOGRAMS',
      requires_shipping: true,
      taxable: true,
      option1: null,
      option2: null,
      option3: null,
      image_url: null
    }],
    images: [],
    seo_title: '',
    seo_description: '',
    published: true,
    template_suffix: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const token = sessionStorage.getItem('access_token');
      
      // Detailed request logging
      console.log('=== REQUEST DATA START ===');
      console.log('Product Data:', productData);
      console.log('Variants:', productData.variants);
      console.log('First Variant Details:', productData.variants[0]);
      console.log('JSON being sent:', JSON.stringify(productData, null, 2));
      console.log('=== REQUEST DATA END ===');

      const response = await axios.post(
        '/api/shopify/create_product',
        productData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      navigate('/products');
    } catch (err) {
      // Detailed error logging
      console.log('=== ERROR RESPONSE START ===');
      console.log('Full error object:', err);
      console.log('Error response data:', err.response?.data);
      console.log('Error response status:', err.response?.status);
      console.log('Error response headers:', err.response?.headers);
      console.log('Error message:', err.message);
      console.log('=== ERROR RESPONSE END ===');
      
      setError(err.response?.data?.detail || err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVariantChange = (index, field, value) => {
    setProductData(prev => ({
      ...prev,
      variants: prev.variants.map((variant, i) => {
        if (i === index) {
          let processedValue = value;
          
          // Handle numeric fields
          if (field === 'price') {
            processedValue = parseFloat(value) || 0;
          } else if (field === 'inventory_quantity') {
            processedValue = parseInt(value) || 0;
          } else if (field === 'weight') {
            processedValue = value === '' ? null : parseFloat(value);
          } else if (field === 'compare_at_price') {
            processedValue = value === '' ? null : parseFloat(value);
          }
          
          return {
            ...variant,
            [field]: processedValue,
            // Ensure title is always set
            title: variant.title || `Variant ${index + 1}`
          };
        }
        return variant;
      })
    }));
  };

  const addVariant = () => {
    setProductData(prev => ({
      ...prev,
      variants: [...prev.variants, {
        title: `Variant ${prev.variants.length + 1}`,
        price: 0,
        compare_at_price: null,
        sku: '',
        barcode: '',
        inventory_quantity: 0,
        inventory_policy: 'DENY',
        inventory_management: 'shopify',
        weight: null,
        weight_unit: 'KILOGRAMS',
        requires_shipping: true,
        taxable: true,
        option1: null,
        option2: null,
        option3: null,
        image_url: null
      }]
    }));
  };

  const handleImageUpload = async (e) => {
    const files = Array.from(e.target.files);
    const newImages = await Promise.all(
      files.map(async (file, index) => {
        return {
          url: URL.createObjectURL(file),
          alt_text: file.name,
          position: productData.images.length + index + 1
        };
      })
    );

    setProductData(prev => ({
      ...prev,
      images: [...prev.images, ...newImages]
    }));
  };

  return (
    <div className="una-create-product-container">
      <div className="una-create-product-header">
        <FaStore />
        <h2>Create Product</h2>
      </div>

      {error && (
        <div className="una-error-message">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="una-create-product-form">
        <div className="una-form-section">
          <h3>Basic Information</h3>
          <input
            type="text"
            placeholder="Product Title"
            value={productData.title}
            onChange={(e) => setProductData(prev => ({ ...prev, title: e.target.value }))}
            required
          />
          
          <textarea
            placeholder="Product Description"
            value={productData.body_html}
            onChange={(e) => setProductData(prev => ({ ...prev, body_html: e.target.value }))}
          />

          <input
            type="text"
            placeholder="Vendor"
            value={productData.vendor}
            onChange={(e) => setProductData(prev => ({ ...prev, vendor: e.target.value }))}
          />

          <input
            type="text"
            placeholder="Product Type"
            value={productData.product_type}
            onChange={(e) => setProductData(prev => ({ ...prev, product_type: e.target.value }))}
          />
        </div>

        <div className="una-form-section">
          <h3>Variants</h3>
          {productData.variants.map((variant, index) => (
            <div key={index} className="una-variant-inputs">
              <input
                type="number"
                placeholder="Price"
                value={variant.price}
                onChange={(e) => handleVariantChange(index, 'price', e.target.value)}
                required
              />
              <input
                type="number"
                placeholder="Inventory"
                value={variant.inventory_quantity}
                onChange={(e) => handleVariantChange(index, 'inventory_quantity', parseInt(e.target.value))}
              />
              <input
                type="text"
                placeholder="SKU"
                value={variant.sku}
                onChange={(e) => handleVariantChange(index, 'sku', e.target.value)}
              />
            </div>
          ))}
          <button type="button" onClick={addVariant} className="una-add-variant-button">
            <FaPlus /> Add Variant
          </button>
        </div>

        <div className="una-form-section">
          <h3>Images</h3>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handleImageUpload}
            className="una-file-input"
          />
          <div className="una-image-preview">
            {productData.images.map((image, index) => (
              <img key={index} src={image.url} alt={image.alt_text} className="una-preview-image" />
            ))}
          </div>
        </div>

        <button 
          type="submit" 
          className="una-submit-button" 
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Create Product'}
        </button>
      </form>
    </div>
  );
};

export default CreateProduct;
