import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaTruck, FaSearch, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import './LiveBuyForm.css';

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePhone = (phone) => {
  const phoneRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
  return phoneRegex.test(phone);
};

const validateZip = (zip) => {
  const zipRegex = /^[0-9]{5}$/;
  return zipRegex.test(zip);
};

const LiveBuyForm = () => {
  const { publicToken } = useParams();
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [step, setStep] = useState(1);

  const [customerData, setCustomerData] = useState(() => {
    const savedData = localStorage.getItem('shippingInformation');
    return savedData ? JSON.parse(savedData) : {
      email: '',
      shipping_address: {
        first_name: '',
        last_name: '',
        address1: '',
        address2: '',
        city: '',
        country: 'France',
        state: '',
        zip: '',
        phone: ''
      },
      click_and_collect: false,
      is_relay: false,
      relay_id: null
    };
  });

  const [validationErrors, setValidationErrors] = useState({
    email: '',
    phone: '',
    zip: ''
  });

  const [paymentIntent, setPaymentIntent] = useState(null);
  const [stripePublicKey, setStripePublicKey] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [paymentMessage, setPaymentMessage] = useState('');

  const [relayPoints, setRelayPoints] = useState([]);
  const [loadingRelayPoints, setLoadingRelayPoints] = useState(false);
  const [relayError, setRelayError] = useState(null);
  const [selectedRelay, setSelectedRelay] = useState(null);

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axios.get(`/api/shopify/public/form/${publicToken}`);
        setFormData(response.data);
      } catch (err) {
        setError(err.response?.data?.detail || 'Failed to load form');
      } finally {
        setLoading(false);
      }
    };

    fetchFormData();
  }, [publicToken]);

  useEffect(() => {
    if (!formData || !searchQuery.trim()) {
      setSearchResults([]);
      return;
    }
    
    const filtered = formData.products.filter(product =>
      product.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(filtered);
  }, [searchQuery, formData]);

  // Update the selectedVariants whenever selectedProducts change
  useEffect(() => {
    const variants = selectedProducts
      .filter(p => p.selectedVariant)
      .map(p => ({
        variant_id: p.selectedVariant.id,
        quantity: p.quantity
      }));
    setSelectedVariants(variants);
  }, [selectedProducts]);

  useEffect(() => {
    if (stripePublicKey && !stripe && window.Stripe) {
      console.log('Initializing Stripe with key:', stripePublicKey);
      const stripeInstance = window.Stripe(stripePublicKey);
      setStripe(stripeInstance);
    }
  }, [stripePublicKey, stripe]);

  const handleProductSelect = (product) => {
    if (!selectedProducts.find(p => p.id === product.id)) {
      setSelectedProducts([...selectedProducts, {
        ...product,
        selectedVariant: null,
        quantity: 1
      }]);
    }
    setSearchQuery('');
    setShowSearch(false);
  };

  const handleVariantSelect = (productId, variant) => {
    setSelectedProducts(prev => prev.map(p => 
      p.id === productId ? { ...p, selectedVariant: variant } : p
    ));
  };

  const handleQuantityChange = (productId, change) => {
    setSelectedProducts(prev => prev.map(p => {
      if (p.id === productId) {
        const newQuantity = Math.max(1, p.quantity + change);
        return { ...p, quantity: newQuantity };
      }
      return p;
    }));
  };

  const handleCustomerDataChange = (field, value) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setCustomerData(prev => {
        const newData = {
          ...prev,
          [parent]: { ...prev[parent], [child]: value }
        };
        
        // If turning off relay, clear relay_id
        if (field === 'is_relay' && !value) {
          newData.relay_id = null;
          setSelectedRelay(null);
        }
        
        // If turning on relay, turn off click_and_collect
        if (field === 'is_relay' && value) {
          newData.click_and_collect = false;
        }
        
        // If turning on click_and_collect, turn off is_relay
        if (field === 'click_and_collect' && value) {
          newData.is_relay = false;
          newData.relay_id = null;
          setSelectedRelay(null);
        }
        
        localStorage.setItem('shippingInformation', JSON.stringify(newData));
        return newData;
      });
    } else {
      setCustomerData(prev => {
        const newData = { ...prev, [field]: value };
        
        // If turning off relay, clear relay_id
        if (field === 'is_relay' && !value) {
          newData.relay_id = null;
          setSelectedRelay(null);
        }
        
        // If turning on relay, turn off click_and_collect
        if (field === 'is_relay' && value) {
          newData.click_and_collect = false;
        }
        
        // If turning on click_and_collect, turn off is_relay
        if (field === 'click_and_collect' && value) {
          newData.is_relay = false;
          newData.relay_id = null;
          setSelectedRelay(null);
        }
        
        localStorage.setItem('shippingInformation', JSON.stringify(newData));
        return newData;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate all fields before submission
    const errors = {
      email: validateEmail(customerData.email) ? '' : 'Format email invalide',
      phone: validatePhone(customerData.shipping_address.phone) ? '' : 'Format téléphone invalide',
      zip: validateZip(customerData.shipping_address.zip) ? '' : 'Code postal invalide'
    };

    setValidationErrors(errors);

    if (Object.values(errors).some(error => error) || selectedVariants.length === 0) {
      return;
    }

    // If is_relay is true, relay_id is required
    if (customerData.is_relay && !customerData.relay_id) {
      setValidationErrors(prev => ({...prev, relay: 'Veuillez sélectionner un point relais'}));
      return;
    }

    try {
      // First, prepare payment data
      const preparePaymentData = {
        list_id: formData.list_id,
        variants: selectedVariants,
        customer_email: customerData.email,
        shipping_address: {
          name: `${customerData.shipping_address.first_name} ${customerData.shipping_address.last_name}`,
          line1: customerData.shipping_address.address1,
          line2: customerData.shipping_address.address2 || '',
          city: customerData.shipping_address.city,
          state: customerData.shipping_address.state || '',
          postal_code: customerData.shipping_address.zip,
          country: customerData.shipping_address.country
        }
      };

      console.log('Preparing payment with data:', preparePaymentData);
      
      const prepareResponse = await axios.post(`/api/shopify/public/form/${publicToken}/prepare_payment`, preparePaymentData);

      if (!prepareResponse.data || !prepareResponse.data.payment_intent_id || !prepareResponse.data.stripe_public_key) {
        throw new Error('Invalid prepare payment response: Missing required data');
      }

      console.log('Payment preparation successful:', prepareResponse.data);

      // Set Stripe public key first
      setStripePublicKey(prepareResponse.data.stripe_public_key);

      // Wait for stripe to be initialized
      if (!window.Stripe) {
        throw new Error('Stripe.js not loaded');
      }

      let stripeInstance = stripe;
      if (!stripeInstance) {
        stripeInstance = window.Stripe(prepareResponse.data.stripe_public_key);
        setStripe(stripeInstance);
      }

      setPaymentIntent(prepareResponse.data.payment_intent_id);

      // Create payment intent with shipping address and relay info
      const paymentIntentData = {
        payment_intent_id: prepareResponse.data.payment_intent_id,
        shipping_address: {
          name: `${customerData.shipping_address.first_name} ${customerData.shipping_address.last_name}`,
          line1: customerData.shipping_address.address1,
          line2: customerData.shipping_address.address2 || '',
          city: customerData.shipping_address.city,
          state: customerData.shipping_address.state || '',
          postal_code: customerData.shipping_address.zip,
          country: customerData.shipping_address.country
        },
        click_and_collect: customerData.click_and_collect,
        is_relay: customerData.is_relay,
        relay_id: customerData.relay_id
      };

      console.log('Creating payment intent with data:', paymentIntentData);

      const paymentIntentResponse = await axios.post(
        `/api/shopify/public/form/${publicToken}/create_payment_intent`,
        paymentIntentData
      );

      // Handle click and collect differently
      if (paymentIntentResponse.data.click_and_collect) {
        setPaymentStatus('success');
        setPaymentMessage('Your order has been placed for in-store pickup. Thank you!');
        
        // Reset form after 3 seconds
        setTimeout(() => {
          setPaymentStatus(null);
          setPaymentMessage('');
          setPaymentIntent(null);
          setSelectedProducts([]);
          setStep(1);
        }, 3000);
        
        return;
      }

      // Continue with regular Stripe payment flow
      if (!paymentIntentResponse.data || !paymentIntentResponse.data.client_secret) {
        throw new Error('Invalid payment intent response: Missing client_secret');
      }

      // Initialize payment element
      const options = {
        clientSecret: paymentIntentResponse.data.client_secret,
        appearance: {
          theme: 'stripe'
        }
      };

      const elementsInstance = stripeInstance.elements(options);
      if (!elementsInstance) {
        throw new Error('Failed to create Stripe elements instance');
      }

      const paymentElement = elementsInstance.create('payment');
      if (!paymentElement) {
        throw new Error('Failed to create payment element');
      }

      paymentElement.mount('#payment-element');
      setElements(elementsInstance);

    } catch (err) {
      console.error('Payment preparation error:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status,
        fullError: err
      });
      setError(
        err.response?.data?.detail || 
        err.message || 
        'Failed to prepare payment. Please check the console for more details.'
      );
    }
  };

  const handlePaymentConfirmation = async () => {
    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    setPaymentError(null);
    setPaymentStatus(null);
    setPaymentMessage('');

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (error) {
        setPaymentStatus('failed');
        setPaymentMessage(error.message);
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        setPaymentStatus('success');
        setPaymentMessage('Payment successful! Thank you for your purchase.');
        
        // Reset form and redirect after 3 seconds
        setTimeout(() => {
          setPaymentStatus(null);
          setPaymentMessage('');
          setPaymentIntent(null);
          setSelectedProducts([]);
          setStep(1);
        }, 3000);
      }
    } catch (err) {
      setPaymentStatus('failed');
      setPaymentMessage('An unexpected error occurred.');
    } finally {
      setProcessing(false);
    }
  };

  const renderVariantOptions = (product) => {
    // Get unique colors and sizes
    const variants = product.variants;
    const colors = [...new Set(variants.map(v => v.title.split(' / ')[0]))];
    const sizes = [...new Set(variants.map(v => v.title.split(' / ')[1]).filter(Boolean))];

    return (
      <div className="live-buy-variants">
        <div className="live-buy-variant-colors">
          <h4 className="live-buy-variant-title">Color:</h4>
          <div className="live-buy-variant-options">
            {colors.map(color => {
              const variantWithColor = variants.find(v => v.title.startsWith(color));
              return (
                <button
                  key={color}
                  className={`live-buy-variant-button ${
                    product.selectedVariant?.title.startsWith(color) ? 'selected' : ''
                  }`}
                  onClick={() => handleVariantSelect(product.id, variantWithColor)}
                  disabled={!variantWithColor.available}
                >
                  {color}
                </button>
              );
            })}
          </div>
        </div>

        {sizes.length > 0 && (
          <div className="live-buy-variant-sizes">
            <h4 className="live-buy-variant-title">Size:</h4>
            <div className="live-buy-variant-options">
              {sizes.map(size => {
                const selectedColor = product.selectedVariant?.title.split(' / ')[0] || colors[0];
                const variantWithSize = variants.find(v => v.title === `${selectedColor} / ${size}`);
                return (
                  <button
                    key={size}
                    className={`live-buy-variant-button ${
                      product.selectedVariant?.title.endsWith(size) ? 'selected' : ''
                    }`}
                    onClick={() => handleVariantSelect(product.id, variantWithSize)}
                    disabled={!variantWithSize?.available}
                  >
                    {size}
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  // Add a new function to fetch relay points
  const fetchRelayPoints = async (zipCode) => {
    if (!zipCode || !validateZip(zipCode)) return;
    
    setLoadingRelayPoints(true);
    setRelayError(null);
    
    try {
      const response = await axios.get(
        `/api/shopify/public/form/${publicToken}/relay_points/${zipCode}`,
        {
          params: {
            carrier: 'mondialrelay',
            country: 'FR'
          }
        }
      );
      
      const relays = response.data.relays || [];
      console.log('Fetched relay points:', relays);
      
      // Log the structure of the first relay for debugging
      if (relays.length > 0) {
        console.log('First relay point structure:', relays[0]);
      }
      
      setRelayPoints(relays);
      
      // Clear selected relay when zip code changes
      if (selectedRelay) {
        setSelectedRelay(null);
        handleCustomerDataChange('relay_id', null);
      }
    } catch (err) {
      console.error('Error fetching relay points:', err);
      setRelayError(err.response?.data?.detail || 'Failed to fetch relay points');
      setRelayPoints([]);
    } finally {
      setLoadingRelayPoints(false);
    }
  };

  // Add effect to fetch relay points when zip code changes and is_relay is true
  useEffect(() => {
    if (customerData.is_relay && customerData.shipping_address.zip) {
      fetchRelayPoints(customerData.shipping_address.zip);
    }
  }, [customerData.shipping_address.zip, customerData.is_relay, publicToken]);

  // Fix the handleRelaySelect function
  const handleRelaySelect = (relay) => {
    setSelectedRelay(relay);
    // The issue is here - the relay points API might return 'id' or possibly another property name
    // Let's make it more robust by checking which property exists
    const relayId = relay.id || relay.ID || relay.relayId || relay.relay_id;
    
    if (relayId) {
      handleCustomerDataChange('relay_id', relayId);
      console.log(`Selected relay point: ${relay.name} with ID: ${relayId}`);
    } else {
      console.error('Could not determine relay ID from:', relay);
    }
  };

  if (loading) return <div className="live-buy-loading">Loading...</div>;
  if (error) return (
    <div className="live-buy-wrapper">
      <div className="live-buy-container">
        <div className="live-buy-header">
          <h1>
            {error === 'Form not found or inactive' ? "This Live hasn't started yet" : error}
          </h1>
        </div>
      </div>
    </div>
  );

  return (
    <div className="live-buy-wrapper">
      <div className="live-buy-container">
        <div className="live-buy-header">
          <h1>{formData.list_name}</h1>
          {formData.description && <p>{formData.description}</p>}
        </div>

        {step === 1 && (
          <div className="live-buy-step-indicator">
            <span className="active">1. Products</span>
            <span onClick={() => setStep(2)}>2. Shipping</span>
          </div>
        )}
        {step === 2 && (
          <div className="live-buy-step-indicator">
            <span onClick={() => setStep(1)}>1. Products</span>
            <span className="active">2. Shipping</span>
          </div>
        )}

        {step === 1 ? (
          <>
            <div className="live-buy-search-container">
              <div className="live-buy-search-wrapper">
                <FaSearch className="live-buy-search-icon" />
                <input
                  type="text"
                  className="live-buy-search-input"
                  placeholder="Search products..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onFocus={() => setShowSearch(true)}
                />
              </div>
              
              {showSearch && searchResults.length > 0 && (
                <div className="live-buy-search-results">
                  {searchResults.map(product => (
                    <div
                      key={product.id}
                      className="live-buy-search-item"
                      onClick={() => handleProductSelect(product)}
                    >
                      {product.images?.[0] && (
                        <img
                          src={product.images[0].url}
                          alt={product.title}
                          className="live-buy-search-item-image"
                        />
                      )}
                      <div className="live-buy-search-item-info">
                        <span className="live-buy-search-item-title">{product.title}</span>
                        <span className="live-buy-search-item-price">${product.price}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="live-buy-selected-products">
              {selectedProducts.map(product => (
                <div key={product.id} className="live-buy-product">
                  <div className="live-buy-product-header">
                    <div className="live-buy-product-info">
                      <img
                        src={product.images[0]?.url}
                        alt={product.title}
                        className="live-buy-product-image"
                      />
                      <h3 className="live-buy-product-title">{product.title}</h3>
                    </div>
                    <button
                      className="live-buy-remove"
                      onClick={() => setSelectedProducts(prev => 
                        prev.filter(p => p.id !== product.id)
                      )}
                    >
                      &times;
                    </button>
                  </div>

                  {renderVariantOptions(product)}
                </div>
              ))}
            </div>

            {selectedProducts.length > 0 && (
              <button 
                className="live-buy-button live-buy-button-primary"
                onClick={() => setStep(2)}
              >
                Continue to Shipping
              </button>
            )}
          </>
        ) : (
          <form onSubmit={handleSubmit} className="live-buy-customer">
            <h2 className="live-buy-customer-title">Shipping Information</h2>
            
            <div className="live-buy-form-group">
              <label className="live-buy-label">Email</label>
              <input
                type="email"
                className="live-buy-input"
                value={customerData.email}
                onChange={(e) => handleCustomerDataChange('email', e.target.value)}
                required
              />
              {validationErrors.email && <div className="live-buy-error">{validationErrors.email}</div>}
            </div>
            
            <div className="live-buy-form-group">
              <label className="live-buy-label">First Name</label>
              <input
                type="text"
                className="live-buy-input"
                value={customerData.shipping_address.first_name}
                onChange={(e) => handleCustomerDataChange('shipping_address.first_name', e.target.value)}
                required
              />
            </div>
            
            <div className="live-buy-form-group">
              <label className="live-buy-label">Last Name</label>
              <input
                type="text"
                className="live-buy-input"
                value={customerData.shipping_address.last_name}
                onChange={(e) => handleCustomerDataChange('shipping_address.last_name', e.target.value)}
                required
              />
            </div>
            
            <div className="live-buy-form-group">
              <label className="live-buy-label">Phone</label>
              <input
                type="text"
                className="live-buy-input"
                value={customerData.shipping_address.phone}
                onChange={(e) => handleCustomerDataChange('shipping_address.phone', e.target.value)}
                required
              />
              {validationErrors.phone && <div className="live-buy-error">{validationErrors.phone}</div>}
            </div>

            {/* Delivery options */}
            <div className="live-buy-delivery-options">
              <h3 className="live-buy-delivery-title">Delivery Options</h3>
              
              <div className="live-buy-delivery-option">
                <input
                  type="radio"
                  id="home-delivery"
                  name="delivery-option"
                  checked={!customerData.click_and_collect && !customerData.is_relay}
                  onChange={() => {
                    handleCustomerDataChange('click_and_collect', false);
                    handleCustomerDataChange('is_relay', false);
                  }}
                />
                <label htmlFor="home-delivery">Home Delivery</label>
              </div>
              
              <div className="live-buy-delivery-option">
                <input
                  type="radio"
                  id="click-and-collect"
                  name="delivery-option"
                  checked={customerData.click_and_collect}
                  onChange={() => handleCustomerDataChange('click_and_collect', true)}
                />
                <label htmlFor="click-and-collect">Click and Collect</label>
              </div>
              
              <div className="live-buy-delivery-option">
                <input
                  type="radio"
                  id="relay-point"
                  name="delivery-option"
                  checked={customerData.is_relay}
                  onChange={() => handleCustomerDataChange('is_relay', true)}
                />
                <label htmlFor="relay-point">Pickup Point</label>
              </div>
            </div>
            
            {/* Address fields */}
            <div className="live-buy-address-fields">
              <div className="live-buy-form-group">
                <label className="live-buy-label">Address</label>
                <input
                  type="text"
                  className="live-buy-input"
                  value={customerData.shipping_address.address1}
                  onChange={(e) => handleCustomerDataChange('shipping_address.address1', e.target.value)}
                  required
                />
              </div>
              
              <div className="live-buy-form-group">
                <label className="live-buy-label">Address Line 2 (Optional)</label>
                <input
                  type="text"
                  className="live-buy-input"
                  value={customerData.shipping_address.address2}
                  onChange={(e) => handleCustomerDataChange('shipping_address.address2', e.target.value)}
                />
              </div>
              
              <div className="live-buy-form-group">
                <label className="live-buy-label">Postal Code</label>
                <input
                  type="text"
                  className="live-buy-input"
                  value={customerData.shipping_address.zip}
                  onChange={(e) => handleCustomerDataChange('shipping_address.zip', e.target.value)}
                  required
                />
                {validationErrors.zip && <div className="live-buy-error">{validationErrors.zip}</div>}
              </div>
              
              <div className="live-buy-form-group">
                <label className="live-buy-label">City</label>
                <input
                  type="text"
                  className="live-buy-input"
                  value={customerData.shipping_address.city}
                  onChange={(e) => handleCustomerDataChange('shipping_address.city', e.target.value)}
                  required
                />
              </div>
              
              <div className="live-buy-form-group">
                <label className="live-buy-label">Country</label>
                <input
                  type="text"
                  className="live-buy-input"
                  value={customerData.shipping_address.country}
                  onChange={(e) => handleCustomerDataChange('shipping_address.country', e.target.value)}
                  required
                />
              </div>
            </div>
            
            {/* Relay points section - only show when is_relay is true */}
            {customerData.is_relay && (
              <div className="live-buy-relay-section">
                <h3 className="live-buy-relay-title">Select Pickup Point</h3>
                
                {loadingRelayPoints ? (
                  <div className="live-buy-relay-loading">Loading pickup points...</div>
                ) : relayError ? (
                  <div className="live-buy-relay-error">{relayError}</div>
                ) : relayPoints.length > 0 ? (
                  <div className="live-buy-relay-list">
                    {relayPoints.map(relay => (
                      <div 
                        key={relay.id} 
                        className={`live-buy-relay-item ${selectedRelay?.id === relay.id ? 'selected' : ''}`}
                        onClick={() => handleRelaySelect(relay)}
                      >
                        <div className="live-buy-relay-name">{relay.name}</div>
                        <div className="live-buy-relay-address">
                          {relay.address1}
                          {relay.address2 && <span>, {relay.address2}</span>}
                          <br />
                          {relay.postcode} {relay.city}
                        </div>
                        {relay.distance && (
                          <div className="live-buy-relay-distance">
                            {relay.distance} km
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : customerData.shipping_address.zip ? (
                  <div className="live-buy-relay-empty">No pickup points found for this postal code</div>
                ) : (
                  <div className="live-buy-relay-instruction">Enter your postal code to see available pickup points</div>
                )}
                
                {validationErrors.relay && <div className="live-buy-error">{validationErrors.relay}</div>}
              </div>
            )}

            {paymentIntent && (
              <div className="live-buy-payment">
                <div id="payment-element"></div>
                {paymentStatus && (
                  <div className="live-buy-payment-overlay">
                    <div className="live-buy-payment-message">
                      {paymentStatus === 'success' ? (
                        <>
                          <div className="live-buy-payment-icon success">
                            <FaCheckCircle />
                          </div>
                          <p>{paymentMessage}</p>
                        </>
                      ) : (
                        <>
                          <div className="live-buy-payment-icon error">
                            <FaTimesCircle />
                          </div>
                          <p>{paymentMessage || paymentError}</p>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <button 
                  type="button"
                  className="live-buy-button live-buy-button-primary"
                  onClick={handlePaymentConfirmation}
                  disabled={!stripe || !elements || processing || paymentStatus === 'success'}
                >
                  {processing ? 'Processing...' : 'Pay Now'}
                </button>
              </div>
            )}

            <div className="live-buy-footer">
              <div className="live-buy-total">
                <div className="live-buy-shipping">
                  <FaTruck />
                  <span>Shipping: ${formData.shipping_fee.toFixed(2)}</span>
                </div>
                <div className="live-buy-total-amount">
                  Total: ${(
                    selectedProducts.reduce((sum, p) => 
                      sum + ((p.selectedVariant?.price || 0) * p.quantity), 0
                    ) + formData.shipping_fee
                  ).toFixed(2)}
                </div>
              </div>

              <div className="live-buy-actions">
                <button 
                  type="button" 
                  className="live-buy-button live-buy-button-secondary"
                  onClick={() => setStep(1)}
                >
                  Back
                </button>
                {!paymentIntent ? (
                  <button 
                    type="submit" 
                    className="live-buy-button live-buy-button-primary"
                  >
                    Checkout
                  </button>
                ) : null}
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default LiveBuyForm;
