import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './MessageWindow.css';
import { useNavigate, useParams } from 'react-router-dom';
import InfoPanel from './InfoPanel';
import { useTranslation } from 'react-i18next';
import { 
    FaPaperPlane, 
    FaLightbulb, 
    FaCheck, 
    FaTimes, 
    FaLanguage, 
    FaTruck, 
    FaUser, 
    FaRobot, 
    FaEllipsisV, 
    FaEdit,
    FaFile,
    FaTimesCircle,
    FaInfoCircle,
    FaBox,
    FaShippingFast,
    FaCheckCircle,
    FaBarcode,
    FaFilePdf,
    FaFileImage,
    FaFileAlt,
    FaFileArchive,
    FaFileCode,
    FaTag,
    FaWarehouse,
    FaBuilding,
    FaMapMarkerAlt,
    FaCity,
    FaGlobe,
    FaPhone,
    FaEnvelope,
    FaIdCard,
    FaInfo,
    FaMoneyBillWave,
    FaBoxOpen,
    FaShieldAlt,
    FaReceipt,
    FaComments,
    FaHandPaper
} from 'react-icons/fa';
import WMSChat from './WMSChat';
import CarriersConversation from './CarriersConversation';
import ManualConversation from './ManualConversation';

const MessageWindow = () => {
    const { t } = useTranslation();
    const { ticketId } = useParams();
    const [ticket, setTicket] = useState(null);
    const [messages, setMessages] = useState([]);
    const [employee, setEmployee] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [editingMessage, setEditingMessage] = useState(null);
    const [editedContent, setEditedContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [userLoading, setUserLoading] = useState(true);
    const [targetLanguage, setTargetLanguage] = useState('original');
    const [translatedMessages, setTranslatedMessages] = useState({});
    const [customerLoading, setCustomerLoading] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);
    const navigate = useNavigate();
    const [overlayState, setOverlayState] = useState({ show: false, message: '', success: false });
    const [user, setUser] = useState(null);
    const [clientInfo, setClientInfo] = useState('');
    const [orderDetails, setOrderDetails] = useState('');
    const [summary, setSummary] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [isTranslating, setIsTranslating] = useState(false);
    const [carriersConversation, setCarriersConversation] = useState(null);
    const [continuationMessage, setContinuationMessage] = useState('');
    const [testUserId, setTestUserId] = useState('');
    const [testThreadId, setTestThreadId] = useState('');
    const [testAssistantId, setTestAssistantId] = useState('');
    const [validatingMessage, setValidatingMessage] = useState(false);
    const [activeTab, setActiveTab] = useState('carriers');
    const [showActionMenu, setShowActionMenu] = useState(false);
    const [showLanguageMenu, setShowLanguageMenu] = useState(false);
    const actionMenuRef = useRef(null);

    // State for ticket files
    const [ticketFiles, setTicketFiles] = useState([]);
    const [filesLoading, setFilesLoading] = useState(true);
    // State for file overlay
    const [selectedFile, setSelectedFile] = useState(null);

    const wsRef = useRef(null);
    const reconnectTimeoutRef = useRef(null);

    // Add new state for context data
    const [contextData, setContextData] = useState(null);
    const [contextLoading, setContextLoading] = useState(true);

    // Add new state for warehouse email form
    const [warehouseEmailForm, setWarehouseEmailForm] = useState({
        subject: '',
        content: '',
        recipient_email: ''
    });

    // Add new state for niveau2 email form
    const [niveau2EmailForm, setNiveau2EmailForm] = useState({
        subject: '',
        content: '',
        recipient_email: '',
        selectedFiles: []
    });

    // Add new state for file selection
    const [selectedTicketFiles, setSelectedTicketFiles] = useState([]);

    // Add new state for warehouse conversation
    const [warehouseConversation, setWarehouseConversation] = useState(null);
    const [warehouseLoading, setWarehouseLoading] = useState(true);

    const fetchTicketContext = async () => {
        setContextLoading(true);
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get(`/api/tickets/${ticketId}/context`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setContextData(response.data);
        } catch (error) {
            console.error('Failed to fetch ticket context:', error);
        } finally {
            setContextLoading(false);
        }
    };

    const fetchWarehouseConversation = async () => {
        setWarehouseLoading(true);
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get(`/api/tickets/warehouse_conversation/${ticketId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setWarehouseConversation(response.data);
        } catch (error) {
            console.error('Failed to fetch warehouse conversation:', error);
        } finally {
            setWarehouseLoading(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (actionMenuRef.current && !actionMenuRef.current.contains(event.target)) {
                setShowActionMenu(false);
                setShowLanguageMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const fetchCarriersConversation = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get(`/api/tickets/carriers_conversation/${ticketId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setCarriersConversation(response.data);
        } catch (error) {
            console.error('Failed to fetch carriers conversation:', error);
        }
    };

    const connectWebSocket = () => {
        const token = sessionStorage.getItem('access_token');
        if (!token) return;

        wsRef.current = new WebSocket(`/api/ws?token=${token}`);
        
        wsRef.current.onopen = (event) => {
            console.log('WebSocket connection established:', event);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }
        };

        wsRef.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        wsRef.current.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            reconnectTimeoutRef.current = setTimeout(connectWebSocket, 5000);
        };

        wsRef.current.onmessage = (event) => {
            console.log('WebSocket message received:', event.data);
            if (event.data === 'ping') {
                wsRef.current.send('pong');
            } else {
                // Fetch messages only to quickly update the message list
                fetchMessages();
            }
        };
    };

    useEffect(() => {
        fetchUserData();
        fetchTicketAndMessages();
        fetchCarriersConversation();
        fetchTicketFiles();
        fetchTicketContext();
        fetchWarehouseConversation();
        connectWebSocket();

        const pingInterval = setInterval(() => {
            if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
                wsRef.current.send('ping');
            }
        }, 30000);

        return () => {
            clearInterval(pingInterval);
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
            }
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [ticketId]);

    const fetchUserData = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get('/api/users/user', {
                headers: { Authorization: `Bearer ${token}` }
            });
            setUser(response.data);
        } catch (error) {
            console.error('Failed to fetch user data:', error);
        } finally {
            setUserLoading(false);
        }
    };

    const fetchTicketAndMessages = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get(`/api/tickets/${ticketId}/messages`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTicket(response.data.ticket);
            setMessages(response.data.messages);
            setEmployee(response.data.employee);

            // Fetch order and customer info asynchronously
            fetchOrderAndCustomerInfo(response.data.ticket, token);

            setSummary(response.data.ticket.summary || 'Summary information will be fetched/updated here.');
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch ticket and messages:', error);
            setLoading(false);
        }
    };

    const fetchMessages = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get(`/api/tickets/${ticketId}/messages`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessages(response.data.messages);
        } catch (error) {
            console.error('Failed to fetch messages:', error);
        }
    };

    const fetchOrderAndCustomerInfo = async (ticketData, token) => {
        if (ticketData.customer_id) {
            fetchCustomerInfo(ticketData.customer_id, token);
        } else {
            setClientInfo('');
        }

        if (ticketData.order) {
            fetchOrderInfo(ticketData.order, token);
        } else {
            setOrderDetails('');
        }
    };

    // New function to fetch ticket files
    const fetchTicketFiles = async () => {
        setFilesLoading(true);
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.get(`/api/tickets/${ticketId}/files`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setTicketFiles(response.data.files);
        } catch (error) {
            console.error('Failed to fetch ticket files:', error);
        } finally {
            setFilesLoading(false);
        }
    };

    const formatMessageContent = (content) => {
        const lines = content.split('\n');
        return lines.map((line, index) => {
            // Regular expression to match URLs ending with .pdf
            const urlRegex = /(https?:\/\/[^\s]+\.pdf)/gi;
            const parts = line.split(urlRegex);
            
            return (
                <span key={index}>
                    {parts.map((part, partIndex) => {
                        if (part.match(urlRegex)) {
                            // Convert http to https
                            const secureUrl = part.replace('http://', 'https://');
                            return (
                                <div key={partIndex}>
                                    <object
                                        data={secureUrl}
                                        type="application/pdf"
                                        width="100%"
                                        height="500px"
                                    >
                                        <p>Unable to display PDF. <a href={secureUrl}>Download PDF</a></p>
                                    </object>
                                </div>
                            );
                        }
                        return part;
                    })}
                    <br />
                </span>
            );
        });
    };
    
    const fetchCustomerInfo = async (customerId, token) => {
        setCustomerLoading(true);
        try {
            const response = await axios.get(`/api/shopify/get_customer`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { customer_id: customerId }
            });
            const customer = response.data;
            setClientInfo(`Phone: ${customer.phone}\nFirst Name: ${customer.first_name}\nLast Name: ${customer.last_name}\nEmail: ${customer.email}`);
        } catch (error) {
            console.error('Failed to fetch customer info:', error);
        } finally {
            setCustomerLoading(false);
        }
    };

    const fetchOrderInfo = async (orderId, token) => {
        setOrderLoading(true);
        try {
            const response = await axios.get(`/api/logistics/edn/order_by_id/${orderId}`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            const order = response.data;
            const formattedOrderDetails = `
Order ID: ${order.order_id}
Reference: ${order.reference}
Status: ${order.status}
Total Price: ${order.grand_total} ${order.currency}
Shipping Cost: ${order.shipping_cost} ${order.currency}
Created At: ${order.created_at}
Customer Email: ${order.customer_email}
Customer Name: ${order.customer_name}
Customer Phone: ${order.customer_phone}
Shipping Address:
    ${order.shipping_address.firstname} ${order.shipping_address.lastname}
    ${order.shipping_address.street1}
    ${order.shipping_address.street2 ? order.shipping_address.street2 + '\n' : ''}
    ${order.shipping_address.postcode} ${order.shipping_address.city}
    ${order.shipping_address.country}
Line Items:
${order.items.map(item => `
Product ID: ${item.product_id}
Name: ${item.name}
SKU: ${item.sku}
Quantity: ${item.quantity}
Price: ${item.price} ${order.currency}
EAN: ${item.ean || 'N/A'}
`).join('\n')}
`;
            setOrderDetails(formattedOrderDetails);
        } catch (error) {
            console.error('Failed to fetch order info:', error);
        } finally {
            setOrderLoading(false);
        }
    };

    const handleSendMessage = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post('/api/messages/add', {
                ticket_id: ticketId,
                content: newMessage,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setNewMessage('');
            fetchMessages(); // Fetch messages only to update the message list quickly
        } catch (error) {
            console.error('Failed to send message:', error);
        }
    };

    const handleGetAssistantResponse = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            const aiLogsString = JSON.stringify(ticket.ai_logs);

            const response = await axios.post('/api/messages/writer_assistant', {
                text: newMessage,
                ai_logs: aiLogsString,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setNewMessage(response.data.response);
        } catch (error) {
            console.error('Failed to get assistant response:', error);
        }
    };

    const handleResolveTicket = async () => {
        setOverlayState({ show: true, message: t('messageWindow.processing'), success: false });
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(`/api/tickets/${ticketId}/resolve`, null, {
                headers: { Authorization: `Bearer ${token}` },
            });
            await fetchTicketAndMessages(); // Fetch ticket and messages to update the status and messages
            setOverlayState({ show: true, message: t('messageWindow.resolveSuccess'), success: true });
        } catch (error) {
            console.error('Failed to resolve ticket:', error);
            setOverlayState({ show: true, message: t('messageWindow.resolveError'), success: false });
        } finally {
            setTimeout(() => setOverlayState({ show: false, message: '', success: false }), 2000);
        }
    };
    
    const handleCloseTicket = async () => {
        setOverlayState({ show: true, message: t('messageWindow.processing'), success: false });
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.put(`/api/tickets/${ticketId}/close`, null, {
                headers: { Authorization: `Bearer ${token}` },
            });
            await fetchTicketAndMessages(); // Fetch ticket and messages to update the status and messages
            setOverlayState({ show: true, message: t('messageWindow.closeSuccess'), success: true });
        } catch (error) {
            console.error('Failed to close ticket:', error);
            setOverlayState({ show: true, message: t('messageWindow.closeError'), success: false });
        } finally {
            setTimeout(() => setOverlayState({ show: false, message: '', success: false }), 2000);
        }
    };

    const handleSendDraftMessage = async (messageId) => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post(`/api/messages/send`, { message_id: messageId }, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            });
            fetchMessages(); // Fetch messages to update the message list
        } catch (error) {
            console.error('Failed to send draft message:', error);
        }
    };

    const handleDeleteDraftMessage = async (messageId) => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post(`/api/messages/delete`, { message_id: messageId }, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            });
            fetchMessages(); // Fetch messages to update the message list
        } catch (error) {
            console.error('Failed to delete draft message:', error);
        }
    };

    const handleModifyDraftMessage = (message) => {
        setEditingMessage(message);
        setEditedContent(message.content);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const handleSaveModifiedMessage = async () => {
        try {
            const token = sessionStorage.getItem('access_token');
            await axios.post(`/api/messages/edit`, {
                message_id: editingMessage._id,
                content: editedContent,
            }, {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            });
            setEditingMessage(null);
            setEditedContent('');
            fetchMessages(); // Fetch messages to update the message list
        } catch (error) {
            console.error('Failed to modify draft message:', error);
        }
    };

    const handleCancelModification = () => {
        setEditingMessage(null);
        setEditedContent('');
    };

    // File overlay functions
    const handleFileClick = (file) => {
        setSelectedFile(file);
    };

    const closeFileOverlay = () => {
        setSelectedFile(null);
    };

    useEffect(() => {
        if (!userLoading && !user) {
            navigate('/');
        }
    }, [userLoading, user, navigate]);

    if (loading) {
        return <div>{t('messageWindow.loading')}</div>;
    }

    if (!ticket) {
        return <div>{t('messageWindow.failedToLoadTicket')}</div>;
    }

    const isEditable = !['resolved', 'closed', 'ai_resolved'].includes(ticket.status);
    
    const handleLanguageChange = async (language) => {
        setTargetLanguage(language);
        if (language !== 'original') {
            setIsTranslating(true);
            await translateMessages(language);
            setIsTranslating(false);
        } else {
            setTranslatedMessages({});
        }
    };

    const translateMessages = async (language) => {
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.post('/api/messages/translate_messages', {
                messages: messages.map(m => ({ id: m._id, content: m.content })),
                target_language: language
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const translatedObj = {};
            response.data.translated_messages.forEach(m => {
                translatedObj[m.id] = m.content;
            });
            setTranslatedMessages(translatedObj);
        } catch (error) {
            console.error('Failed to translate messages:', error);
        }
    };

    const renderMessageContent = (message) => {
        const content = targetLanguage === 'original' ? message.content : (translatedMessages[message._id] || message.content);
        return formatMessageContent(content);
    };

    const renderMessage = (message) => {
        const isSent = message.sender === 'employee' || message.status === 'sent' || message.status === 'draft';
        const messageClass = `message ${isSent ? 'sent' : 'received'} ${message.status}`;
        
        return (
            <div key={message._id} className={messageClass}>
                {message.status === 'automated' && (
                    <span className="message-status-badge automated">{t('messageWindow.automated')}</span>
                )}
                <div className="message-content">
                    {renderMessageContent(message)}
                </div>
                <div className="message-meta">
                    <span className="message-status">
                        {message.status 
                            ? t(`messageWindow.statusOptions.${message.status}`)
                            : t('messageWindow.statusOptions.received')}
                    </span>
                    <span className="message-timestamp">{formatDate(message.created_at)}</span>
                </div>
                {message.status === 'draft' && isEditable && (
                    <div className="draft-actions">
                        <button className="draft-message-button send" onClick={() => handleSendDraftMessage(message._id)}>
                            <FaPaperPlane /> {t('messageWindow.send')}
                        </button>
                        <button className="draft-message-button delete" onClick={() => handleDeleteDraftMessage(message._id)}>
                            <FaTimes /> {t('messageWindow.delete')}
                        </button>
                        <button className="draft-message-button modify" onClick={() => handleModifyDraftMessage(message)}>
                            <FaEdit /> {t('messageWindow.modify')}
                        </button>
                    </div>
                )}
            </div>
        );
    };

    const handleValidateCarrierMessage = async (conversationId, approved) => {
        setValidatingMessage(true);
        try {
            const token = sessionStorage.getItem('access_token');
            const response = await axios.post('/api/logistics/validate-carrier-message', 
                {
                    conversation_id: conversationId,
                    approved: approved
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            
            // Refresh the carriers conversation after validation
            await fetchCarriersConversation();
            
            setOverlayState({ 
                show: true, 
                message: approved ? t('messageWindow.messageApproved') : t('messageWindow.messageRejected'), 
                success: true 
            });
        } catch (error) {
            console.error('Failed to validate carrier message:', error);
            setOverlayState({ 
                show: true, 
                message: t('messageWindow.validationError'), 
                success: false 
            });
        } finally {
            setValidatingMessage(false);
            setTimeout(() => setOverlayState({ show: false, message: '', success: false }), 2000);
        }
    };

    const getFileIcon = (mimeType) => {
        if (mimeType.includes('pdf')) {
            return <FaFilePdf />;
        } else if (mimeType.includes('image')) {
            return <FaFileImage />;
        } else if (mimeType.includes('zip') || mimeType.includes('rar')) {
            return <FaFileArchive />;
        } else if (mimeType.includes('code') || mimeType.includes('json')) {
            return <FaFileCode />;
        }
        return <FaFileAlt />;
    };

    const getFileClass = (mimeType) => {
        if (mimeType.includes('pdf')) {
            return 'pdf';
        } else if (mimeType.includes('image')) {
            return 'image';
        }
        return 'document';
    };

    // Add this new component for the metadata tooltip
    const FileMetadataTooltip = ({ metadata }) => {
        if (!metadata || Object.keys(metadata).length === 0) return null;
        
        return (
            <div className="file-metadata-content">
                {Object.entries(metadata).map(([key, value]) => (
                    value && (
                        <div key={key} className={`metadata-item metadata-${key}`}>
                            <strong>{t(`messageWindow.metadata.${key}`, key)}:</strong> {value}
                        </div>
                    )
                ))}
            </div>
        );
    };

    // Add handler for warehouse email submission
    const handleWarehouseEmailSubmit = async (e) => {
        e.preventDefault();
        setIsProcessing(true);
        try {
            const token = sessionStorage.getItem('access_token');
            const formData = new FormData();
            formData.append('subject', warehouseEmailForm.subject);
            formData.append('content', warehouseEmailForm.content);
            formData.append('ticket_id', ticketId);
            formData.append('recipient_email', warehouseEmailForm.recipient_email);
            formData.append('asst_id', user.assistant_id);

            const response = await axios.post('/api/webhook/warehouse-email', 
                formData,
                {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                    }
                }
            );

            setOverlayState({
                show: true,
                message: t('messageWindow.warehouseEmailSent'),
                success: true
            });
            
            // Reset form
            setWarehouseEmailForm({
                subject: '',
                content: '',
                recipient_email: ''
            });
            
        } catch (error) {
            console.error('Failed to send warehouse email:', error);
            setOverlayState({
                show: true,
                message: t('messageWindow.warehouseEmailError'),
                success: false
            });
        } finally {
            setIsProcessing(false);
            setTimeout(() => setOverlayState({ show: false, message: '', success: false }), 2000);
        }
    };

    // Add file selection handler
    const handleFileSelection = (fileUrl) => {
        setSelectedTicketFiles(prev => {
            if (prev.includes(fileUrl)) {
                return prev.filter(url => url !== fileUrl);
            } else {
                return [...prev, fileUrl];
            }
        });
    };

    // Add handler for niveau2 email submission
    const handleNiveau2EmailSubmit = async (e) => {
        e.preventDefault();
        setIsProcessing(true);
        try {
            const token = sessionStorage.getItem('access_token');
            const formData = new FormData();
            formData.append('subject', niveau2EmailForm.subject);
            formData.append('content', niveau2EmailForm.content);
            formData.append('ticket_id', ticketId);
            formData.append('recipient_email', niveau2EmailForm.recipient_email);
            formData.append('asst_id', user.assistant_id);
            formData.append('file_urls', JSON.stringify(selectedTicketFiles));

            const response = await axios.post('/api/webhook/niveau2-email', 
                formData,
                {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                    }
                }
            );

            setOverlayState({
                show: true,
                message: t('messageWindow.niveau2EmailSent'),
                success: true
            });
            
            // Reset form and selected files
            setNiveau2EmailForm({
                subject: '',
                content: '',
                recipient_email: '',
                selectedFiles: []
            });
            setSelectedTicketFiles([]);
            
        } catch (error) {
            console.error('Failed to send niveau2 email:', error);
            setOverlayState({
                show: true,
                message: t('messageWindow.niveau2EmailError'),
                success: false
            });
        } finally {
            setIsProcessing(false);
            setTimeout(() => setOverlayState({ show: false, message: '', success: false }), 2000);
        }
    };

    // Update this component for rendering warehouse messages
    const WarehouseConversation = ({ conversation }) => {
        if (!conversation || !conversation.messages || conversation.messages.length === 0) {
            return <div className="empty-conversation">{t('messageWindow.noWarehouseMessages')}</div>;
        }

        return (
            <div className="warehouse-conversation">
                <div className="warehouse-conversation-tracking">
                    <span className="tracking-badge warehouse">
                        <FaWarehouse /> {t('messageWindow.warehouseConversation')}
                    </span>
                </div>
                
                <div className="warehouse-conversation-content">
                    <div className="warehouse-conversation-messages">
                        {conversation.messages.map((message, index) => (
                            <div 
                                key={index} 
                                className={`warehouse-conversation-message warehouse-conversation-message--${message.sender_type === 'warehouse' ? 'received' : 'sent'}`}
                            >
                                <div className="warehouse-conversation-message__sender">
                                    {message.direction === 'final_response' 
                                        ? t('messageWindow.warehouse') 
                                        : t('messageWindow.us')}
                                </div>
                                <div className="warehouse-conversation-message__content">
                                    <div className="warehouse-response">
                                        <div className="warehouse-response__details-content">
                                            {formatMessageContent(message.content)}
                                        </div>
                                    </div>
                                </div>
                                <div className="warehouse-conversation-message__meta">
                                    <span className="warehouse-conversation-message__timestamp">
                                        {formatDate(message.timestamp)}
                                    </span>
                                    <span className="warehouse-conversation-message__status">
                                        {message.status || t('messageWindow.statusOptions.sent')}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="warehouse-refresh">
                    <button onClick={fetchWarehouseConversation} className="refresh-button">
                        {t('messageWindow.refreshConversation')}
                    </button>
                </div>
            </div>
        );
    };

    return (
        <div className="app-container">
            <div className="message-window-layout">
                <div className="ticket-header">
                    <div className="ticket-title-row">
                        <div className="ticket-title-container">
                            <h2>{ticket.subject}</h2>
                        </div>
                        <div className="header-actions">
                            {ticketFiles.length > 0 && (
                                <div className="ticket-file-indicators">
                                    {ticketFiles.map((file, index) => (
                                        <div
                                            key={index}
                                            className={`ticket-file-indicator ${getFileClass(file.mime_type)}`}
                                            onClick={() => handleFileClick(file)}
                                        >
                                            {getFileIcon(file.mime_type)}
                                            <div className="ticket-file-tooltip">
                                                <p className="tooltip-filename">{file.name}</p>
                                                {file.metadata && Object.keys(file.metadata).length > 0 && (
                                                    <FileMetadataTooltip metadata={file.metadata} />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className="action-menu-container" ref={actionMenuRef}>
                                <button 
                                    className="action-menu-trigger"
                                    onClick={() => setShowActionMenu(!showActionMenu)}
                                >
                                    <FaEllipsisV />
                                </button>
                                {showActionMenu && (
                                    <div className="action-menu">
                                        {isEditable && (
                                            <>
                                                <button onClick={handleResolveTicket}>
                                                    <FaCheck /> {t('messageWindow.resolveTicket')}
                                                </button>
                                                <button onClick={handleCloseTicket}>
                                                    <FaTimes /> {t('messageWindow.closeTicket')}
                                                </button>
                                            </>
                                        )}
                                        <div className="action-menu-item" 
                                             onMouseEnter={() => setShowLanguageMenu(true)}
                                             onMouseLeave={() => setShowLanguageMenu(false)}>
                                            <span>
                                                <FaLanguage /> {t('messageWindow.translate')}
                                            </span>
                                            {showLanguageMenu && (
                                                <div className="language-submenu">
                                                    <button onClick={() => handleLanguageChange('original')}>
                                                        {t('messageWindow.original')}
                                                    </button>
                                                    <button onClick={() => handleLanguageChange('en')}>English</button>
                                                    <button onClick={() => handleLanguageChange('fr')}>Français</button>
                                                    <button onClick={() => handleLanguageChange('es')}>Español</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="ticket-meta">
                        <span className={`status-badge ${ticket.status}`}>
                            {t(`messageWindow.statusOptions.${ticket.status}`, ticket.status)}
                        </span>
                        <span className="ticket-employee">
                            {t('messageWindow.employee')}: {employee}
                        </span>
                        <div className="ticket-indicators">
                            {contextData && (
                                <>
                                    <div className="ticket-indicator tracking" title={t('messageWindow.masterTracking')}>
                                        <FaBarcode />
                                        <span>{contextData.shipping_info.master_tracking || t('messageWindow.noTracking')}</span>
                                    </div>
                                    <div className="ticket-indicator tracking" title={t('messageWindow.topic')}>
                                        <FaTag />
                                        <span>{contextData.shipping_info.topic || t('messageWindow.noTopic')}</span>
                                    </div>
                                    <div className="ticket-indicator client" title={t('messageWindow.clientName')}>
                                        <FaUser />
                                        <span>{contextData.client_info.name || t('messageWindow.noClient')}</span>
                                    </div>
                                    <div className="ticket-indicator carrier" title={t('messageWindow.carrierName')}>
                                        <FaTruck />
                                        <span>{contextData.shipping_info.carrier_name || t('messageWindow.noCarrier')}</span>
                                    </div>
                                    <div className={`ticket-indicator status ${contextData.shipping_info.is_shipped ? '' : 'off'}`} 
                                         title={t('messageWindow.shipped')}>
                                        <FaShippingFast />
                                        <span>{t('messageWindow.shipped')}</span>
                                    </div>
                                    <div className={`ticket-indicator status ${contextData.shipping_info.is_delivered ? '' : 'off'}`}
                                         title={t('messageWindow.delivered')}>
                                        <FaBox />
                                        <span>{t('messageWindow.delivered')}</span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                
                <div className="message-window-content">
                    <div className="message-window-messages">
                        {messages.map((message) => renderMessage(message))}
                    </div>

                    {isEditable && (
                        <div className="input-container">
                            <textarea
                                value={newMessage}
                                onChange={(e) => {
                                    setNewMessage(e.target.value);
                                    // Auto-grow functionality
                                    e.target.style.height = '40px';
                                    e.target.style.height = `${Math.min(e.target.scrollHeight, 120)}px`;
                                }}
                                placeholder={t('messageWindow.typeYourResponse')}
                                className="message-window-textarea"
                                rows={1}
                            />
                            <div className="response-actions">
                                <button className="message-window-action-button message-window-send" onClick={handleSendMessage}>
                                    <FaPaperPlane /> {t('messageWindow.send')}
                                </button>
                                <button className="message-window-action-button message-window-ai-help" onClick={handleGetAssistantResponse}>
                                    <FaLightbulb /> {t('messageWindow.aiHelp')}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="side-panel">
                <div className="tab-buttons">
                    <button 
                        className={`tab-button ${activeTab === 'carriers' ? 'active' : ''}`}
                        onClick={() => setActiveTab('carriers')}
                        title={t('messageWindow.carriersConversation')}
                    >
                        <FaComments />
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'manual' ? 'active' : ''}`}
                        onClick={() => setActiveTab('manual')}
                        title={t('messageWindow.manualConversation')}
                    >
                        <FaHandPaper />
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'user' ? 'active' : ''}`}
                        onClick={() => setActiveTab('user')}
                        title={t('messageWindow.userInfo')}
                    >
                        <FaUser />
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'track' ? 'active' : ''}`}
                        onClick={() => setActiveTab('track')}
                        title={t('messageWindow.trackingInfo')}
                    >
                        <FaShippingFast />
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'warehouse' ? 'active' : ''}`}
                        onClick={() => setActiveTab('warehouse')}
                        title={t('messageWindow.warehouseEmail')}
                    >
                        <FaWarehouse />
                    </button>
                    <button 
                        className={`tab-button ${activeTab === 'niveau2' ? 'active' : ''}`}
                        onClick={() => setActiveTab('niveau2')}
                        title={t('messageWindow.niveau2Email')}
                    >
                        <FaEnvelope />
                    </button>
                </div>
                
                <div className="tab-content">
                    {activeTab === 'carriers' && (
                        <CarriersConversation 
                            conversation={carriersConversation} 
                            onValidateMessage={handleValidateCarrierMessage}
                            isValidating={validatingMessage}
                            onRefresh={fetchCarriersConversation}
                        />
                    )}
                    {activeTab === 'manual' && (
                        <ManualConversation 
                            onRefresh={fetchCarriersConversation}
                            ticketFiles={ticketFiles}
                            ticket={ticket}
                        />
                    )}
                    {activeTab === 'user' && contextData && (
                        <div className="user-tab">
                            <div className="user-section">
                                <h3>{t('messageWindow.recipientInfo')}</h3>
                                {contextData.recipient_info.company && (
                                    <p><FaBuilding /><strong>{t('messageWindow.company')}:</strong> {contextData.recipient_info.company}</p>
                                )}
                                <p><FaUser /><strong>{t('messageWindow.name')}:</strong> {contextData.recipient_info.name}</p>
                                <h4>{t('messageWindow.address')}</h4>
                                <p><FaMapMarkerAlt /><strong>{t('messageWindow.street')}:</strong> {contextData.recipient_info.address.street1}</p>
                                {contextData.recipient_info.address.street2 && 
                                    <p><FaMapMarkerAlt /><strong>{t('messageWindow.street2')}:</strong> {contextData.recipient_info.address.street2}</p>
                                }
                                <p><FaCity /><strong>{t('messageWindow.city')}:</strong> {contextData.recipient_info.address.postcode} {contextData.recipient_info.address.city}</p>
                                <p><FaGlobe /><strong>{t('messageWindow.country')}:</strong> {contextData.recipient_info.address.country}</p>
                                <h4>{t('messageWindow.contact')}</h4>
                                <p><FaPhone /><strong>{t('messageWindow.phone')}:</strong> {contextData.recipient_info.contact.phone}</p>
                                <p><FaEnvelope /><strong>{t('messageWindow.email')}:</strong> {contextData.recipient_info.contact.email}</p>
                                {contextData.shipping_info.label_url && (
                                    <p><strong>{t('messageWindow.labelUrl')}:</strong> 
                                        <a href={contextData.shipping_info.label_url} target="_blank" rel="noopener noreferrer">
                                            {t('messageWindow.viewLabel')}
                                        </a>
                                    </p>
                                )}
                            </div>

                            <div className="user-section">
                                <h3>{t('messageWindow.clientInfo')}</h3>
                                <p><FaIdCard /><strong>{t('messageWindow.clientId')}:</strong> {contextData.client_info.id}</p>
                                <p><FaUser /><strong>{t('messageWindow.clientName')}:</strong> {contextData.client_info.name}</p>
                                <p><FaInfo /><strong>{t('messageWindow.clientStatus')}:</strong> {contextData.client_info.status}</p>
                            </div>

                            <div className="user-section">
                                <h3>{t('messageWindow.packageInfo')}</h3>
                                <p><FaBarcode /><strong>{t('messageWindow.reference')}:</strong> {contextData.order_info.reference}</p>
                                <div className="package-content">
                                    <FaBox />
                                    <p>{t('messageWindow.packageContent')}:</p>
                                    <div className="package-content-truncated">
                                        {contextData.order_info.package_content}
                                    </div>
                                    <div className="package-content-full">
                                        {contextData.order_info.package_content}
                                    </div>
                                </div>
                                <p><FaMoneyBillWave /><strong>{t('messageWindow.goodsValue')}:</strong> {contextData.order_info.goods_value} {contextData.order_info.currency}</p>
                                <p><FaBoxOpen /><strong>{t('messageWindow.packageValue')}:</strong> {contextData.order_info.package_value} {contextData.order_info.currency}</p>
                                <p><FaShieldAlt /><strong>{t('messageWindow.insuranceValue')}:</strong> {contextData.order_info.insurance_value} {contextData.order_info.currency}</p>
                                {contextData.order_info.ht_price !== null && (
                                    <p><FaReceipt /><strong>{t('messageWindow.htPrice')}:</strong> {contextData.order_info.ht_price} {contextData.order_info.currency}</p>
                                )}
                            </div>
                        </div>
                    )}
                    {activeTab === 'track' && contextData && (
                        <div className="track-tab">
                            <div className="tracking-section">
                                <h3>{t('messageWindow.shippingInfo')}</h3>
                                
                                {contextData.shipping_info.tracking_info && (
                                    <div className="tracking-info">
                                        <p><strong>{t('messageWindow.currentStatus')}:</strong> {contextData.shipping_info.tracking_info.status}</p>
                                        
                                        {contextData.shipping_info.tracking_info.events && 
                                         contextData.shipping_info.tracking_info.events.length > 0 && (
                                            <>
                                                <h4>{t('messageWindow.trackingHistory')}</h4>
                                                <div className="tracking-events">
                                                    {contextData.shipping_info.tracking_info.events.map((event, index) => (
                                                        <div key={index} className="tracking-event">
                                                            <div className="event-date">
                                                                {new Date(event.date).toLocaleString()}
                                                            </div>
                                                            {event.location && (
                                                                <div className="event-location">
                                                                    {event.location}
                                                                </div>
                                                            )}
                                                            <div className="event-status">
                                                                {event.status}
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {activeTab === 'warehouse' && (
                        <div className="warehouse-tab">
                            {warehouseLoading ? (
                                <div className="loading-indicator">{t('messageWindow.loading')}</div>
                            ) : warehouseConversation && warehouseConversation.messages && warehouseConversation.messages.length > 0 ? (
                                <WarehouseConversation conversation={warehouseConversation} />
                            ) : (
                                <form onSubmit={handleWarehouseEmailSubmit} className="warehouse-email-form">
                                    <div className="form-group">
                                        <label htmlFor="recipient_email">{t('messageWindow.recipientEmail')}</label>
                                        <input
                                            type="email"
                                            id="recipient_email"
                                            value={warehouseEmailForm.recipient_email}
                                            onChange={(e) => setWarehouseEmailForm(prev => ({
                                                ...prev,
                                                recipient_email: e.target.value
                                            }))}
                                            required
                                            className="warehouse-input"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="subject">{t('messageWindow.emailSubject')}</label>
                                        <input
                                            type="text"
                                            id="subject"
                                            value={warehouseEmailForm.subject}
                                            onChange={(e) => setWarehouseEmailForm(prev => ({
                                                ...prev,
                                                subject: e.target.value
                                            }))}
                                            required
                                            className="warehouse-input"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="content">{t('messageWindow.emailContent')}</label>
                                        <textarea
                                            id="content"
                                            value={warehouseEmailForm.content}
                                            onChange={(e) => setWarehouseEmailForm(prev => ({
                                                ...prev,
                                                content: e.target.value
                                            }))}
                                            required
                                            className="warehouse-textarea"
                                            rows={6}
                                        />
                                    </div>
                                    <button 
                                        type="submit" 
                                        className="submit-button"
                                        disabled={isProcessing}
                                    >
                                        {isProcessing ? (
                                            t('messageWindow.sending')
                                        ) : (
                                            <>
                                                <FaPaperPlane /> {t('messageWindow.sendToWarehouse')}
                                            </>
                                        )}
                                    </button>
                                </form>
                            )}
                        </div>
                    )}
                    {activeTab === 'niveau2' && (
                        <div className="niveau2-tab">
                            <form onSubmit={handleNiveau2EmailSubmit} className="niveau2-email-form">
                                <div className="form-group">
                                    <label htmlFor="niveau2_recipient_email">{t('messageWindow.recipientEmail')}</label>
                                    <input
                                        type="email"
                                        id="niveau2_recipient_email"
                                        value={niveau2EmailForm.recipient_email}
                                        onChange={(e) => setNiveau2EmailForm(prev => ({
                                            ...prev,
                                            recipient_email: e.target.value
                                        }))}
                                        required
                                        className="niveau2-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="niveau2_subject">{t('messageWindow.emailSubject')}</label>
                                    <input
                                        type="text"
                                        id="niveau2_subject"
                                        value={niveau2EmailForm.subject}
                                        onChange={(e) => setNiveau2EmailForm(prev => ({
                                            ...prev,
                                            subject: e.target.value
                                        }))}
                                        required
                                        className="niveau2-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="niveau2_content">{t('messageWindow.emailContent')}</label>
                                    <textarea
                                        id="niveau2_content"
                                        value={niveau2EmailForm.content}
                                        onChange={(e) => setNiveau2EmailForm(prev => ({
                                            ...prev,
                                            content: e.target.value
                                        }))}
                                        required
                                        className="niveau2-textarea"
                                        rows={6}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{t('messageWindow.selectFiles')}</label>
                                    <div className="file-selection-grid">
                                        {ticketFiles.map((file, index) => (
                                            <div 
                                                key={index}
                                                className={`file-selection-item ${selectedTicketFiles.includes(file.url) ? 'selected' : ''}`}
                                                onClick={() => handleFileSelection(file.url)}
                                            >
                                                {getFileIcon(file.mime_type)}
                                                <span className="file-name">{file.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <button 
                                    type="submit" 
                                    className="niveau2-submit-button"
                                    disabled={isProcessing}
                                >
                                    {isProcessing ? (
                                        t('messageWindow.sending')
                                    ) : (
                                        <>
                                            <FaPaperPlane /> {t('messageWindow.sendToNiveau2')}
                                        </>
                                    )}
                                </button>
                            </form>
                        </div>
                    )}
                </div>
            </div>

            {/* File Overlay */}
            {selectedFile && (
                <div className="file-overlay">
                    <div className="file-overlay-content">
                        <button className="close-overlay" onClick={closeFileOverlay}>
                            <FaTimesCircle size={30} />
                        </button>
                        {selectedFile.mime_type === 'application/pdf' ? (
                            <object
                                data={selectedFile.url}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                            >
                                <p>
                                    {t('messageWindow.unableToDisplayFile')}. <a href={selectedFile.url}> {t('messageWindow.downloadFile')}</a>
                                </p>
                            </object>
                        ) : (
                            <iframe 
                                src={selectedFile.url} 
                                title={selectedFile.name} 
                                width="100%" 
                                height="100%" 
                                frameBorder="0"
                            ></iframe>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MessageWindow;
