// src/components/AIAgentsList.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaRobot, FaPlay, FaTools, FaEnvelope, FaCheck, FaTimes, FaWhatsapp, FaFacebook, FaTwitter, FaSlack, FaInstagram, FaDiscord, FaTelegram, FaAmazon, FaComments, FaCode } from 'react-icons/fa';
import './AIAssistant.css';
import { useNavigate } from 'react-router-dom';
import Button from './UI/Button';

const AIAgentsList = ({ onSelectAgent }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [aiAssistants, setAIAssistants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [availableTools, setAvailableTools] = useState([]);
  const [userEmails, setUserEmails] = useState({
    gmail_email: null,
    outlook_emails: []
  });
  const [availableChannels, setAvailableChannels] = useState({});
  const [showEmailSelector, setShowEmailSelector] = useState(false);
  const [showChannelSelector, setShowChannelSelector] = useState(false);
  const [selectedAssistantForEmail, setSelectedAssistantForEmail] = useState(null);
  const [selectedAssistantForChannels, setSelectedAssistantForChannels] = useState(null);
  const [selectedChannels, setSelectedChannels] = useState({});
  const [associationLoading, setAssociationLoading] = useState(false);
  const [associationSuccess, setAssociationSuccess] = useState(null);
  const [channelAssociationLoading, setChannelAssociationLoading] = useState(false);
  const [channelAssociationSuccess, setChannelAssociationSuccess] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem('access_token');
    if (token) {
      fetchData(token);
      fetchUserEmails(token);
      fetchAvailableChannels(token);
    }
  }, []);

  useEffect(() => {
    const createConnectionLines = () => {
      document.querySelectorAll('.connection-line').forEach(line => line.remove());
      
      // Create email connections
      document.querySelectorAll('.agent-card[data-email]').forEach(agentEl => {
        const associatedEmail = agentEl.getAttribute('data-email');
        if (!associatedEmail) return;
        
        const emailCircle = document.querySelector(`.email-circle[data-email="${associatedEmail}"]`);
        if (!emailCircle) return;
        
        createConnectionLine(agentEl, emailCircle, 'var(--primary-color)');
      });
      
      // Create channel connections
      document.querySelectorAll('.agent-card').forEach(agentEl => {
        const assistantId = agentEl.getAttribute('data-assistant-id');
        if (!assistantId) return;
        
        const assistant = aiAssistants.find(a => a._id === assistantId);
        if (!assistant || !assistant.associated_channels) return;
        
        Object.entries(assistant.associated_channels).forEach(([channelName, isActive]) => {
          if (!isActive) return;
          
          const channelCircle = document.querySelector(`.channel-circle[data-channel="${channelName}"]`);
          if (!channelCircle) return;
          
          createConnectionLine(agentEl, channelCircle, 'var(--secondary-color)');
        });
      });
    };
    
    const createConnectionLine = (sourceEl, targetEl, color) => {
      const sourceRect = sourceEl.getBoundingClientRect();
      const targetRect = targetEl.getBoundingClientRect();
      
      const line = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      line.classList.add('connection-line');
      line.style.position = 'absolute';
      line.style.top = '0';
      line.style.left = '0';
      line.style.width = '100%';
      line.style.height = '100%';
      line.style.pointerEvents = 'none';
      line.style.zIndex = '1';
      
      const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      
      const startX = sourceRect.left + sourceRect.width/2;
      const startY = sourceRect.bottom;
      const endX = targetRect.left + targetRect.width/2;
      const endY = targetRect.top;
      
      const pathData = `M${startX},${startY} C${startX},${startY + 50} ${endX},${endY - 50} ${endX},${endY}`;
      
      path.setAttribute('d', pathData);
      path.setAttribute('stroke', color);
      path.setAttribute('stroke-width', '3');
      path.setAttribute('fill', 'none');
      path.setAttribute('stroke-dasharray', '5,5');
      path.classList.add('pulsating-path');
      
      line.appendChild(path);
      document.body.appendChild(line);
    };
    
    if (aiAssistants.length > 0 && !isLoading) {
      setTimeout(createConnectionLines, 500);
      window.addEventListener('resize', createConnectionLines);
      
      return () => {
        window.removeEventListener('resize', createConnectionLines);
        document.querySelectorAll('.connection-line').forEach(line => line.remove());
      };
    }
  }, [aiAssistants, isLoading]);

  const fetchData = async (token) => {
    try {
      setIsLoading(true);
      const [assistantsResponse, toolsResponse] = await Promise.all([
        axios.get('/api/ai-assistants', {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get('/api/tools', {
          headers: { Authorization: `Bearer ${token}` },
        })
      ]);
      
      setAIAssistants(assistantsResponse.data);
      setAvailableTools(toolsResponse.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setIsLoading(false);
    }
  };

  const fetchUserEmails = async (token) => {
    try {
      const response = await axios.get('/api/users/user/emails', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserEmails(response.data);
    } catch (error) {
      console.error('Failed to fetch user emails:', error);
    }
  };

  const fetchAvailableChannels = async (token) => {
    try {
      const response = await axios.get('/api/users/available-channels', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAvailableChannels(response.data.channels || {});
    } catch (error) {
      console.error('Failed to fetch available channels:', error);
    }
  };

  const getPromptPreview = (text) => {
    const maxLength = 80;
    return text && text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  const getToolTranslation = (toolName) => {
    const toolTranslations = {
      'retrieve_knowledge': t('tools.retrieveKnowledge'),
      'assign_to_employee': t('tools.assignToEmployee'),
      'resolve_ticket': t('tools.resolveTicket'),
      'Close_ticket': t('tools.closeTicket'),
      'get_tracking_info': t('tools.getTrackingInfo'),
      'Retrieve_customer_order_by_reference_and_email': t('tools.retrieveOrder'),
      'Update_customer_shipping_address': t('tools.updateShippingAddress'),
      'find_relay_points': t('tools.findRelayPoints'),
      'create_return_with_edn_label': t('tools.createReturn'),
      'carriers_conversation': t('tools.carriersConversation'),
      'Retour': t('tools.return'),
      'fill_form_fields': t('tools.fillPDF'),
      'getOrderById': t('tools.getOrderById'),
      'carriers_conversation_update': t('tools.carriersConversationUpdate'),
      'order_status': t('tools.orderStatus'),
      'Request_return_order': t('tools.Request_return_order'),
      'Retrieve_customer_order_by_ID': t('tools.Retrieve_customer_order_by_ID'),
      'Request_exchange_shopify': t('tools.Request_exchange_shopify'),
      'Get_all_product_variants': t('tools.Get_all_product_variants')
    };
    return toolTranslations[toolName] || toolName;
  };

  const handleAssociateEmailClick = (assistantId) => {
    setSelectedAssistantForEmail(assistantId);
    setShowEmailSelector(true);
  };

  const associateEmailWithAssistant = async (email) => {
    if (!selectedAssistantForEmail) return;
    
    setAssociationLoading(true);
    setAssociationSuccess(null);
    
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post(
        '/api/ai-assistants/associate-email',
        {
          email: email,
          assistant_id: selectedAssistantForEmail
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      // Update the local state to reflect this change
      setAIAssistants(aiAssistants.map(assistant => {
        if (assistant._id === selectedAssistantForEmail) {
          return { ...assistant, associated_email: email };
        }
        return assistant;
      }));
      
      setAssociationSuccess(true);
      
      // Reset after 3 seconds
      setTimeout(() => {
        setShowEmailSelector(false);
        setSelectedAssistantForEmail(null);
        setAssociationSuccess(null);
      }, 3000);
      
    } catch (error) {
      console.error('Failed to associate email with assistant:', error);
      setAssociationSuccess(false);
      
      // Reset after 3 seconds
      setTimeout(() => {
        setAssociationSuccess(null);
      }, 3000);
    } finally {
      setAssociationLoading(false);
    }
  };

  const removeEmailAssociation = async (assistantId, email) => {
    setAssociationLoading(true);
    setAssociationSuccess(null);
    
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post(
        '/api/ai-assistants/remove-email-association',
        {
          email: email,
          assistant_id: assistantId
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      // Update the local state to reflect this change
      setAIAssistants(aiAssistants.map(assistant => {
        if (assistant._id === assistantId) {
          const updatedAssistant = { ...assistant };
          delete updatedAssistant.associated_email;
          return updatedAssistant;
        }
        return assistant;
      }));
      
      setAssociationSuccess(true);
      
      // Reset after 3 seconds
      setTimeout(() => {
        setAssociationSuccess(null);
      }, 3000);
      
    } catch (error) {
      console.error('Failed to remove email association:', error);
      setAssociationSuccess(false);
      
      // Reset after 3 seconds
      setTimeout(() => {
        setAssociationSuccess(null);
      }, 3000);
    } finally {
      setAssociationLoading(false);
    }
  };

  const handleAssociateChannelsClick = (assistant) => {
    setSelectedAssistantForChannels(assistant._id);
    // Initialize selected channels with current associations if they exist
    setSelectedChannels(assistant.associated_channels || {});
    setShowChannelSelector(true);
  };

  const associateChannelsWithAssistant = async () => {
    if (!selectedAssistantForChannels) return;
    
    setChannelAssociationLoading(true);
    setChannelAssociationSuccess(null);
    
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post(
        '/api/ai-assistants/associate-channels',
        {
          assistant_id: selectedAssistantForChannels,
          channels: selectedChannels
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      
      // Update the local state to reflect this change
      setAIAssistants(aiAssistants.map(assistant => {
        if (assistant._id === selectedAssistantForChannels) {
          return { ...assistant, associated_channels: selectedChannels };
        }
        return assistant;
      }));
      
      setChannelAssociationSuccess(true);
      
      // Reset after 3 seconds
      setTimeout(() => {
        setShowChannelSelector(false);
        setSelectedAssistantForChannels(null);
        setChannelAssociationSuccess(null);
      }, 3000);
      
    } catch (error) {
      console.error('Failed to associate channels with assistant:', error);
      setChannelAssociationSuccess(false);
      
      // Reset after 3 seconds
      setTimeout(() => {
        setChannelAssociationSuccess(null);
      }, 3000);
    } finally {
      setChannelAssociationLoading(false);
    }
  };

  const handleChannelToggle = (channelName) => {
    setSelectedChannels(prev => ({
      ...prev,
      [channelName]: !prev[channelName]
    }));
  };

  const handleAgentClick = (assistant) => {
    // Navigate to the AI assistants management page with the selected assistant ID
    navigate(`/ai-assistants?assistantId=${assistant._id}`);
  };

  // Helper function to get the appropriate icon for each channel
  const getChannelIcon = (channelName) => {
    const channelIcons = {
      'whatsapp': <FaWhatsapp />,
      'facebook': <FaFacebook />,
      'messenger': <FaFacebook />,
      'twitter': <FaTwitter />,
      'slack': <FaSlack />,
      'instagram': <FaInstagram />,
      'discord': <FaDiscord />,
      'telegram': <FaTelegram />,
      'amazon': <FaAmazon />,
      'website': <FaComments />,
      'test': <FaPlay />,
      'custom': <FaTools />
    };
    
    return channelIcons[channelName.toLowerCase()] || <FaComments />;
  };

  if (isLoading) {
    return (
      <div className="una-dashboard-loading-screen">
        <div className="una-loading-content">
          <div className="una-loading-logo">
            <img src="/bgless_logo.png" alt="Unanimity AI Logo" />
          </div>
          <h2>{t('aiAssistant.loading')}</h2>
          <div className="una-loading-progress">
            <div className="una-progress-bar">
              <div className="una-progress-glow"></div>
            </div>
          </div>
          <div className="una-loading-metrics">
            <div className="una-metric-item">
              <FaRobot />
              <span>{t('aiAssistant.fetchingAssistants')}</span>
            </div>
            <div className="una-metric-item">
              <FaTools />
              <span>{t('aiAssistant.loadingTools')}</span>
            </div>
            <div className="una-metric-item">
              <FaCode />
              <span>{t('aiAssistant.processingConfigurations')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const validEmails = [
    userEmails.gmail_email, 
    ...(userEmails.outlook_emails || [])
  ].filter(email => email);

  return (
    <div className="agents-list-wrapper">
      <h3>{t('aiAssistant.availableAgents')}</h3>
      
      <div className="agent-cards-grid">
        {aiAssistants.map((assistant) => {
          const assistantTools = availableTools.filter(tool => 
            assistant.selected_tool_ids && assistant.selected_tool_ids.includes(tool.id)
          );
          
          return (
            <div
              key={assistant._id}
              className={`agent-card ${assistant.associated_email ? 'has-email-connection' : ''} ${assistant.associated_channels && Object.values(assistant.associated_channels).some(Boolean) ? 'has-channel-connection' : ''}`}
              onClick={() => handleAgentClick(assistant)}
              data-email={assistant.associated_email}
              data-assistant-id={assistant._id}
            >
              <div className="agent-card-header">
                <div className="agent-circle small">
                  <FaRobot />
                </div>
                <div className="agent-card-title">
                  <h4>{assistant.name}</h4>
                </div>
              </div>
              
              {assistant.prompt && (
                <div className="agent-card-prompt">
                  <h5>{t('aiAssistant.prompt')}:</h5>
                  <p>{getPromptPreview(assistant.prompt)}</p>
                </div>
              )}
              
              {assistantTools.length > 0 && (
                <div className="agent-card-tools">
                  <h5><FaTools /> {t('aiAssistant.tools')}</h5>
                  <div className="agent-tools-list">
                    {assistantTools.slice(0, 3).map(tool => (
                      <span key={tool.id} className="agent-tool-badge">
                        {getToolTranslation(tool.name)}
                      </span>
                    ))}
                    {assistantTools.length > 3 && (
                      <span className="agent-tool-badge more">
                        +{assistantTools.length - 3}
                      </span>
                    )}
                  </div>
                </div>
              )}
              
              <div className="agent-card-actions">
                {validEmails.length > 0 && (
                  <Button
                    variant="tertiary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAssociateEmailClick(assistant._id);
                    }}
                  >
                    <FaEnvelope /> {assistant.associated_email ? t('aiAssistant.changeEmail') : t('aiAssistant.associateEmail')}
                  </Button>
                )}
                
                {Object.keys(availableChannels).length > 0 && (
                  <Button
                    variant="tertiary"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAssociateChannelsClick(assistant);
                    }}
                  >
                    <FaTools /> {t('aiAssistant.manageChannels')}
                  </Button>
                )}
              </div>
              
              {assistant.associated_email && (
                <div className="agent-email-link"></div>
              )}
            </div>
          );
        })}
      </div>
      
      {/* Combined connections container with all circles in one row */}
      <div className="connections-container">
        <h4 className="connections-heading">{t('aiAssistant.connections')}</h4>
        <div className="circles-wrapper">
          {/* Email circles */}
          {validEmails.map((email, index) => (
            <div key={`email-${index}`} className="connection-circle email-circle" data-email={email}>
              <FaEnvelope />
              <span className="circle-text">{email}</span>
            </div>
          ))}
          
          {/* Channel circles with icons instead of letters */}
          {Object.keys(availableChannels).map((channel, index) => (
            <div key={`channel-${index}`} className="connection-circle channel-circle" data-channel={channel}>
              <div className="channel-icon">
                {getChannelIcon(channel)}
              </div>
              <span className="circle-text">{channel}</span>
            </div>
          ))}
        </div>
      </div>
      
      {showEmailSelector && (
        <div className="email-selector-modal">
          <div className="email-selector-content">
            <h3>{t('aiAssistant.selectEmail')}</h3>
            
            {associationSuccess === true && (
              <div className="association-success">
                <FaCheck /> {t('aiAssistant.emailAssociatedSuccess')}
              </div>
            )}
            
            {associationSuccess === false && (
              <div className="association-error">
                <FaTimes /> {t('aiAssistant.emailAssociatedError')}
              </div>
            )}
            
            {associationSuccess === null && (
              <>
                <div className="email-options">
                  {validEmails.map((email, index) => (
                    <Button 
                      key={index}
                      variant="tertiary"
                      onClick={() => associateEmailWithAssistant(email)}
                      disabled={associationLoading}
                    >
                      {email}
                    </Button>
                  ))}
                  
                  {aiAssistants.find(a => a._id === selectedAssistantForEmail)?.associated_email && (
                    <Button 
                      variant="tertiary"
                      onClick={() => {
                        if (window.confirm(t('aiAssistant.confirmRemoveEmail'))) {
                          const assistant = aiAssistants.find(a => a._id === selectedAssistantForEmail);
                          removeEmailAssociation(selectedAssistantForEmail, assistant.associated_email);
                        }
                      }}
                      disabled={associationLoading}
                    >
                      <FaTimes /> {t('aiAssistant.removeEmail')}
                    </Button>
                  )}
                </div>
                
                <div className="modal-actions">
                  <Button 
                    variant="secondary"
                    onClick={() => {
                      setShowEmailSelector(false);
                      setSelectedAssistantForEmail(null);
                    }}
                  >
                    {t('aiAssistant.cancel')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      
      {showChannelSelector && (
        <div className="channel-selector-modal">
          <div className="channel-selector-content">
            <h3>{t('aiAssistant.manageChannels')}</h3>
            
            {channelAssociationSuccess === true && (
              <div className="association-success">
                <FaCheck /> {t('aiAssistant.channelsAssociatedSuccess')}
              </div>
            )}
            
            {channelAssociationSuccess === false && (
              <div className="association-error">
                <FaTimes /> {t('aiAssistant.channelsAssociatedError')}
              </div>
            )}
            
            {channelAssociationSuccess === null && (
              <>
                <div className="channel-options">
                  {Object.entries(availableChannels).map(([channelName, isAvailable]) => (
                    isAvailable && (
                      <div key={channelName} className="channel-option">
                        <label className="channel-toggle">
                          <input
                            type="checkbox"
                            checked={selectedChannels[channelName] || false}
                            onChange={() => handleChannelToggle(channelName)}
                            disabled={channelAssociationLoading}
                          />
                          <span className="channel-toggle-slider"></span>
                        </label>
                        <span className="channel-name">{channelName}</span>
                      </div>
                    )
                  ))}
                </div>
                
                <div className="modal-actions">
                  <Button 
                    variant="primary"
                    onClick={associateChannelsWithAssistant}
                    disabled={channelAssociationLoading}
                  >
                    {channelAssociationLoading ? (
                      <span className="spinner-icon"></span>
                    ) : (
                      t('aiAssistant.saveChannels')
                    )}
                  </Button>
                  
                  <Button 
                    variant="secondary"
                    onClick={() => {
                      setShowChannelSelector(false);
                      setSelectedAssistantForChannels(null);
                    }}
                    disabled={channelAssociationLoading}
                  >
                    {t('aiAssistant.cancel')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AIAgentsList;