import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaSave, FaTimes, FaPlus, FaFileAlt, FaLink, FaSearch } from 'react-icons/fa';
import './ClientsInstructions.css';
import Button from './UI/Button';

const ClientsInstructions = () => {
  const { t } = useTranslation();
  const [clients, setClients] = useState([]);
  const [editingClient, setEditingClient] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [newClient, setNewClient] = useState({
    name: '',
    client_id: '',
    instructions: '',
    reference: '',
    automation: false
  });

  const ITEMS_PER_PAGE = 6;
  
  // Filter clients based on search query
  const filteredClients = clients.filter(client => 
    client.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  const totalPages = Math.ceil(filteredClients.length / ITEMS_PER_PAGE);
  const paginatedClients = filteredClients.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // Reset to first page when search query changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    fetchClients();
  }, []);

  const fetchClients = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      const response = await axios.get('/api/logistics/clients-edn', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setClients(response.data);
    } catch (error) {
      console.error('Failed to fetch clients:', error);
    }
  };

  const handleUpdateClient = async (clientId) => {
    if (!editingClient) return;
    
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.put(
        `/api/logistics/clients-edn/${clientId}`,
        {
          name: editingClient.name,
          instructions: editingClient.instructions,
          reference: editingClient.reference,
          automation: editingClient.automation
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      await fetchClients();
      handleCloseModal();
    } catch (error) {
      console.error('Failed to update client:', error);
    }
  };

  const handleCreateClient = async () => {
    try {
      const token = sessionStorage.getItem('access_token');
      await axios.post('/api/logistics/clients-edn', newClient, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setShowCreateModal(false);
      setNewClient({
        name: '',
        client_id: '',
        instructions: '',
        reference: '',
        automation: false
      });
      fetchClients();
    } catch (error) {
      console.error('Failed to create client:', error);
    }
  };

  const handleClientClick = (client) => {
    setSelectedClient(client);
    setEditingClient({ ...client });
    setShowEditModal(true);
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    setShowCreateModal(false);
    setEditingClient(null);
    setSelectedClient(null);
  };

  const ClientCard = ({ client, onClick }) => (
    <div className="client-card" onClick={onClick}>
      <div className="client-content">
        <h3 className="client-title">{client.name}</h3>
        <div className="client-id">{client.client_id}</div>
        <div className="client-info-grid">
          <div className="info-item">
            <span className="info-label">{t('clientsInstructions.instructions')}</span>
            <FaFileAlt className={client.instructions ? 'success' : 'failure'} />
          </div>
          <div className="info-item">
            <span className="info-label">{t('clientsInstructions.reference')}</span>
            <FaLink className={client.reference && client.reference.trim() !== '' ? 'success' : 'failure'} />
          </div>
          <div className="info-item">
            <span className="info-label">{t('common.automation')}</span>
            <div className={`automation-status ${client.automation ? 'enabled' : 'disabled'}`}>
              {client.automation ? t('common.on') : t('common.off')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="clients-instructions-container">
      <div className="header">
        <div className="pagination">
          <Button 
            variant="tertiary"
            className="page-button"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage(p => p - 1)}
          >
            {t('common.previous')}
          </Button>
          <span className="page-info">
            {t('common.page')} {currentPage} / {Math.max(1, totalPages)}
          </span>
          <Button 
            variant="tertiary"
            className="page-button"
            disabled={currentPage === totalPages || totalPages === 0}
            onClick={() => setCurrentPage(p => p + 1)}
          >
            {t('common.next')}
          </Button>
        </div>
        
        <div className="search-container">
          <div className="search-input-wrapper">
            <FaSearch className="search-icon" />
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t('common.search')}
              className="search-input"
            />
            {searchQuery && (
              <Button 
                variant="tertiary"
                className="clear-search" 
                onClick={() => setSearchQuery('')}
              >
                <FaTimes />
              </Button>
            )}
          </div>
        </div>
        
        <Button 
          variant="tertiary"
          className="create-button" 
          onClick={() => setShowCreateModal(true)}
        >
          <FaPlus /> {t('clientsInstructions.createNew')}
        </Button>
      </div>

      <div className="clients-grid">
        {paginatedClients.length > 0 ? (
          paginatedClients.map(client => (
            <ClientCard 
              key={client.id} 
              client={client}
              onClick={() => handleClientClick(client)}
            />
          ))
        ) : (
          <div className="no-results">
            {searchQuery 
              ? t('common.noSearchResults') 
              : t('clientsInstructions.noClients')}
          </div>
        )}
      </div>

      {/* Edit Modal */}
      {showEditModal && (
        <div className="modalai">
          <div className="modal-content client-modal">
            <h3>{t('clientsInstructions.editClient')}</h3>
            <div className="form-group">
              <label>{t('clientsInstructions.name')}:</label>
              <input
                type="text"
                value={editingClient.name}
                onChange={(e) => setEditingClient({
                  ...editingClient,
                  name: e.target.value
                })}
                className="ai-agent-input"
              />
            </div>
            <div className="form-group">
              <label>{t('clientsInstructions.instructions')}:</label>
              <textarea
                value={editingClient.instructions}
                onChange={(e) => setEditingClient({
                  ...editingClient,
                  instructions: e.target.value
                })}
                rows="8"
                className="ai-agent-textarea"
              />
            </div>
            <div className="form-group">
              <label>{t('clientsInstructions.reference')}:</label>
              <input
                type="text"
                value={editingClient.reference}
                onChange={(e) => setEditingClient({
                  ...editingClient,
                  reference: e.target.value
                })}
                className="ai-agent-input"
              />
            </div>
            <div className="form-group">
              <label>{t('clientsInstructions.automation')}:</label>
              <div className="toggle-container">
                <input
                  type="checkbox"
                  id="automation-toggle"
                  className="toggle-checkbox"
                  checked={editingClient.automation || false}
                  onChange={(e) => setEditingClient({
                    ...editingClient,
                    automation: e.target.checked
                  })}
                />
                <label htmlFor="automation-toggle" className="toggle-label">
                  {editingClient.automation ? t('common.on') : t('common.off')}
                </label>
              </div>
            </div>
            <div className="modal-actions">
              <Button 
                variant="primary"
                onClick={() => handleUpdateClient(selectedClient.id)}
              >
                <FaSave /> {t('common.save')}
              </Button>
              <Button 
                variant="secondary"
                onClick={handleCloseModal}
              >
                <FaTimes /> {t('common.cancel')}
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* Create Modal */}
      {showCreateModal && (
        <div className="modalai">
          <div className="modal-content client-modal">
            <h3>{t('clientsInstructions.createNew')}</h3>
            <div className="form-group">
              <label>{t('clientsInstructions.name')}:</label>
              <input
                type="text"
                value={newClient.name}
                onChange={(e) => setNewClient({
                  ...newClient,
                  name: e.target.value
                })}
                className="ai-agent-input"
              />
            </div>
            <div className="form-group">
              <label>{t('clientsInstructions.clientId')}:</label>
              <input
                type="text"
                value={newClient.client_id}
                onChange={(e) => setNewClient({
                  ...newClient,
                  client_id: e.target.value
                })}
                className="ai-agent-input"
              />
            </div>
            <div className="form-group">
              <label>{t('clientsInstructions.instructions')}:</label>
              <textarea
                value={newClient.instructions}
                onChange={(e) => setNewClient({
                  ...newClient,
                  instructions: e.target.value
                })}
                rows="8"
                className="ai-agent-textarea"
              />
            </div>
            <div className="form-group">
              <label>{t('clientsInstructions.reference')}:</label>
              <input
                type="text"
                value={newClient.reference}
                onChange={(e) => setNewClient({
                  ...newClient,
                  reference: e.target.value
                })}
                className="ai-agent-input"
              />
            </div>
            <div className="form-group">
              <label>{t('clientsInstructions.automation')}:</label>
              <div className="toggle-container">
                <input
                  type="checkbox"
                  id="new-automation-toggle"
                  className="toggle-checkbox"
                  checked={newClient.automation}
                  onChange={(e) => setNewClient({
                    ...newClient,
                    automation: e.target.checked
                  })}
                />
                <label htmlFor="new-automation-toggle" className="toggle-label">
                  {newClient.automation ? t('common.on') : t('common.off')}
                </label>
              </div>
            </div>
            <div className="modal-actions">
              <Button 
                variant="primary"
                onClick={handleCreateClient}
              >
                <FaSave /> {t('common.create')}
              </Button>
              <Button 
                variant="secondary"
                onClick={() => setShowCreateModal(false)}
              >
                <FaTimes /> {t('common.cancel')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientsInstructions;
