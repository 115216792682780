import React, { useState } from 'react';
import './TicketTableDashboard.css';
import { useTranslation } from 'react-i18next';
import { FaInstagram, FaFacebookMessenger, FaWhatsapp, FaEnvelope, FaGlobe, FaSort, FaSortUp, FaSortDown, FaUser, FaRobot } from 'react-icons/fa';

const TicketTableDashboard = ({ tickets, user, onTicketClick }) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortField, setSortField] = useState('updated_at');
  const [statusFilter, setStatusFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const ticketsPerPage = 20;

  const channelIcons = {
    Instagram: FaInstagram,
    Messenger: FaFacebookMessenger,
    WhatsApp: FaWhatsapp,
    Gmail: FaEnvelope,
    Website: FaGlobe
  };

  const statusOptions = {
    new: 'newTickets',
    automated: 'automatedTickets',
    waiting_client_response: 'waitingClientResponse',
    waiting_carrier_response: 'waitingCarrierResponse',
    waiting_carrier_commercial_response: 'waitingCarrierCommercialResponse',
    npec_yourstore: 'npecYourStore',
    resolved: 'resolvedTickets',
    affected: 'affectedTickets',
    closed: 'closedTickets',
    open: 'openTickets',
    pending: 'pendingTickets',
    return_pending: 'returnPending',
    to_compensate: 'toCompensate',
    processing: 'processing',
    compensation: 'compensation',
    compensation_invoice_made: 'compensationInvoiceMade',
    compensation_in_progress: 'compensationInProgress'
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const sortTickets = (tickets, field, order) => {
    return [...tickets].sort((a, b) => {
      const valA = a[field];
      const valB = b[field];
      if (field === 'updated_at' || field === 'created_at') {
        return order === 'asc' ? new Date(valA) - new Date(valB) : new Date(valB) - new Date(valA);
      }
      if (field === 'sentiment_score') {
        return order === 'asc' ? valA - valB : valB - valA;
      }
      return order === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
    });
  };

  const filterTickets = (tickets) => {
    return tickets.filter(ticket => {
      const statusMatch = statusFilter ? ticket.status === statusFilter : true;
      
      const searchTerm = searchFilter.toLowerCase();
      const searchMatch = searchTerm ? (
        (ticket.company_name?.toLowerCase().includes(searchTerm)) ||
        (ticket.tracking_number?.toString().toLowerCase().includes(searchTerm)) ||
        (ticket.external_ticket_id?.toString().toLowerCase().includes(searchTerm)) ||
        (ticket.metadata?.reference?.toLowerCase().includes(searchTerm))
      ) : true;
      
      return statusMatch && searchMatch;
    });
  };

  const sortedTickets = sortTickets(filterTickets(tickets), sortField, sortOrder);

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = sortedTickets.slice(indexOfFirstTicket, indexOfLastTicket);
  const totalPages = Math.ceil(sortedTickets.length / ticketsPerPage);

  const handlePageChange = (direction) => {
    if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSortChange = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const handleSearchFilterChange = (e) => {
    setSearchFilter(e.target.value);
    setCurrentPage(1); // Reset to first page when filter changes
  };

  const getSentimentScoreClass = (score) => {
    if (score <= 25) return 'low';
    if (score <= 60) return 'medium';
    return 'high';
  };

  const SortIcon = ({ field }) => {
    if (sortField !== field) return <FaSort />;
    return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <div className="ticket-table-dashboard">
      <div className="table-controls">
        <div className="filters-container">
          <div className="status-filter">
            <select
              id="status-select"
              value={statusFilter}
              onChange={handleStatusFilterChange}
            >
              <option value="">{t('all')}</option>
              {Object.entries(statusOptions).map(([status, translationKey]) => (
                <option key={status} value={status}>
                  {t(translationKey)}
                </option>
              ))}
            </select>
          </div>
          <div className="company-filter">
            <input
              id="search-input"
              type="text"
              value={searchFilter}
              onChange={handleSearchFilterChange}
              placeholder={t('searchAcrossFields')}
              className="search-input"
            />
          </div>
        </div>
        <div className="pagination">
          <button 
            onClick={() => handlePageChange('prev')} 
            disabled={currentPage === 1}
          >
            {t('common.previous')}
          </button>
          <span>{currentPage} / {totalPages}</span>
          <button 
            onClick={() => handlePageChange('next')} 
            disabled={currentPage === totalPages}
          >
            {t('common.next')}
          </button>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>{t('channel')}</th>
            <th>{t('subject')}</th>
            <th 
              onClick={() => handleSortChange('sentiment_score')} 
              className="sortable"
            >
              {t('sentimentScore')} <SortIcon field="sentiment_score" />
            </th>
            <th>{t('carrier')}</th>
            <th>{t('company')}</th>
            <th className="centered">{t('assignedTo')}</th>
            <th>{t('status')}</th>
            <th 
              onClick={() => handleSortChange('updated_at')} 
              className="sortable"
            >
              {t('updatedAt')} <SortIcon field="updated_at" />
            </th>
          </tr>
        </thead>
        <tbody>
          {currentTickets.map((ticket) => {
            const ChannelIcon = channelIcons[ticket.channel_name];
            return (
              <tr key={ticket._id} onClick={() => onTicketClick(ticket._id)}>
                <td className="tertiary-info">
                  <div className="visual-indicator">
                    {ChannelIcon && <ChannelIcon className="channel-icon-dashboard" />}
                    <span>{ticket.channel_name}</span>
                  </div>
                </td>
                <td className="primary-info truncate">{ticket.subject}</td>
                <td>
                  <span className={`sentiment-score ${getSentimentScoreClass(ticket.sentiment_score)}`}>
                    {Math.round(ticket.sentiment_score)}
                  </span>
                </td>
                <td className="secondary-info">{ticket.carrier_name || t('unknown')}</td>
                <td className="secondary-info">{ticket.company_name || t('unknown')}</td>
                <td className="centered">
                  {ticket.employee_assigned === 'yes' ? (
                    <FaUser className="assigned-icon human" title={t('humanOperator')} />
                  ) : (
                    <FaRobot className="assigned-icon robot" title={t('automated')} />
                  )}
                </td>
                <td className="centered">
                  <span className={`status-badge ${ticket.status}`}>
                    {t(ticket.status)}
                  </span>
                </td>
                <td className="tertiary-info date-info">{formatDate(ticket.updated_at)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TicketTableDashboard;